<template>
  <div>
    <h3>Genes, paneles y umbrellas</h3>
    <hr />
    <div class="col">
      <div class="row">
        <router-link :to="{ name: NAMED_ROUTES.GENES }" tag="div" class="card">
          <span>Genes <i class="material-icons">fingerprint</i></span>
        </router-link>
      </div>
      <hr />
      <div class="row">
        <router-link
          :to="{ name: NAMED_ROUTES.PANELS, params: { panelId: null } }"
          tag="div"
          class="card"
        >
          <span>Paneles <i class="material-icons">blur_linear</i></span>
        </router-link>
      </div>
      <hr />
      <div class="row">
        <router-link
          :to="{ name: NAMED_ROUTES.UMBRELLAS }"
          tag="div"
          class="card"
        >
          <span>Umbrellas <i class="material-icons">beach_access</i> </span>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  data: () => ({}),
}
</script>
<style lang="scss" scoped>
.card {
  cursor: pointer;
  width: 90%;
  border: 5px solid #674eec;
  text-align: center;
  text-decoration-style: solid;
  font-size: 30px;

  .enabled {
    color: green;
  }
  .disabled {
    color: red;
  }

  h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 400;
    margin: 0;
  }
}
.card:hover {
  border: 5px solid #7f60bc;
  background-color: #dfd8ef;
}
</style>
