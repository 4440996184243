var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group"},[_c('form-label',{attrs:{"required":""}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('d-row',_vm._l((_vm.tests),function(test){return _c('d-col',{key:test.code,class:{
        'test-picker': true,
        'test-picker-active': _vm.isTestSelected(test),
      },attrs:{"sm":"6"}},[_c('div',{staticClass:"p-2 mb-1 mt-1 test-picker-block"},[_c('d-row',{staticClass:"align-items-center"},[_c('d-col',{attrs:{"sm":"12","md":"4"}},[_c('d-form-checkbox',{attrs:{"id":test.code,"value":test.code,"disabled":_vm.checkOnePharmaIsDisabled(test.code, test)},model:{value:(_vm.selectedTests),callback:function ($$v) {_vm.selectedTests=$$v},expression:"selectedTests"}},[(!test.allowedPharmas.find(function (ph) { return ph.diagnosisSetting; }))?_c('h6',{staticClass:"m-0 p-0 d-inline-block"},[_vm._v(" "+_vm._s(test.name)+" ")]):(
                  test.allowedPharmas.find(function (ph) { return ph.diagnosisSetting; })
                )?_c('h6',{staticClass:"m-0 p-0 d-inline-block"},[_vm._v(" "+_vm._s(_vm.currentlySelectedPharmaCustomName(test))+" ")]):_vm._e(),(_vm.isRecentBio(test.code))?_c('span',[_c('svg',{staticClass:"ml-2",attrs:{"id":"tooltip","width":"30","height":"12","viewBox":"0 0 25 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M24.8979 10.8675L22.0366 5.9025L24.8687 1.14C24.936 1.02651 24.9727 0.896703 24.9751 0.763858C24.9775 0.631014 24.9455 0.499901 24.8823 0.383934C24.8192 0.267968 24.7273 0.171311 24.6159 0.103852C24.5045 0.0363923 24.3776 0.000552859 24.2483 0H1.45986C1.07268 0 0.701361 0.158036 0.427584 0.43934C0.153806 0.720645 0 1.10218 0 1.5L0 10.5C0 10.8978 0.153806 11.2794 0.427584 11.5607C0.701361 11.842 1.07268 12 1.45986 12H24.2702C24.399 12 24.5256 11.9649 24.6369 11.8984C24.7483 11.8318 24.8406 11.7362 24.9043 11.6212C24.9681 11.5061 25.0011 11.3758 25 11.2435C24.9988 11.1111 24.9636 10.9814 24.8979 10.8675ZM7.67157 8.385H6.85405L4.47448 5.13V8.3925H3.64965V3.75H4.47448L6.86135 7.0125V3.75H7.67887L7.67157 8.385ZM12.292 4.5H9.71538V5.6175H12.0512V6.3675H9.71538V7.575H12.292V8.325H8.89056V3.75H12.2847L12.292 4.5ZM18.3432 8.37H17.5183L16.3869 4.9275L15.2556 8.385H14.438L12.9782 3.75H13.8687L14.8322 7.0725L15.9417 3.75H16.8322L17.8979 7.0725L18.8687 3.75H19.7665L18.3432 8.37Z","fill":"#FFB800"}})]),_c('d-tooltip',{attrs:{"target":"#tooltip","container":"true"}},[_vm._v(" Hemos habilitado un nuevo biomarcador. ")])],1):_vm._e()])],1),_c('d-col',{attrs:{"sm":"12","md":"8"}},[(_vm.isTestSelected(test))?_c('d-form-select',{staticClass:"pharma",class:{ 'is-invalid': !_vm.isTestSelected(test).pharmaId },attrs:{"id":"pharmaSelect","value":_vm.isTestSelected(test).pharmaId,"options":_vm.pharmaOptionsFor(test)},on:{"change":function($event){return _vm.setTestPharma({ code: test.code, value: $event })}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Seleccione programa")]),(
                  _vm.testPerPharmaUnavailableTests.find(
                    function (opt) { return opt.testCode == test.code; }
                  )
                )?_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v(" "+_vm._s(_vm.testPerPharmaUnavailableTests .filter(function (opt) { return opt.testCode == test.code; }) .map(function (val) { return val.pharma.name + '*'; }) .join(', '))+" ")]):_vm._e()]):_vm._e()],1)],1)],1)])}),1),(!_vm.testsAreValid)?_c('p',{staticClass:"text-center text-danger"},[_vm._v(" Faltan seleccionar programas para algunos estudios ")]):_vm._e(),(
      _vm.testPerPharmaUnavailableTests.length &&
      _vm.tests.find(function (test) { return _vm.testPerPharmaUnavailableTests.some(function (t) { return t.testCode === test.code; }); }
      ) &&
      !!_vm.testPerPharmaUnavailableTests.find(function (t) { return t.pharma.caps.some(function (cap) { return cap.caps !== 0; }); }
      )
    )?_c('p',{staticClass:"text-accent"},[_vm._v(" El cupo de tests: "+_vm._s(_vm.testPerPharmaUnavailableTests .filter(function (t) { return t.pharma.caps.some(function (cap) { return cap.caps !== 0; }); }) .map(function (val) { return val.testCode.toUpperCase() + "(" + (val.pharma.name) + ")"; }) .join(', '))+" asignado por el/los sponsor/s para este mes han llegado al máximo permitido. Para solicitarlos de forma privada puede enviar mail a: "),_c('a',{staticClass:"text-accent",attrs:{"href":"mailto:presupuesto@biomakers.net"}},[_vm._v(" presupuesto@biomakers.net")]),_vm._v(" junto a la orden médica, para poder acercarle un presupuesto. ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }