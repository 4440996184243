<template>
  <div class="d-flex align-items-center" :class="positionClass">
    <div class="mr-3">
      {{ paginationDescription }}
    </div>
    <nav aria-label="Paginación del listado">
      <ul class="pagination mb-0">
        <li class="page-item" :class="{ disabled: !hasPreviousPage }">
          <a class="page-link" href="#" @click.stop.prevent="previousPage()">
            <i class="material-icons">chevron_left</i>
          </a>
        </li>
        <li
          v-if="hasPreviousPage && currentPage - 2 != 1"
          :id="'firstPageButton'"
          class="page-item"
        >
          <a href="#" class="page-link" @click.stop.prevent="goToPage(1)">1</a>
        </li>
        <li v-if="hasDoublePreviousPage" :id="'-2Button'" class="page-item">
          <a
            v-if="hasDoublePreviousPage"
            href="#"
            class="page-link"
            @click.stop.prevent="goToPage(currentPage - 2)"
            >{{ currentPage - 2 }}</a
          >
        </li>
        <li v-if="hasDoublePreviousPage" :id="'-2Button'" class="page-item">
          <a
            v-if="hasDoublePreviousPage"
            href="#"
            class="page-link"
            @click.stop.prevent="goToPage(currentPage - 1)"
            >{{ currentPage - 1 }}</a
          >
        </li>
        <li id="#currentPageSelector" class="page-item">
          <a class="page-link" disabled
            ><span class="text-accent">{{ currentPage }}</span></a
          >
        </li>
        <li v-if="hasNextPage" class="page-item">
          <a
            href="#"
            class="page-link"
            @click.stop.prevent="goToPage(currentPage + 1)"
            >{{ currentPage + 1 }}</a
          >
        </li>

        <li v-if="hasDoubleNextPage" :id="'+2Button'" class="page-item">
          <a
            href="#"
            class="page-link"
            @click.stop.prevent="goToPage(currentPage + 2)"
            >{{ currentPage + 2 }}</a
          >
        </li>
        <li class="page-item" :class="{ disabled: !hasNextPage }">
          <a class="page-link" href="#" @click.stop.prevent="nextPage()">
            <i class="material-icons">chevron_right</i>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    totalCount: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    currentCount: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
    nextPage: {
      type: Function,
      required: true,
    },
    previousPage: {
      type: Function,
      required: true,
    },
    goToPage: { type: Function, required: false },
    positionClass: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {}
  },
  computed: {
    numberOfPages() {
      return Number(Number(this.totalCount / this.limit).toFixed())
    },
    hasPreviousPage() {
      return this.currentPage > 1
    },
    hasNextPage() {
      return (this.currentPage - 1) * this.limit + this.limit < this.totalCount
    },
    hasDoubleNextPage() {
      return this.currentPage + 1 < this.numberOfPages
    },
    hasDoublePreviousPage() {
      return this.currentPage - 2 > 0
    },
    paginationDescription() {
      const from = (this.currentPage - 1) * this.limit + 1
      const to = Math.min(
        (this.currentPage - 1) * this.limit + this.currentCount,
        this.totalCount
      )
      return `${from}-${to} de ${this.totalCount}`
    },
  },
  mounted() {},
}
</script>
