<template>
  <form @submit="handleSave">
    <management-header :title="`${isNew ? 'Nuevo' : 'Editar'} tipo de tumor`">
      <button
        type="submit"
        :disabled="!isValid || !isDirty"
        class="btn btn-pill btn-accent"
      >
        {{ isNew ? 'Crear' : 'Actualizar' }}
      </button>
      <button
        v-if="!isNew"
        type="button"
        :disabled="!isValid"
        class="btn btn-pill btn-secondary"
        @click="handleToggleStatus"
      >
        {{ isEnabled ? 'Deshabilitar' : 'Habilitar' }}
      </button>
      <button
        type="button"
        class="btn btn-pill btn-secondary"
        @click="handleDiscard"
      >
        {{ isDirty ? 'Descartar' : 'Volver' }}
      </button>
    </management-header>

    <d-card class="mb-4">
      <d-card-body>
        <v-input
          v-model="primaryTumorName"
          :error="errors.name"
          name="name"
          placeholder="Nombre del tipo de tumor"
        />
        <v-input
          v-model="primaryTumorCode"
          :error="errors.code"
          name="code"
          placeholder="Código del tipo de tumor"
          help="Código identificador en CSVs y Dirmod"
        />
        <hr />
        <div id="icon">
          <img
            v-if="primaryTumorIcon"
            :src="primaryTumorIcon"
            :alt="`Ícono de tumor ${primaryTumorName || 'nuevo'}`"
          />
          <div class="form-group">
            <label for="icon">Ícono del tumor</label>
            <input
              id="icon"
              type="file"
              name="icon"
              class="form-control"
              @change="hasSetPrimaryTumorIcon"
            />
            <p>
              Debe ser una imagen, idealmente un SVG, con un aspect ratio de 1:1
              y fondo transparente.
            </p>
          </div>
        </div>
      </d-card-body>
    </d-card>
    <d-card v-if="primaryTumor" class="mb-4 bibliography">
      <d-card-body>
        <h5>Literatura asociada</h5>
        <div class="header">
          <v-input
            v-model="bibliographyIdentifiers"
            name="new_bibliography_identifiers"
            placeholder="Identificador único"
            help="Por ej el ISSN, DOI, etc"
          />
          <v-input
            v-model="bibliographyAuthors"
            name="new_bibliography_authors"
            placeholder="Autores de la bibliografía"
          />
          <v-input
            v-model="bibliographyTitle"
            name="new_bibliography_title"
            placeholder="Titulo"
          />
          <v-input
            v-model="bibliographyUrl"
            name="new_bibliography_url"
            placeholder="Url"
          />
          <button
            type="button"
            class="btn btn-secondary"
            :disabled="!(bibliography.identifiers && bibliography.url)"
            @click="addBibliography"
          >
            Agregar bibliografía
          </button>
        </div>
        <hr />
        <template v-for="(bibliography, idx) in bibliographies">
          <div :key="idx" class="col">
            <v-input
              :value="bibliography.identifiers"
              :name="`${idx}_new_bibliography_identifiers`"
              placeholder="Identificador único"
              @input="
                setPrimaryTumorBibliography({
                  value: $event,
                  index: idx,
                  key: 'identifiers',
                })
              "
            />
            <v-input
              :value="bibliography.authors"
              :name="`${idx}_new_bibliography_authors`"
              placeholder="Autores de la bibliografía"
              @input="
                setPrimaryTumorBibliography({
                  value: $event,
                  index: idx,
                  key: 'authors',
                })
              "
            />
            <v-input
              :value="bibliography.title"
              :name="`${idx}_new_bibliography_title`"
              placeholder="Titulo"
              @input="
                setPrimaryTumorBibliography({
                  value: $event,
                  index: idx,
                  key: 'title',
                })
              "
            />
            <v-input
              :value="bibliography.url"
              :name="`${idx}_new_bibliography_url`"
              placeholder="URL"
              @input="
                setPrimaryTumorBibliography({
                  value: $event,
                  index: idx,
                  key: 'url',
                })
              "
            />
            <v-checkbox
              value="E"
              :name="`${idx}_bibliography_row_status`"
              :model="[bibliography.rowStatus]"
              @change="
                setPrimaryTumorBibliography({
                  value: $event === bibliography.rowStatus ? 'D' : 'E',
                  index: idx,
                  key: 'rowStatus',
                })
              "
            >
              {{
                bibliography.rowStatus === 'E' ? 'Habilitado' : 'Deshabilitado'
              }}
            </v-checkbox>
          </div>
          <hr
            v-if="idx !== primaryTumor.bibliographies.length - 1"
            :key="`${idx}-hr`"
          />
        </template>
      </d-card-body>
    </d-card>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import VInput from '@/components/Form/VInput.vue'
import VCheckbox from '@/components/Form/VCheckbox.vue'
import ManagementHeader from '@/components/ManagementHeader.vue'

export default {
  components: {
    VInput,
    VCheckbox,
    ManagementHeader,
  },
  props: {
    primaryTumorId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters('primaryTumors', [
      'isNew',
      'isValid',
      'isDirty',
      'hasErrors',
      'errors',
      'primaryTumor',
      'bibliography',
      'bibliographies',
    ]),
    isEnabled() {
      return this.$store.state.primaryTumors.primaryTumor.rowStatus === 'E'
    },
    primaryTumorName: {
      get() {
        return this.$store.state.primaryTumors.primaryTumor.name
      },
      set(name) {
        this.setPrimaryTumorName(name)
      },
    },
    primaryTumorCode: {
      get() {
        return this.$store.state.primaryTumors.primaryTumor.code
      },
      set(code) {
        this.setPrimaryTumorCode(code)
      },
    },
    primaryTumorIcon: {
      get() {
        return this.$store.state.primaryTumors.primaryTumor.icon
      },
      set(icon) {
        this.setPrimaryTumorIcon(icon)
      },
    },
    bibliographyIdentifiers: {
      get() {
        return this.$store.state.primaryTumors.bibliography.identifiers
      },
      set(identifiers) {
        this.setBibliographyIdentifiers(identifiers)
      },
    },
    bibliographyAuthors: {
      get() {
        return this.$store.state.primaryTumors.bibliography.authors
      },
      set(authors) {
        this.setBibliographyAuthors(authors)
      },
    },
    bibliographyTitle: {
      get() {
        return this.$store.state.primaryTumors.bibliography.title
      },
      set(title) {
        this.setBibliographyTitle(title)
      },
    },
    bibliographyUrl: {
      get() {
        return this.$store.state.primaryTumors.bibliography.url
      },
      set(url) {
        this.setBibliographyUrl(url)
      },
    },
  },
  async mounted() {
    await this.getPrimaryTumors()
    await this.setPrimaryTumor(this.primaryTumorId)
  },
  methods: {
    ...mapActions('primaryTumors', [
      'getPrimaryTumors',
      'savePrimaryTumor',
      'setPrimaryTumor',
      'setPrimaryTumorName',
      'setPrimaryTumorCode',
      'setPrimaryTumorBibliography',
      'setBibliographyIdentifiers',
      'setBibliographyAuthors',
      'setBibliographyTitle',
      'setBibliographyUrl',
      'addBibliography',
      'setPrimaryTumorRowStatus',
      'hasSetPrimaryTumorIcon',
    ]),
    async handleSave(e) {
      if (e) e.preventDefault()
      await this.savePrimaryTumor(e)
      if (!this.hasErrors) this.$router.push({ name: this.NAMED_ROUTES.TUMORS })
    },
    handleDiscard() {
      this.$router.push({ name: this.NAMED_ROUTES.TUMORS })
    },
    handleToggleStatus() {
      const action = this.isEnabled ? 'deshabilitar' : 'habilitar'
      const result = window.confirm(
        `Al ${action} ${this.primaryTumor.name} se ${action}án los biomarcadores y configuraciones de biomarcadores para países que estén asociadas.`
      )
      if (!result) return
      const newRowStatus = this.isEnabled ? 'D' : 'E'
      this.setPrimaryTumorRowStatus(newRowStatus)
      this.handleSave()
    },
  },
}
</script>
<style lang="scss" scoped>
#icon {
  display: flex;
  gap: 1rem;

  img {
    max-width: 5.5rem;
    height: 100%;
  }
}

.bibliography .header,
.bibliography .col {
  width: 100%;
  display: inline-grid;
  gap: 16px;
  grid-template-columns: 1fr;
  align-items: center;

  .form-group {
    margin: 0;
  }
}

@media screen and (min-width: 768px) {
  .bibliography .header,
  .bibliography .col {
    grid-template-columns: 1fr 1fr;

    > :last-child {
      grid-column: span 2;
    }
  }
}

@media screen and (min-width: 1024px) {
  .bibliography .header,
  .bibliography .col {
    grid-template-columns: 1fr 1fr 1fr 0.5fr 130px;
    > :last-child {
      grid-column: span 1;
    }
  }

  .bibliography .col hr {
    display: none;
  }
}

.header button {
  margin-bottom: 1.2rem;
}

select {
  height: 3.2rem !important;
}
</style>
