<template>
  <form @submit="miniPost">
    <management-header :title="`${isNew ? 'Nuevo' : 'Editar'} panel`">
    </management-header>
    <d-card class="mb-4">
      <d-card-body>
        <v-input
          id="name"
          v-model="panelName"
          :errors="errors.name"
          name="name"
          placeholder="Nombre del panel"
          @input="checkName()"
        />
        <p v-if="!nameIsAvailable && isNew" style="color: red">
          Este nombre de panel ya existe
        </p>
        <p v-if="isNotName" style="color: red">Escriba un nombre de panel</p>
        <div v-if="!isNew">
          <label>Estado</label>
          <d-form-select
            id="enabled"
            v-model="panelRowStatus"
            :options="rowStatusOptions"
          >
            <option value="null" disabled>Seleccione un estado</option>
          </d-form-select>
        </div>
        <span
          v-if="!isNew"
          :class="panel.rowStatus === 'E' ? 'enabled' : 'disabled'"
        >
          {{ panel.rowStatus === 'E' ? 'Habilitado' : 'Deshabilitado' }}
        </span>
        <hr style="opacity: 0" />
        <button
          v-if="panel_has_created == false"
          name="post"
          class="btn btn-pill btn-accent"
          :disabled="(!nameIsAvailable && isNew) || isNotName"
          @click="checkName()"
        >
          <span
            class="text"
            style="align-items:center centerright: 30px;
          bottom: 10px;
          height: 10px;
          padding: 0 5px;
          display: inline;
          gap: 2px;"
            >{{
              !isNew ? 'Editar nombre y estado del Panel' : 'Agregar Panel'
            }}</span
          >
        </button>
        <button
          type="button"
          name="button"
          class="btn btn-pill btn-secondary"
          @click="handleDiscard"
        >
          Volver
        </button>
      </d-card-body>
    </d-card>
    <d-card>
      <d-card-body v-if="panel_has_created || !isNew">
        <d-row>
          <d-col>
            <h5>Configuración de los genes del panel</h5>
            <div class="header">
              <div class="form-group">
                <label>Gen</label>
                <d-form-select
                  id="genesFormSelect"
                  v-model="geneId"
                  :options="genesOptions"
                  :disabled="editGeneConfig == true"
                  @change="checkAddedGene()"
                >
                  <option value="null" disabled>Seleccione un gen</option>
                </d-form-select>
                <p
                  v-if="
                    geneAlreadyConfigured == true && editGeneConfig == false
                  "
                  style="color: red"
                >
                  Este gen ya está configurado
                </p>
              </div>
            </div>
            <div>
              <h5>Seleccione las categorias para dicho gen</h5>
              <div class="form-group">
                <d-row>
                  <d-form-checkbox
                    id="array-of-genes"
                    v-model="checkedCategories"
                    value="hotspot"
                    class="d-card"
                  >
                    Hotspot genes</d-form-checkbox
                  >
                </d-row>
                <d-row>
                  <d-form-checkbox
                    id="fusion"
                    v-model="checkedCategories"
                    value="fusion"
                    class="d-card"
                  >
                    Genic Fusion</d-form-checkbox
                  >
                </d-row>
                <d-row>
                  <d-form-checkbox
                    id="copynumber"
                    v-model="checkedCategories"
                    value="copynumber"
                    class="d-card"
                  >
                    Copy number gene</d-form-checkbox
                  >
                </d-row>
                <div>
                  <h5 id="genes_comfigured_title">
                    Estado del gen para este panel
                  </h5>
                  <label for="genes_comfigured_title" class="help text-danger">
                    Los genes deshabilitados de un panel desaparecen de
                    este</label
                  >
                  <d-form-select
                    id="enabled"
                    v-model="geneRowStatus"
                    :options="rowStatusOptions"
                    :disabled="isConfigNew"
                  >
                    <option value="null" disabled>Seleccione un estado</option>
                  </d-form-select>
                </div>
                <button
                  type="button"
                  class="btn btn-pill btn-accent"
                  style="display: inline"
                  :disabled="
                    geneAlreadyConfigured == true && editGeneConfig == false
                  "
                  @click="submitGenePanel"
                >
                  <span
                    class="text"
                    style="align-items:center centerright: 30px;
                  bottom: 10px;
                  height: 10px;
                  padding: 0 5px;
                  display: inline;
                  gap: 2px;"
                    >{{
                      editGeneConfig === true
                        ? 'Editar gen'
                        : 'Agregar Gen al panel'
                    }}</span
                  >
                  <!-- <span
                    class="material-icons"
                    style="aling-items:center
                  bottom: 10px;
                  height: 10px;
                  gap:2px"
                    >add</span
                  > -->
                </button>
              </div>
            </div>
          </d-col>
        </d-row>
      </d-card-body>
      <d-card v-if="panel_has_created || !isNew" class="result">
        <d-card-body>
          <h5>Genes configurados</h5>
          <p v-if="genesForThisPanel == undefined" style="color: red">
            Todavía no hay genes configurados
          </p>
          <div v-else-if="genesForThisPanel.length > 0" class="row">
            <div class="col">
              <h6>Gen</h6>
            </div>
            <div class="col">
              <h6>Categorias</h6>
            </div>
            <div class="col">
              <h6>Estado</h6>
            </div>
            <div class="col">
              <h6>Editar configuración</h6>
            </div>
          </div>
          <template
            v-for="(genes, idx) in genesForThisPanel"
            @load="checkAddedGene()"
          >
            <div :key="idx" class="row">
              <div class="col">
                <span>{{
                  $store.state.panels.genes.find(
                    (gene) => gene.id == genesForThisPanel[idx].geneId
                  ).name
                }}</span>
              </div>
              <div class="col">
                <div
                  v-for="(index, item) in genesForThisPanel[idx].category"
                  :key="item.index"
                  style="display: inline"
                >
                  <span>
                    {{ genesForThisPanel[idx].category[item] }}
                  </span>
                </div>
              </div>
              <div class="col">
                <span :class="genes.rowStatus === 'E' ? 'enabled' : 'disabled'">
                  {{
                    genesForThisPanel[idx].rowStatus === 'E'
                      ? 'Habilitado'
                      : 'Deshabilitado'
                  }}
                </span>
              </div>
              <div class="col">
                <button
                  class="material-icons"
                  type="button"
                  @click="updateGenePanel(genesForThisPanel[idx])"
                >
                  <a href="#name">view_agenda</a>
                </button>
              </div>
            </div>
          </template>
        </d-card-body>
      </d-card>
    </d-card>
  </form>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import VInput from '@/components/Form/VInput.vue'

import ManagementHeader from '@/components/ManagementHeader.vue'
export default {
  components: {
    VInput,

    ManagementHeader,
  },
  props: {
    panelId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      panel_has_created: false,
      nameIsAvailable: true,
      geneAlreadyConfigured: false,
      editGeneConfig: false,
      genesConfigured: [],
      isNotName: false,
    }
  },
  computed: {
    ...mapGetters('panels', [
      'panels',
      'genes',
      'panel',
      'panel_genes_configuration',
      'single_panel_gene_configuration',
      'genesEnabled',
      'genesForThisPanel',
      'errors',
      'isNew',
      'isConfigNew',
    ]),
    ...mapState('panels', [
      'panel',
      'single_panel_gene_configuration',
      'panel_genes_configuration',
      'genesForThisPanel',
    ]),
    panelName: {
      get() {
        return this.$store.state.panels.panel.name
      },
      set(name) {
        this.setPanelName(name)
      },
    },
    geneId: {
      get() {
        return this.$store.state.panels.single_panel_gene_configuration.gene_id
      },
      set(id) {
        this.setPanelGeneId(id)
      },
    },
    genesOptions() {
      return this.genesEnabled.map((gene) => ({
        value: gene.id,
        text: gene.name,
      }))
    },
    checkedCategories: {
      get() {
        return this.$store.state.panels.single_panel_gene_configuration.category
      },
      set(categoryField) {
        this.setCategory(categoryField)
      },
    },
    panelRowStatus: {
      get() {
        return this.$store.state.panels.panel.rowStatus
      },
      set(rs) {
        this.setPanelRs(rs)
      },
    },
    geneRowStatus: {
      get() {
        return this.$store.state.panels.single_panel_gene_configuration
          .rowStatus
      },
      set(rs) {
        this.setRowStatusForPanelGene(rs)
      },
    },
    rowStatusOptions() {
      return {
        E: ['E'],
        D: ['D'],
      }
    },
  },
  async mounted() {
    await this.discardConfig()
    await this.getPanels()
    await this.getGenes()
    await this.getPanelGenesConfiguration()
    await this.setPanel(this.panelId)
    await this.setSinglePanelGeneId(this.panelId)
    await this.getGenesForThisPanel(this.panelId)
  },
  methods: {
    ...mapActions('panels', [
      'getPanels',
      'getGenes',
      'setPanel',
      'setSinglePanelGeneId',
      'getPanelGenesConfiguration',
      'setPanelName',
      'setPanelRs',
      'setIdForPanel',
      'savePanel',
      'setPanelGeneId',
      'setCategory',
      'savePanelGenes',
      'deletePanelGeneConfig',
      'setIdForPanelGene',
      'updateThisGenePanelConfig',
      'getGenesForThisPanel',
      'setRowStatusForPanelGene',
      'setNullGenesForThisPanel',
    ]),
    checkName() {
      let panelName = document.getElementsByName('name')
      if (
        panelName[0].value.length == 0 ||
        panelName[0].value.trim().length == 0
      ) {
        this.isNotName = true
      } else if (
        this.panels.some((panel) => panel.name === panelName[0].value)
      ) {
        this.nameIsAvailable = false
        this.isNotName = false
      } else {
        this.nameIsAvailable = true
        this.isNotName = false
      }
    },
    checkAddedGene() {
      let selectedOptions = document.getElementById('genesFormSelect')
      if (
        selectedOptions != null &&
        this.genesForThisPanel != undefined &&
        this.genesForThisPanel.filter(
          (gene) => gene.geneId == selectedOptions.value
        )[0] != undefined
      ) {
        if (
          this.genesEnabled.filter(
            (obs) =>
              obs.id ===
              this.genesForThisPanel.filter(
                (gene) => gene.geneId == selectedOptions.value
              )[0].geneId
          )[0].id == selectedOptions.value
        ) {
          this.geneAlreadyConfigured = true
        }
      } else {
        this.geneAlreadyConfigured = false
      }
    },
    async miniPost(e) {
      if (e) {
        e.preventDefault()
      }
      // save an empty panel
      await this.savePanel()
      let new_panel_id = this.$store.state.panels.panels.find(
        (panel) => panel.name === this.$store.state.panels.panel.name
      ).id
      // set panel_id in single_panel_gene_configuration
      await this.setSinglePanelGeneId(new_panel_id)
      await this.setIdForPanel(new_panel_id)
      await this.getGenesForThisPanel(new_panel_id)
      this.panel_has_created = true
      window.location =
        window.origin +
        `#/management/panels/${this.$store.state.panels.panel.id}`
    },
    handleDiscard() {
      this.discardConfig()
      this.setNullGenesForThisPanel()
      this.$router.push({ name: this.NAMED_ROUTES.PANELS })
    },
    async discardConfig(e) {
      if (e) {
        e.preventDefault()
      }
      await this.setCategory([])
      await this.setIdForPanelGene(null)
      await this.setPanelGeneId(null)
      await this.setRowStatusForPanelGene('E')
      this.geneAlreadyConfigured = false
    },
    async submitGenePanel(e) {
      if (e) {
        e.preventDefault()
      }
      await this.savePanelGenes()
      await this.discardConfig()
      this.getGenesForThisPanel(this.panelId)
      this.editGeneConfig = false
    },
    async updateGenePanel(genePanelIdx, error) {
      if (error) {
        error.preventDefault()
      }
      await this.setPanelGeneId(genePanelIdx.geneId)
      await this.setSinglePanelGeneId(genePanelIdx.panelId)
      await this.setCategory(genePanelIdx.category)
      await this.setIdForPanelGene(genePanelIdx.id)
      await this.setRowStatusForPanelGene(genePanelIdx.rowStatus)
      this.editGeneConfig = true
    },
  },
}
</script>
<style lang="scss" scoped>
.result .header,
.result .col {
  width: 50%;
  display: inline;
  gap: 0.2px;
  grid-template-columns: 1fr;
  align-items: center;

  .form-group {
    margin: 0;
  }
}
.bibliography .header,
.bibliography .col {
  width: 100%;
  display: inline-grid;
  gap: 2px;
  grid-template-columns: 1fr;
  align-items: center;

  .form-group {
    margin: 0;
  }
}

@media screen and (min-width: 768px) {
  .result .header,
  .result .col,
  .bibliography .header,
  .bibliography .col {
    grid-template-columns: 1fr 1fr;

    > :last-child {
      grid-column: span 1;
    }
  }
}

@media screen and (min-width: 1024px) {
  .result .header,
  .result .col {
    grid-template-columns: 1fr 1fr 25px 1fr 120px;
    > :last-child {
      grid-column: span 1;
    }
  }

  .bibliography .header,
  .bibliography .col {
    grid-template-columns: 1fr 1fr 1fr 0.5fr 130px;
    > :last-child {
      grid-column: span 1;
    }
  }

  .result .col hr {
    display: none;
  }
  .bibliography .col hr {
    display: none;
  }
}

.header button {
  margin-bottom: 1.2rem;
}

select {
  height: 3.2rem !important;
}
.result button {
  transition: 15ms;
  border: none;
  background: transparent;
  outline: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);

  &:hover {
    transition: 15ms;
    background-color: rgba(0, 0, 0, 0.04);
  }
}
.enabled {
  color: green;
}
.disabled {
  color: red;
}
</style>
