<template>
  <div v-if="bibliographies.length" class="bibliography">
    <h2>Bibliografía</h2>
    <ol>
      <li v-for="bibliography in bibliographies" :key="bibliography.id">
        <a :href="bibliography.url" target="_blank">
          {{ bibliography.authors }}.
          <strong>«{{ bibliography.title }}». </strong>
          <span class="identifiers">{{ bibliography.identifiers }}</span>
        </a>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  props: {
    bibliographies: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
h2 {
  font-size: var(--font-lg);
  color: var(--grey-2);
  font-weight: 500;
}

.bibliography {
  padding-top: 34px;
  border-top: 1px solid var(--dim-10);

  li {
    padding-left: 12px;
    padding-bottom: var(--font-md);
  }

  li,
  a {
    color: var(--grey-1);
    text-decoration: none;
    font-size: var(--font-lg);
    span {
      color: var(--grey-2);
    }
  }
}
</style>
