import axios from 'axios'

const getDefaultState = () => ({
  status: null,
  errors: {},
  dirty: false,
  testsGroupers: [],
})

const getters = {
  hasErrors: (state) => Object.entries(state.errors).length > 0,
  errors: (state) => state.errors,
  testsGroupers: (state) => state.testsGroupers,
}
const actions = {
  async getTestsGrouper({ commit }) {
    commit('REQUEST_INIT')
    try {
      let testsGrouperData = (
        await axios.get('api2/settings/test_groupers/get_all/')
      ).data
      if (testsGrouperData) commit('SET_TEST_GROUPER_DATA', testsGrouperData)
      commit('REQUEST_SUCCESS')
    } catch (error) {
      commit('REQUEST_ERROR', error)
    }
  },
}

const mutations = {
  REQUEST_INIT(state) {
    state.status = 'loading'
  },
  REQUEST_SUCCESS(state) {
    state.status = 'success'
    state.errors = {}
    state.dirty = false
  },
  REQUEST_ERROR(state, message) {
    state.status = 'error'
    state.errors = message
  },
  SET_TEST_GROUPER_DATA(state, data) {
    state.testsGroupers = data
  },
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  actions,
  mutations,
}
