<template>
  <div class="main-content-container container-fluid h-100 p-4">
    <d-row no-gutters class="h-100">
      <d-col cols="12" lg="5" md="7" class="auth-form mx-auto my-auto">
        <d-card>
          <d-card-body>
            <!-- Logo -->
            <img
              class="d-table mx-auto mb-3"
              src="../assets/images/logos/pluton-2x-color.png"
              alt="Pluton "
            />

            <!-- Title -->
            <h5 class="auth-form__title text-center mb-4">
              Crear Cuenta &mdash;
              <span class="text-accent">{{ stepName }}</span>
            </h5>

            <!-- Form Fields -->
            <!-- Step 1 -->

            <d-form
              v-show="currentStep === 'email_password'"
              @submit="nextStep"
            >
              <div class="form-group">
                <d-input
                  v-model="current_item.email"
                  :state="states.email"
                  type="email"
                  required
                  placeholder="Ingrese su email"
                />
                <d-form-invalid-feedback>{{
                  feedback.email
                }}</d-form-invalid-feedback>
              </div>

              <password-entry
                v-model="current_item.password"
                :change="false"
                @password_entered="get_password"
              />

              <div class="form-group d-flex justify-content-center">
                <d-checkbox
                  id="register-accept-terms"
                  v-model="accept_terms_and_conditions"
                >
                  Acepto los
                  <a href="/terms.html" target="_blank"
                    >Términos y Condiciones</a
                  >.
                </d-checkbox>
              </div>

              <div class="d-flex justify-content-center">
                <d-button
                  ref="step-1-btn"
                  pill
                  :disabled="
                    !(
                      accept_terms_and_conditions &&
                      current_item.email &&
                      current_item.password
                    )
                  "
                  type="submit"
                  class="btn-accent d-inline-block mx-auto loader-button"
                >
                  Siguiente
                </d-button>
              </div>
            </d-form>
            <!-- Step 2 -->
            <d-form
              v-show="currentStep === 'personal_details'"
              @submit="nextStep"
            >
              <div class="form-group">
                <form-label required> Nombre </form-label>
                <d-input
                  v-model="current_item.first_name"
                  :state="states.first_name"
                  type="text"
                  placeholder="Nombre"
                />
                <d-form-invalid-feedback
                  >Este campo es obligatorio</d-form-invalid-feedback
                >
              </div>
              <div class="form-group">
                <form-label required> Apellido </form-label>
                <d-input
                  v-model="current_item.last_name"
                  :state="states.last_name"
                  type="text"
                  placeholder="Apellido"
                />
                <d-form-invalid-feedback
                  >Este campo es obligatorio</d-form-invalid-feedback
                >
              </div>
              <div class="form-group">
                <form-label required> Teléfono </form-label>
                <d-input
                  v-model="current_item.tel_mobile"
                  :state="states.tel_mobile"
                  type="text"
                  placeholder="Teléfono Celular"
                />
                <d-form-invalid-feedback
                  >Este campo es obligatorio</d-form-invalid-feedback
                >
              </div>

              <!-- Phone Group -->
              <div
                v-show="personal_additional_phones_count > 0"
                class="form-group"
              >
                <form-label> Teléfono Alternativo 1 </form-label>
                <d-input
                  v-model="current_item.tel_additional_1"
                  :state="states.tel_additional_1"
                  type="text"
                  placeholder="Teléfono"
                />
              </div>
              <div
                v-show="personal_additional_phones_count > 1"
                class="form-group"
              >
                <form-label> Teléfono Alternativo 2 </form-label>
                <d-input
                  v-model="current_item.tel_additional_2"
                  :state="states.tel_additional_2"
                  type="text"
                  placeholder="Teléfono"
                />
              </div>
              <div
                v-show="personal_additional_phones_count > 2"
                class="form-group"
              >
                <form-label> Teléfono Alternativo 3 </form-label>
                <d-input
                  v-model="current_item.tel_additional_3"
                  :state="states.tel_additional_3"
                  type="text"
                  placeholder="Teléfono"
                />
              </div>
              <div
                v-show="personal_additional_phones_count > 3"
                class="form-group"
              >
                <form-label> Teléfono Alternativo 4 </form-label>
                <d-input
                  v-model="current_item.tel_additional_4"
                  :state="states.tel_additional_4"
                  type="text"
                  placeholder="Teléfono"
                />
              </div>
              <div
                v-show="personal_additional_phones_count > 4"
                class="form-group"
              >
                <form-label> Teléfono Alternativo 5 </form-label>
                <d-input
                  v-model="current_item.tel_additional_5"
                  :state="states.tel_additional_5"
                  type="text"
                  placeholder="Teléfono"
                />
              </div>

              <d-button
                v-show="personal_additional_phones_count < 5"
                size="sm"
                theme="light"
                class="mb-3 pull-right"
                type="button"
                @click.stop.prevent="personal_additional_phones_count++"
              >
                + Agregar otro teléfono (optativo)
              </d-button>
              <!-- End Phone Group -->

              <div class="form-group">
                <form-label required> País </form-label>
                <d-form-select
                  v-model="current_item.country_id"
                  :options="countries"
                  :state="states.country_id"
                  placeholder="País"
                  name="country"
                >
                  <option :value="null" disabled>Seleccione un país</option>
                </d-form-select>
                <d-form-invalid-feedback
                  >Este campo es obligatorio</d-form-invalid-feedback
                >
              </div>
              <div class="form-group">
                <form-label required> Matrícula Nacional </form-label>
                <d-input
                  v-model="current_item.national_license"
                  :state="states.national_license"
                  type="text"
                  placeholder="Matrícula Nacional"
                />
                <d-form-invalid-feedback
                  >Este campo es obligatorio</d-form-invalid-feedback
                >
              </div>
              <div class="form-group">
                <form-label> Matrícula Provincial (si aplica) </form-label>
                <d-input
                  v-model="current_item.state_license"
                  :state="states.state_license"
                  type="text"
                  placeholder="Matrícula Provincial (si aplica)"
                />
              </div>
              <div class="d-flex mt-4">
                <d-button
                  type="button"
                  outline
                  pill
                  class="btn-outline-light"
                  @click.stop.prevent="previousStepFrom('personal_details')"
                >
                  Anterior
                </d-button>
                <button
                  ref="step-2-btn"
                  type="submit"
                  class="
                    btn btn-pill btn-accent
                    ml-auto
                    loader-button
                    btn-next-step
                  "
                >
                  Siguiente
                </button>
              </div>
            </d-form>
            <!-- Step 3 -->
            <d-form
              v-show="currentStep === 'pickup_details'"
              @submit="nextStep"
            >
              <p class="text-center">
                <small class="text-gray text-grey">
                  Necesitamos una (o más) direcciones para coordinar de retiro
                  para las muestras. Puede agregar o modificar puntos de retiro
                  en cualquier momento una vez finalizado el proceso de
                  registro.
                </small>
              </p>

              <pickup-locations
                ref="pickup_locations"
                v-model="current_item.pickup_locations"
                :country="currentCountryName"
              />

              <div class="d-flex mt-4 justify-content-between">
                <d-button
                  theme="light"
                  pill
                  type="button"
                  outline
                  @click.stop.prevent="previousStepFrom('pickup_details')"
                >
                  Anterior
                </d-button>
                <d-button
                  ref="step-3-btn"
                  pill
                  type="submit"
                  class="btn-accent loader-button btn-next-step-3"
                >
                  Siguiente
                </d-button>
              </div>
            </d-form>
            <!-- Step 4 -->
            <div v-show="currentStep == 'confirm_email'">
              <p>
                Enviamos un correo de confirmación a su cuenta de mail ({{
                  current_item.email
                }}).
              </p>
              <p>
                Ingrese a su cuenta de correo y haga click en el link de
                confirmación para continuar.
              </p>

              <p>
                Si no recibió ningún correo, por favor revise su carpeta de
                Correo No Deseado o Spam.
              </p>

              <p v-if="validation_resent" class="alert alert-info">
                Correo de confirmación reenviado correctamente.
              </p>
              <d-button
                id="resend-email"
                pill
                type="button"
                class="btn-accent d-table mx-auto"
                @click="resendValidationEmail()"
              >
                Reenviar Correo
              </d-button>
            </div>
          </d-card-body>
        </d-card>

        <!-- Meta -->
        <div class="auth-form__meta d-flex mt-4">
          <router-link :to="{ name: NAMED_ROUTES.FORGOT_PASSWORD }">
            Reestablecer contraseña
          </router-link>
          <router-link :to="{ name: NAMED_ROUTES.LOGIN }" class="ml-auto">
            Ingresar
          </router-link>
        </div>
      </d-col>
    </d-row>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

import FormLabel from 'src/components/Form/Label'
import PlutonBaseMixin from 'src/components/mixins/PlutonBaseMixin'
import PickupLocations from 'src/components/PickupLocations'
import PasswordEntry from 'src/components/Form/PasswordEntry'

export default {
  name: 'Register',
  components: { FormLabel, PickupLocations, PasswordEntry },
  mixins: [PlutonBaseMixin],
  props: {},
  data() {
    return {
      states: {
        email: null,
        password: null,
        first_name: null,
        last_name: null,
        tel_mobile: null,
        tel_office: null,
        country_id: null,
        national_license: null,
        state_license: null,
      },
      color: {
        mayus: false,
        especial_char: false,
        min_len: false,
      },
      feedback: {
        email: 'Ingrese una dirección de email válida',
      },
      currentStep: 'email_password',
      accept_terms_and_conditions: false,
      personal_additional_phones_count: 0,
      public_token: null,
      validation_resent: false,
      current_item: {
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        tel_mobile: '',
        tel_office: '',
        country_id: null,
        national_license: '',
        state_license: '',
        tel_additional_1: '',
        tel_additional_2: '',
        tel_additional_3: '',
        tel_additional_4: '',
        tel_additional_5: '',
        pickup_locations: [],
      },
    }
  },
  computed: {
    countries() {
      return this.$store.getters['countries/countriesEnabled'].map(
        (country) => ({
          value: country.id,
          text: country.name,
        })
      )
    },
    stepName() {
      const stepNames = {
        email_password: 'Acceso',
        personal_details: 'Datos Personales',
        pickup_details: 'Retiro de Muestras',
        confirm_email: 'Confirmación de e-mail',
      }
      return stepNames[this.currentStep]
    },
    currentCountryName() {
      if (!this.current_item.country_id) return null
      const country = this.countries.find(
        (c) => c.value == this.current_item.country_id
      )
      if (!country) return null
      return country.text
    },
  },
  async mounted() {
    this.getCountries()
  },
  methods: {
    ...mapActions('countries', ['getCountries']),
    validatePersonalDetails() {
      let validation = true
      ;[
        'first_name',
        'last_name',
        'tel_mobile',
        'country_id',
        'national_license',
      ].forEach((field) => {
        this.states[field] = null
        if (!this.current_item[field]) {
          this.states[field] = 'invalid'
          validation = false
        }
      })
      return validation
    },

    validateEmail() {
      if (!this.current_item.email) {
        this.states.email = 'invalid'
        return false
      }
      this.states.email = null
      return true
    },

    get_password(val) {
      this.current_item.password = val.pass
      this.states.password = val.state
    },

    validatePassword() {
      return this.states.password
    },

    previousStepFrom(from) {
      if (from == 'personal_details') this.currentStep = 'email_password'
      if (from == 'pickup_details') this.currentStep = 'personal_details'
    },

    async nextStep(e) {
      e.preventDefault()
      if (this.currentStep === 'email_password') {
        const email_validation = this.validateEmail()
        const password_validation = this.validatePassword()

        if (!email_validation || !password_validation) return false

        this.setLoading(true)
        const emailValidationResponse = await this.axios.get(
          `/api/auth/is-valid-email?email=${this.current_item.email}`
        )

        if (emailValidationResponse.data.success) {
          this.currentStep = 'personal_details'
        } else {
          this.states.email = 'invalid'
          this.feedback.email =
            'Este correo electrónico ya está registrado en el sistema.'
        }

        return this.setLoading(false)
      }

      if (this.currentStep === 'personal_details') {
        const personal_details_validation = this.validatePersonalDetails()
        if (!personal_details_validation) {
          this.alertFormError(
            'Por favor, revise que los datos del formulario estén correctos e intente nuevamente.'
          )
          return false
        }

        return (this.currentStep = 'pickup_details')
      }

      if (this.currentStep === 'pickup_details') {
        this.setLoading(true)
        if (this.$refs['pickup_locations'].isValid()) {
          const signUpResponse = await this.axios({
            method: 'post',
            url: '/api/auth/sign-up',
            data: this.current_item,
          })
          if (signUpResponse.data.success) {
            this.public_token = signUpResponse.data.public_token
            this.currentStep = 'confirm_email'
          }
          this.setLoading(false)
        } else {
          this.setLoading(false)
          this.alertFormError(
            'Por favor, revise que los datos del formulario estén correctos e intente nuevamente.'
          )
        }
      }
    },
    async resendValidationEmail() {
      self.validation_resent = false
      this.setLoading(true)

      const response = await this.axios.get(
        '/api/auth/resend-validation-email?token=' + this.public_token
      )
      if (response.data.success) this.validation_resent = true

      this.setLoading(false)
    },
  },
}
</script>

<style scoped lang="scss">
.inner-card .card-body {
  box-shadow: none !important;
}

.inner-card {
  border: 1px solid #eee;
  box-shadow: none;
  -webkit-box-shadow: none;

  .card-header {
    padding: 0.5rem 0.8rem;
  }

  .card-body {
    padding: 0.8rem;
  }
}
</style>
