// shuffle method suggested here: https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
export function shuffle(array) {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

export const BREAKPOINTS = {
  sm: 567,
  md: 768,
  lg: 992,
  xl: 1200,
}

export const getOptionsFor = (obj = {}) =>
  Object.keys(obj).map((key) => ({
    value: key,
    text: obj[key],
  }))
export function getFormattedText(array) {
  let k = ''
  for (let index = 0; index < array.length; index++) {
    const element = array[index]
    k = k + ' ' + element + ','
  }
  return k
}
export const parse_pydantic_errors = (response) => {
  const errors_map = {
    'type_error.none.not_allowed': 'Este campo es requerido',
    'value_error.email': 'Este e-mail no es válido',
  }
  const errors = {}
  response.detail.forEach((detail) => {
    let attribute = detail.loc[detail.loc.length - 1]
    let message =
      detail.type in errors_map ? errors_map[detail.type] : detail.msg
    errors[attribute] = message
  })
  return errors
}
