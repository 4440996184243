import { getFiltersDefaultState } from './index'

export default {
  updateStudyFilters(state, filter) {
    state.study = {
      ...state.study,
      [filter.field]: filter.value,
    }
  },
  updateStudyTestFilters(state, filter) {
    state.studyTest = {
      ...state.studyTest,
      [filter.field]: filter.value,
    }
  },
  updatePatientFilters(state, filter) {
    state.patient = {
      ...state.patient,
      [filter.field]: filter.value,
    }
  },
  initializeFilters(state, userRole = 'doctor') {
    state.initialized = true
    Object.assign(state, getFiltersDefaultState(userRole))
  },
  clearFilters(state, { userRole, secondaryRole }) {
    Object.assign(state, getFiltersDefaultState(userRole, secondaryRole))
  },
}
