import mutations from './mutations'
import actions from './actions'

const STUDY_FILTERS_INITIAL_STATE = () => ({
  primaryTumor: null,
  status: new Array(),
  createdAtFrom: null,
  createdAtTo: null,
  search: null,
  sampleType: null,
  pharma: null,
  umbrella: [],
})
const STUDY_FILTERS_INITIAL_STATE_FOR_NAVIGATOR = () => ({
  primaryTumor: null,
  status: new Array(),
  createdAtFrom: null,
  createdAtTo: null,
  search: null,
  sampleType: null,
  pharma: 'FORTREA',
})

const STUDY_TEST_FILTERS_INITIAL_STATE = () => ({
  biomarkerId: new Array(),
  result: new Array(),
})

const PATIENT_FILTERS_INITIAL_STATE = () => ({
  name: null,
  gender: null,
  diagnosisLocation: null,
  smokerStatus: null,
  diagnosis: null,
  ageRangeStart: null,
  ageRangeEnd: null,
  diagnosisStage: new Array(),
  previousTherapies: null,
})

export const getFiltersDefaultState = (userRole, secondaryRole) => {
  const baseFilters = {
    study: STUDY_FILTERS_INITIAL_STATE(),
    patient: PATIENT_FILTERS_INITIAL_STATE(),
    studyTest: STUDY_TEST_FILTERS_INITIAL_STATE(),
  }
  if (userRole === 'lab') {
    if (secondaryRole === 'navigator') {
      baseFilters.study = STUDY_FILTERS_INITIAL_STATE_FOR_NAVIGATOR()
    }
  }
  return baseFilters
}

export default {
  namespaced: true,
  state: {
    ...getFiltersDefaultState(),
    initialized: false,
  },
  mutations,
  actions,
}
