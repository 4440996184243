<template>
  <div>
    <management-header :title="title">
      <router-link
        :to="{ name: 'countryCreate' }"
        tag="button"
        class="btn btn-pill btn-accent"
      >
        Agregar
      </router-link>
    </management-header>

    <d-row>
      <d-col v-for="country in countries" :key="country.id" md="4" class="p-4">
        <router-link
          :to="{ name: 'country', params: { countryId: country.id } }"
          tag="div"
          class="card p-4 h-100"
        >
          <header>
            <h3>{{ country.name }}</h3>
            <p :class="country.rowStatus === 'E' ? 'enabled' : 'disabled'">
              {{ country.rowStatus === 'E' ? 'Habilitado' : 'Deshabilitado' }}
            </p>
          </header>
          <p v-if="country.settings.length > 0">
            {{ country.settings.length }} biomarcador{{
              (country.settings.length > 1 && 'es') || ''
            }}
            configurados
            <br />
            {{ country.countryUmbrellaSetting.length }} umbrella{{
              (country.countryUmbrellaSetting.length > 1 && 's') || ''
            }}
            configurados
          </p>
          <p v-else>
            No tiene biomarcadores configurados
            <br />
            No tiene umbrellas configurados
          </p>
        </router-link>
      </d-col>
    </d-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ManagementHeader from '@/components/ManagementHeader.vue'

export default {
  components: {
    ManagementHeader,
  },
  computed: {
    ...mapGetters('countries', ['countries']),
    title() {
      return this.countries.length === 1
        ? '1 país'
        : `${this.countries.length} países`
    },
  },
  async mounted() {
    this.getCountries()
  },
  methods: {
    ...mapActions('countries', ['getCountries']),
  },
}
</script>

<style lang="scss" scoped>
.card {
  cursor: pointer;

  h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 400;
    margin: 0;
  }

  .enabled {
    color: green;
  }
  .disabled {
    color: red;
  }
  p {
    margin: 0;
  }
}
</style>
