import axios from 'axios'

import { ROW_STATUS } from '@/utils/constants'

const getEmptyGenes = () => ({
  id: null,
  code: '',
  name: '',
  rowStatus: 'E',
})

const getEmptyPanel = () => ({
  id: null,
  name: '',
  rowStatus: 'E',
})
const getEmptyPanelGeneConfig = () => ({
  id: null,
  panel_id: null,
  gene_id: null,
  category: [],
  rowStatus: 'E',
})
const getEmptyUmbrella = () => ({
  id: null,
  name: '',
  code: '',
  panelsId: null,
  biomarkers: [],
  rowStatus: 'E',
})
const getDefaultState = () => ({
  status: null,
  errors: {},
  dirty: false,
  panel: getEmptyPanel(),
  panels: [getEmptyPanel()],
  genes: [getEmptyGenes()],
  gene: getEmptyGenes(),
  panel_genes_configuration: [getEmptyPanelGeneConfig()],
  single_panel_gene_configuration: getEmptyPanelGeneConfig(),
  genesForThisPanel: [],
  umbrellas: [getEmptyUmbrella()],
  umbrella: getEmptyUmbrella(),
})

const getters = {
  genes: (state) => state.genes,
  gene: (state) => state.gene,
  panel: (state) => state.panel,
  panels: (state) => state.panels,
  panelsEnabled: (state) =>
    state.panels.filter((panel) => panel.rowStatus === ROW_STATUS.ENABLED),
  genesEnabled: (state) =>
    state.genes.filter((gene) => gene.rowStatus === ROW_STATUS.ENABLED),
  umbrellasEnabled: (state) =>
    state.umbrellas.filter(
      (umbrella) => umbrella.rowStatus === ROW_STATUS.ENABLED
    ),
  isDirty: (state) => state.dirty,
  isNew: (state) =>
    !(
      state.panel.id &&
      state.panels.find((panel) => panel.id === state.panel.id)
    ),
  isConfigNew: (state) =>
    !(
      state.single_panel_gene_configuration.id &&
      state.panel_genes_configuration.find(
        (cfg) => cfg.id === state.single_panel_gene_configuration.id
      )
    ),
  isUmbrellaNew: (state) =>
    !(
      state.umbrella.id &&
      state.umbrellas.find((u) => u.id === state.umbrella.id)
    ),
  umbrellas: (state) => state.umbrellas,
  umbrella: (state) => state.umbrella,
  panel_genes_configuration: (state) => state.panel_genes_configuration,
  single_panel_gene_configuration: (state) =>
    state.single_panel_gene_configuration,
  genesForThisPanel: (state) => state.genesForThisPanel,
  isGeneNew: (state) =>
    !(state.gene.id && state.genes.find((gene) => gene.id === state.gene.id)),
  hasErrors: (state) => Object.entries(state.errors).length > 0,
  errors: (state) => state.errors,
}
const actions = {
  async getGenes({ commit }) {
    commit('REQUEST_INIT')
    try {
      const genes = (await axios.get('/api2/panels/genes')).data
      commit('SET_GENES', genes)
      commit('REQUEST_SUCCESS')
    } catch (err) {
      commit('REQUEST_ERROR', err)
    }
  },
  async getPanels({ commit }) {
    commit('REQUEST_INIT')
    try {
      const panels = (await axios.get('/api2/panels/')).data
      commit('SET_PANELS', panels)
      commit('REQUEST_SUCCESS')
    } catch (err) {
      commit('REQUEST_ERROR', err)
    }
  },

  async getPanelGenesConfiguration({ commit }) {
    commit('REQUEST_INIT')
    try {
      const panel_genes_configuration = (
        await axios.get('/api2/panels/panelGenesConfig')
      ).data
      commit('SET_PANELS_GENES_CONFIG', panel_genes_configuration)
      commit('REQUEST_SUCCESS')
    } catch (err) {
      commit('REQUEST_ERROR', err)
    }
  },
  async saveGene({ commit, state, getters }) {
    const method = getters.isGeneNew ? 'post' : 'put'
    const urlgene =
      method === 'post'
        ? '/api2/panels/genes'
        : `/api2/panels/genes/${state.gene.id}`
    commit('REQUEST_INIT')
    try {
      const gen = (
        await axios({
          method: method,
          url: urlgene,
          data: { ...state.gene },
        })
      ).data
      commit('REQUEST_SUCCESS')
      const genes = [...state.genes]
      if (method === 'post') {
        genes.push(gen)
      } else {
        const indexG = genes.findIndex((c) => c.id === gen.id)
        genes[indexG] = gen
      }
      commit('SET_GENES', genes)
    } catch (e) {
      commit('REQUEST_ERROR', e)
    }
  },
  async savePanelGenes({ commit, state, getters }) {
    const method = getters.isConfigNew ? 'post' : 'put'
    const urlgene =
      method === 'post'
        ? '/api2/panels/panelGenesConfig'
        : `/api2/panels/panelGenesConfig/${state.single_panel_gene_configuration.id}`
    commit('REQUEST_INIT')
    try {
      const single_panel_gene_configuration = (
        await axios({
          method: method,
          url: urlgene,
          data: { ...state.single_panel_gene_configuration },
        })
      ).data
      commit('REQUEST_SUCCESS')
      const panelGenesConfig = [...state.panel_genes_configuration]
      if (method === 'post') {
        panelGenesConfig.push(single_panel_gene_configuration)
      } else {
        const indexG = panelGenesConfig.findIndex(
          (c) => c.gene_id === single_panel_gene_configuration.gene_id
        )
        panelGenesConfig[indexG] = single_panel_gene_configuration
      }
      commit('SET_PANELS_GENES_CONFIG', panelGenesConfig)
    } catch (e) {
      commit('REQUEST_ERROR', e)
    }
  },
  setPanel({ commit, state }, panelId) {
    const panel = state.panels.find((panel) => panel.id === panelId)
    if (panel) commit('SET_PANEL', panel)
    else commit('SET_PANEL', getEmptyPanel())
  },
  setIdForPanel({ commit }, id) {
    commit('SET_PANEL_ID_FOR_PANEL', id)
  },
  setSinglePanelGeneId({ commit, state }, panelId) {
    const singlePanelConfig = state.panels.find((panel) => panel.id === panelId)
    if (singlePanelConfig) commit('SET_PANEL_ID', singlePanelConfig.id)
    else commit('SET_PANEL_ID', null)
  },
  setIdForPanelGene({ commit, state }, id) {
    const singlePanelGene = state.panel_genes_configuration.find(
      (config) => config.id === id
    )
    if (singlePanelGene) commit('SET_PANEL_GENE_CONFIG_ID', singlePanelGene.id)
    else commit('SET_PANEL_GENE_CONFIG_ID', null)
  },
  setRowStatusForPanelGene({ commit }, row_status) {
    commit('SET_PANEL_GENE_CONFIG_RS', row_status)
  },
  async deletePanelGeneConfig({ commit }, id, data) {
    commit('REQUEST_INIT')
    try {
      await axios.delete(`/api2/panels/panelGenesConfig/${id}`, data)
      commit('REQUEST_SUCCESS')
    } catch (e) {
      commit('REQUEST_ERROR', e)
    }
  },
  async getGenesForThisPanel({ commit }, panelId) {
    commit('REQUEST_INIT')
    try {
      let genes = (await axios.get(`/api2/panels/panelGenesConfig/${panelId}`))
        .data
      if (genes != undefined) {
        commit('GET_GENES_FOR_THIS_PANEL', genes)
      } else {
        commit('GET_GENES_FOR_THIS_PANEL', undefined)
      }
      commit('REQUEST_SUCCESS')
    } catch (err) {
      commit('REQUEST_ERROR', err)
    }
  },
  setNullGenesForThisPanel({ commit }) {
    commit('GET_GENES_FOR_THIS_PANEL', undefined)
  },
  async savePanel({ commit, state, getters }) {
    if (!getters.isDirty) return
    const method = getters.isNew ? 'post' : 'put'
    const url =
      method === 'post' ? '/api2/panels/' : `/api2/panels/${state.panel.id}`
    commit('REQUEST_INIT')

    try {
      const panel = (
        await axios({
          method,
          url,
          data: { ...state.panel },
        })
      ).data
      commit('REQUEST_SUCCESS')
      const panels = [...state.panels]
      if (method === 'post') {
        panels.push(panel)
      } else {
        const index = panels.findIndex((c) => c.id === panel.id)
        panels[index] = panel
      }
      commit('SET_PANELS', panels)
    } catch (e) {
      commit('REQUEST_ERROR', e)
    }
  },
  async saveUmbrella({ commit, state, getters }) {
    const method = getters.isUmbrellaNew ? 'post' : 'put'
    const url =
      method === 'post'
        ? '/api2/panels/umbrella'
        : `/api2/panels/umbrella/${state.umbrella.id}`
    commit('REQUEST_INIT')
    try {
      const umbrella = (
        await axios({
          method,
          url,
          data: { ...state.umbrella },
        })
      ).data
      commit('REQUEST_SUCCESS')
      const umbrellas = [...state.umbrellas]
      if (method === 'post') {
        umbrellas.push(umbrella)
      } else {
        const index = umbrellas.findIndex((c) => c.id === umbrella.id)
        umbrellas[index] = umbrella
      }
      commit('SET_UMBRELLAS', umbrellas)
    } catch (e) {
      commit('REQUEST_ERROR', e)
    }
  },
  async setPanelName({ commit }, name) {
    commit('SET_PANEL_NAME', name)
  },
  async setPanelId({ commit }, id) {
    commit('SET_PANEL_ID_UMBRELLA', id)
  },
  async setPanelGeneId({ commit }, id) {
    commit('SET_PANEL_GENE_ID', id)
  },
  async setPanelRs({ commit }, rs) {
    commit('SET_PANEL_ROW_STATUS', rs)
  },
  async setCategory({ commit }, category) {
    commit('SET_CATEGORY', category)
  },
  async setIdForGene({ commit }, id) {
    commit('SET_GENE_ID', id)
  },
  async setGeneName({ commit }, name) {
    commit('SET_GENE_NAME', name)
  },
  async setGeneCode({ commit }, code) {
    commit('SET_GENE_CODE', code)
  },
  async setGeneRs({ commit }, rs) {
    commit('SET_GENE_ROW_STATUS', rs)
  },
  async getUmbrellas({ commit }) {
    commit('REQUEST_INIT')
    try {
      const umbrellas = (await axios.get('/api2/panels/umbrella')).data
      commit('SET_UMBRELLAS', umbrellas)
      commit('REQUEST_SUCCESS')
    } catch (err) {
      commit('REQUEST_ERROR', err)
    }
  },
  async setUmbrellaRS({ commit }, rs) {
    commit('SET_UMBRELLA_ROW_STATUS', rs)
  },
  async setUmbrellaName({ commit }, name) {
    commit('SET_UMBRELLA_NAME', name)
  },
  async setUmbrellaCode({ commit }, code) {
    commit('SET_UMBRELLA_CODE', code)
  },
  async setBiomarkerToUmbrella({ commit }, bmk) {
    commit('SET_BIOMARKER_TO_UMBRELLA', bmk)
  },
  saveUmbrellaPharmaConfig({ commit }, bmkConfig) {
    commit('SAVE_UMBRELLA_PHARMA_CONFIG', bmkConfig)
  },
  async getUmbrella({ commit }, umbrellaId) {
    let umbrella = (await axios.get(`/api2/panels/umbrella/${umbrellaId}`)).data
    commit('SET_UMBRELLA', umbrella)
  },
  async setUmbrella({ commit, state }, umbrellaId) {
    const umbrella = state.umbrellas.find((u) => u.id === umbrellaId)
    if (umbrella) commit('SET_UMBRELLA', umbrella)
    else commit('SET_UMBRELLA', getEmptyUmbrella())
  },
}
const mutations = {
  REQUEST_INIT(state) {
    state.status = 'loading'
  },
  REQUEST_SUCCESS(state) {
    state.status = 'success'
    state.errors = {}
    state.dirty = false
  },
  REQUEST_ERROR(state, message) {
    state.status = 'error'
    state.errors = message
  },
  CLEAR_ERRORS(state) {
    state.errors = {}
  },
  GET_GENES_FOR_THIS_PANEL(state, genes) {
    state.dirty = true
    state.genesForThisPanel = genes
  },
  SET_PANEL(state, panel) {
    state.dirty = true
    state.panel = panel
  },
  SET_PANELS(state, panels) {
    state.dirty = true
    state.panels = panels
  },
  SET_PANEL_NAME(state, name) {
    state.dirty = true
    state.panel.name = name
  },
  SET_GENE_ID(state, id) {
    ;(state.dirty = true), (state.gene.id = id)
  },
  SET_GENE_NAME(state, name) {
    ;(state.dirty = true), (state.gene.name = name)
  },
  SET_GENE_CODE(state, code) {
    ;(state.dirty = true), (state.gene.code = code)
  },
  SET_GENE_ROW_STATUS(state, rs) {
    ;(state.dirty = true), (state.gene.rowStatus = rs)
  },
  SET_PANEL_ROW_STATUS(state, rs) {
    state.dirty = true
    state.panel.rowStatus = rs
  },
  SET_PANEL_ID_FOR_PANEL(state, id) {
    state.dirty = true
    state.panel.id = id
  },
  SET_PANEL_ID(state, panel_id) {
    state.dirty = true
    state.single_panel_gene_configuration.panel_id = panel_id
  },
  SET_PANEL_ID_UMBRELLA(state, panels_id) {
    state.dirty = true
    state.umbrella.panelsId = panels_id
  },
  SET_CATEGORY(state, category) {
    state.dirty = false
    state.single_panel_gene_configuration.category = category
  },
  SET_PANEL_GENE_ID(state, id) {
    state.dirty = true
    state.single_panel_gene_configuration.gene_id = id
  },
  SET_PANEL_GENE_CONFIG_ID(state, id) {
    state.dirty = true
    state.single_panel_gene_configuration.id = id
  },
  SET_GENES(state, genes) {
    state.genes = genes
  },
  SET_PANELS_GENES_CONFIG(state, panel_genes_configuration) {
    state.panel_genes_configuration = panel_genes_configuration
  },
  SET_PANEL_GENE_CONFIG_RS(state, row_status) {
    state.single_panel_gene_configuration.rowStatus = row_status
  },
  DELETE_PANEL_GENE_CONFIG(state, panel_genes_configuration) {
    state.single_panel_gene_configuration = panel_genes_configuration
  },
  SET_UMBRELLAS(state, umbrellas) {
    state.umbrellas = umbrellas
  },
  SET_UMBRELLA(state, umbrella) {
    state.umbrella = umbrella
  },
  SET_UMBRELLA_ROW_STATUS(state, rs) {
    state.dirty = true
    state.umbrella.rowStatus = rs
  },
  SET_UMBRELLA_NAME(state, name) {
    state.dirty = true
    state.umbrella.name = name
  },
  SET_UMBRELLA_CODE(state, code) {
    ;(state.dirty = true), (state.umbrella.code = code)
  },
  SET_BIOMARKER_TO_UMBRELLA(state, bmk) {
    state.dirty = true
    const bmkIndex = state.umbrella.biomarkers.findIndex(
      (b) => b.code === bmk.code
    )
    if (bmkIndex >= 0) {
      state.umbrella.biomarkers.splice(bmkIndex, 1)
    } else {
      state.umbrella.biomarkers = [...state.umbrella.biomarkers, bmk]
    }
  },
  SAVE_UMBRELLA_PHARMA_CONFIG(state, bmkConfig) {
    state.dirty = true
    state.umbrella.biomarkers[bmkConfig.index] = bmkConfig
  },
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  actions,
  mutations,
}
