<template>
  <form @submit="handleSave">
    <management-header :title="`${isNew ? 'Nuevo' : 'Editar'} farma`">
      <button
        type="submit"
        :disabled="!isValid || !isDirty"
        class="btn btn-pill btn-accent"
      >
        {{ isNew ? 'Crear' : 'Actualizar' }}
      </button>
      <button
        v-if="!isNew"
        type="button"
        :disabled="!isValid"
        class="btn btn-pill btn-secondary"
        @click="handleToggleStatus"
      >
        {{ isEnabled ? 'Deshabilitar' : 'Habilitar' }}
      </button>
      <button
        type="button"
        class="btn btn-pill btn-secondary"
        @click="handleDiscard"
      >
        {{ isDirty ? 'Descartar' : 'Volver' }}
      </button>
    </management-header>

    <d-card class="p-4">
      <d-row class="pb-4">
        <d-col>
          <v-input
            v-model="pharmaName"
            :error="errors.name"
            name="name"
            placeholder="Nombre de la farma"
          />
        </d-col>
        <d-col>
          <v-input
            v-model="pharmaCode"
            :error="errors.code"
            name="code"
            placeholder="Código de la farma"
          />
        </d-col>
      </d-row>
      <d-row class="pb-4">
        <d-col>
          <v-input
            v-model="pharmaRequirements"
            name="requirements"
            placeholder="Requerimientos"
          />
        </d-col>
      </d-row>
      <d-form-checkbox v-model="pharmaRequiresSignedConsent" class="pb-4">
        <span>Requiere consentimiento informado</span>
      </d-form-checkbox>
      <d-form-checkbox v-model="pharmaRequiresPharmaEnvelopeCode" class="pb-4">
        <span>Requiere un sobre propio</span>
      </d-form-checkbox>
      <d-form-checkbox v-model="pharmaValidateEnvelopeWithServer" class="pb-4">
        <span>Validar el sobre con el servidor de PHP</span>
      </d-form-checkbox>
      <d-form-checkbox v-model="pharmaValidateEnvelopeWithNewApi" class="pb-4">
        <span
          >Validar el sobre con el nuevo sistema de validación</span
        > </d-form-checkbox
      ><d-form-checkbox v-model="pharmaAdmitsEnvelopeAbsense" class="pb-4">
        <span
          >Admite Ausencia de codigo (no tengo codigo de sobre)</span
        > </d-form-checkbox
      ><d-form-checkbox v-model="pharmaRequiresQrOption" class="pb-4">
        <span>Requiere codigo QR</span>
      </d-form-checkbox>
    </d-card>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import VInput from '@/components/Form/VInput.vue'
import ManagementHeader from '@/components/ManagementHeader.vue'

export default {
  components: {
    VInput,
    ManagementHeader,
  },
  props: {
    pharmaId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters('pharmas', [
      'isNew',
      'isValid',
      'isDirty',
      'hasErrors',
      'errors',
    ]),
    isEnabled() {
      return this.$store.state.pharmas.pharma.rowStatus === 'E'
    },
    pharmaName: {
      get() {
        return this.$store.state.pharmas.pharma.name
      },
      set(name) {
        this.setPharmaName(name)
      },
    },
    pharmaCode: {
      get() {
        return this.$store.state.pharmas.pharma.code
      },
      set(code) {
        this.setPharmaCode(code)
      },
    },
    pharmaRequirements: {
      get() {
        return this.$store.state.pharmas.pharma.requirements
      },
      set(requirements) {
        this.setPharmaRequirements(requirements)
      },
    },
    pharmaRequiresSignedConsent: {
      get() {
        return this.$store.state.pharmas.pharma.requiresSignedConsent
      },
      set(val) {
        this.setPharmaRequiresSignedConsent(val)
      },
    },
    pharmaRequiresPharmaEnvelopeCode: {
      get() {
        return this.$store.state.pharmas.pharma.requiresPharmaEnvelopeCode
      },
      set(val) {
        this.setPharmaRequiresPharmaEnvelopeCode(val)
      },
    },
    pharmaValidateEnvelopeWithServer: {
      get() {
        return this.$store.state.pharmas.pharma.validateEnvelopeWithServer
      },
      set(val) {
        this.setPharmaValidateEnvelopeWithServer(val)
      },
    },
    pharmaValidateEnvelopeWithNewApi: {
      get() {
        return this.$store.state.pharmas.pharma.validateEnvelopeWithNewApi
      },
      set(val) {
        this.setPharmaValidateEnvelopeWithNewApi(val)
      },
    },
    pharmaAdmitsEnvelopeAbsense: {
      get() {
        return this.$store.state.pharmas.pharma.admitsEnvelopeAbscense
      },
      set(val) {
        this.setPharmaAdmitsEnvelopeAbsense(val)
      },
    },
    pharmaRequiresQrOption: {
      get() {
        return this.$store.state.pharmas.pharma.requiresQrOption
      },
      set(val) {
        this.setPharmaRequiresQrOption(val)
      },
    },
  },
  async mounted() {
    await this.getPharmas()
    await this.setPharma(this.pharmaId)
  },
  methods: {
    ...mapActions('pharmas', [
      'getPharmas',
      'savePharma',
      'setPharma',
      'setPharmaName',
      'setPharmaCode',
      'setPharmaRequirements',
      'setPharmaRequiresSignedConsent',
      'setPharmaValidateEnvelopeWithServer',
      'setPharmaRequiresPharmaEnvelopeCode',
      'setPharmaValidateEnvelopeWithNewApi',
      'setPharmaAdmitsEnvelopeAbsense',
      'setPharmaRequiresQrOption',
      'setPharmaRowStatus',
    ]),
    async handleSave(e) {
      if (e) e.preventDefault()
      await this.savePharma()
      if (!this.hasErrors)
        this.$router.push({ name: this.NAMED_ROUTES.PHARMAS })
    },
    handleDiscard() {
      this.$router.push({ name: this.NAMED_ROUTES.PHARMAS })
    },
    handleToggleStatus() {
      const action = this.isEnabled ? 'deshabilitar' : 'habilitar'
      const result = window.confirm(
        `Al ${action} ${this.pharmaName} se ${action}án las configuraciones de biomarcadores para países que estén asociadas.`
      )
      if (!result) return
      const newRowStatus = this.isEnabled ? 'D' : 'E'
      this.setPharmaRowStatus(newRowStatus)
      this.handleSave()
    },
  },
}
</script>
