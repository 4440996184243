<template>
  <div>
    <div class="form-group">
      <label v-if="label"> {{ label }} </label>
      <d-input
        v-model="password.pass"
        type="password"
        required
        placeholder="Contraseña"
        @input="validatePassword"
        @change="validateRepPassword"
      />
      <div class="requisitos mt-2 d-flex flex-wrap justify-content-center">
        <span :class="{ color: color.min_len }" class="req"
          >Mínimo 8 caracteres</span
        >
        <span :class="{ color: color.mayus }" class="req">Una mayúscula</span>
        <span :class="{ color: color.especial_char }" class="req"
          >Un número o caracter especial</span
        >
        <span
          v-show="changePassword"
          :class="{ color: color.lastThree }"
          class="req"
          >Distinta a las últimas 3</span
        >
      </div>
    </div>

    <div class="form-group">
      <label v-if="label2"> {{ label2 }} </label>
      <d-input
        v-model="password_repeat.repeat"
        type="password"
        required
        placeholder="Repita su contraseña"
        @input="validatePassword"
        @change="validateRepPassword"
      />
      <d-form-invalid-feedback v-if="!password_repeat.state">{{
        feedback.password_repeat
      }}</d-form-invalid-feedback>
    </div>
  </div>
</template>

<script>
import owasp from 'owasp-password-strength-test'
import PlutonBaseMixin from '@/components/mixins/PlutonBaseMixin'
import axios from 'axios'

export default {
  name: 'PasswordEntry',
  mixins: [PlutonBaseMixin],
  props: {
    label: {
      type: String,
    },
    label2: {
      type: String,
    },
    change: {
      type: Boolean,
    },
    param_busq: {
      type: Object,
    },
  },
  data() {
    return {
      password: {
        pass: '',
        state: false,
      },
      password_repeat: {
        repeat: '',
        state: false,
      },
      color: {
        mayus: false,
        especial_char: false,
        min_len: false,
        lastThree: false,
      },
      feedback: {
        password_repeat: 'La contraseña tiene que ser idéntica a la anterior',
      },
      changePassword: this.change,
      hookActivated: false,
    }
  },

  watch: {
    'password.pass': function () {
      this.validateLengthPassword()
      this.validateCharPassword()
      this.validateMayus()
      if (
        (this.changePassword &&
          this.color.mayus &&
          this.color.min_len &&
          this.color.especial_char) ||
        this.color.lastThree
      ) {
        setTimeout(this.validateLastThree, 200)
      }
      this.validateRepPassword()
    },
    'password_repeat.repeat': function () {
      this.validateRepPassword()
    },
    'password_repeat.state'(newState) {
      this.password_repeat.state = newState
      this.handleValidation()
    },
    'color.lastThree'(newColor) {
      this.color.lastThree = newColor
      this.handleValidation()
    },
  },
  methods: {
    handleValidation() {
      let typeOfValidation =
        this.change === true
          ? this.color.mayus &&
            this.color.min_len &&
            this.color.especial_char &&
            this.color.lastThree
          : this.color.mayus && this.color.min_len && this.color.especial_char
      if (typeOfValidation) {
        this.password.state = this.password_repeat.state
      } else {
        this.password.state = false
      }
      this.$emit('password_entered', this.password)
    },
    validatePassword() {
      owasp.config({
        minLength: 8,
        minOptionalTestsToPass: 2,
      })

      const isPasswordValid =
        this.controlValidations() &&
        this.password_repeat.state &&
        owasp.test(this.password.pass).strong
      if (isPasswordValid) {
        this.password.state = true
        this.$emit('password_entered', this.password)
      } else {
        this.password.state = false
        this.$emit('password_entered', this.password)
      }
      this.handleValidation()
    },

    controlValidations() {
      let typeOfValidation =
        this.change === true
          ? this.color.mayus &&
            this.color.min_len &&
            this.color.especial_char &&
            this.color.lastThree
          : this.color.mayus && this.color.min_len && this.color.especial_char
      return typeOfValidation
    },

    validateLengthPassword() {
      if (this.password.pass.length >= 8) this.color.min_len = true
      else this.color.min_len = false
    },

    validateCharPassword() {
      var chars_esp = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~123456789]/

      if (
        chars_esp.test(this.password.pass) &&
        this.password.pass.indexOf(' ') < 0
      )
        this.color.especial_char = true
      else this.color.especial_char = false
    },

    validateMayus() {
      var mayus = /[ABCDEFGHIJKLMNOPQRSTUWXYZ]/

      if (mayus.test(this.password.pass)) this.color.mayus = true
      else this.color.mayus = false
    },

    validateLastThree() {
      axios
        .get(
          `${this.param_busq.ruta}?reset_password_token=${this.param_busq.token}&password=${this.password.pass}&user_id=${this.param_busq.user_id}`
        )
        .then((response) => {
          if (!response.data) this.color.lastThree = false
          else this.color.lastThree = true
        })
        .catch(this.catchAjaxError)
    },

    validateRepPassword() {
      if (
        this.controlValidations &&
        this.password.pass === this.password_repeat.repeat &&
        !this.password.pass.startsWith(' ') &&
        !this.password_repeat.repeat.startsWith(' ')
      )
        this.password_repeat.state = true
      else this.password_repeat.state = false
      this.$emit('password_entered', this.password)
    },
  },
}
</script>
