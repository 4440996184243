<template>
  <div class="root">
    <span v-for="(crumb, idx) in crumbs" :key="crumb.name" class="crumb">
      <router-link
        v-if="crumb.route && !isLastCrumb(idx)"
        :to="{ name: crumb.route }"
        >{{ crumb.name }}</router-link
      >
      <div
        v-else
        :class="{ last: isLastCrumb(idx), clickable: !!crumb.onClick }"
        v-on="'onClick' in crumb ? { click: crumb.onClick } : {}"
      >
        {{ crumb.name }}
      </div>
      <i v-if="!isLastCrumb(idx)" class="material-icons-outlined">
        chevron_right
      </i>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    crumbs: {
      type: Array,
      required: true,
    },
  },
  methods: {
    isLastCrumb(idx) {
      return idx === this.crumbs.length - 1
    },
  },
}
</script>

<style lang="scss" scoped>
.root {
  border-radius: var(--radius);
  border: 1px solid var(--dim-10);
  font-size: var(--font-lg);
  font-weight: 500;
  color: var(--grey-2);
  margin: 25px 0;
  display: flex;
  align-items: center;

  & .crumb {
    cursor: default;
    display: flex;
    align-items: center;
    padding: 8px 0 8px 12px;

    > * {
      transition: 0.2s;
      color: var(--grey-2);
    }

    .material-icons-outlined {
      padding-left: 12px;
    }

    .last {
      color: var(--grey-1);
    }

    .clickable {
      cursor: pointer;
    }
  }
}
</style>
