<template>
  <footer class="p-2 pb-4">
    <div class="text-center pb-6">
      <small><strong>Biomakers Plutón</strong></small>
    </div>
    <div class="text-center pb-6">
      <small>Por dudas o consultas <a
        href="mailto:laboratorio@biomakers.net"
        class="text-dark"
      >contáctenos via email</a> o llame al 0800-345-1775</small>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'MainFooter',
};
</script>
