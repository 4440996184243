const getDefaultState = () => ({
  zoom: false,
})

const getters = {
  zoom: (state) => state.zoom,
}

const mutations = {
  toogleZoom(state) {
    state.zoom = !state.zoom
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  mutations,
}
