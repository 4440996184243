<template>
  <label>
    <slot /><span
      v-if="required"
      class="text-danger"
    > *</span>
  </label>
</template>
<script>
    export default {
        name: 'FormLabel',
        props: {
          required: {
            type: Boolean,
            default: false,
          },
        }
    }
</script>
