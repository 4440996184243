import axios from 'axios'

export const getDefaultState = () => ({
  studyLabels: {},
  patientLabels: {},
  studyTestLabels: {},
  preScreeningLabels: {},
})

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateStudyLabels(state, newLabels) {
      state.studyLabels = { ...newLabels }
    },
    updatePatientLabels(state, newLabels) {
      state.patientLabels = { ...newLabels }
    },
    updateStudyTestLabels(state, newLabels) {
      state.studyTestLabels = { ...newLabels }
    },
    updatePreScreeningLabels(state, newLabels) {
      state.preScreeningLabels = { ...newLabels }
    },
  },
  actions: {
    async fetchStudyLabels({ state, commit }) {
      if (Object.entries(state.studyLabels).length) return
      const newLabels = (await axios.get(`/api2/study/labels`)).data
      commit('updateStudyLabels', newLabels)
    },
    async fetchPatientLabels({ state, commit }) {
      if (Object.entries(state.patientLabels).length) return
      const newLabels = (await axios.get(`/api2/patient/labels`)).data
      commit('updatePatientLabels', newLabels)
    },
    async fetchStudyTestLabels(
      { commit },
      queryStrings = { filters: {}, for_stats: false }
    ) {
      if ('studyTest' in queryStrings.filters) {
        delete queryStrings.filters.studyTest.biomarkers
        delete queryStrings.filters.studyTest.result
      }
      let parsedQueryStrings = ''
      Object.keys(queryStrings).forEach(
        (key) =>
          (parsedQueryStrings = `${parsedQueryStrings}${key}=${encodeURIComponent(
            JSON.stringify(queryStrings[key])
          )}&`)
      )
      const newLabels = (
        await axios.get(`/api2/study_test/labels?${parsedQueryStrings}`)
      ).data
      commit('updateStudyTestLabels', newLabels)
    },
    async getPreScreeningLabels({ commit }) {
      const newLabels = (await axios.get('/api2/pscprgm/')).data
      commit('updatePreScreeningLabels', newLabels)
    },
  },
}
