<template>
  <div class="d-flex justify-content-center align-items-center container">
    <div class="auth-form">
      <d-card class="mb-4">
        <d-card-body border-theme="warning">
          <!-- Logo -->
          <div class="text-center mb-3">
            <img
              src="../assets/images/logos/pluton-2x-color.png"
              alt="Plutón"
            />
          </div>

          <!-- Title -->
          <h5 class="auth-form__title text-center mb-4">
            Establecer nueva contraseña
          </h5>

          <!-- Form Fields -->
          <d-form @submit="handleSubmit">
            <d-alert
              v-if="['error', 'ok'].includes(status)"
              :theme="status === 'error' ? 'danger' : 'success'"
              dismissible
              show
            >
              {{ message }}
            </d-alert>

            <password-entry
              v-model="password"
              label="Nueva Contraseña"
              label2="Repita la nueva contraseña"
              change
              :param_busq="busq"
              @password_entered="get_password"
              @update_password="get_password"
            />

            <div class="text-center">
              <d-button
                class="btn btn-accent"
                pill
                type="submit"
                :disabled="change_btn"
              >
                {{ status === 'loading' ? 'Cargando...' : 'Guardar' }}
              </d-button>

              <small class="mt-3 d-block">
                <router-link
                  :to="{ name: NAMED_ROUTES.LOGIN }"
                  class="text-dark"
                >
                  Ingresar al sistema
                </router-link>
              </small>
            </div>
          </d-form>
        </d-card-body>
      </d-card>
    </div>
  </div>
</template>

<script>
import PlutonBaseMixin from '@/components/mixins/PlutonBaseMixin'
import PasswordEntry from '../components/Form/PasswordEntry.vue'

const VIEW_STATUS = Object.freeze({
  START: 'start',
  LOADING: 'loading',
  ERROR: 'error',
  OK: 'ok',
})

const OK_MESSAGE = 'Su contraseña fue reestablecida exitosamente.'

export default {
  name: 'ResetPassword',
  components: { PasswordEntry },
  mixins: [PlutonBaseMixin],
  data() {
    return {
      password: '',
      pass_stat: false,
      status: VIEW_STATUS.START,
      token: '',
      message: OK_MESSAGE,
      change_btn: true,
      hookExecuted: false,
      busq: {
        ruta: '/api2/credentials/last_three_credentials',
        token: '',
      },
      months: 3,
    }
  },
  // updated(){
  //   if (!this.hookExecuted){

  //   }
  // },
  computed: {
    isSubmissionDisabled() {
      return (
        this.status === 'loading' ||
        !this.pass_stat ||
        this.$route.query.token == undefined
      )
    },
    // change_btn() {
    //   return !this.pass_stat
    // },
  },
  watch: {
    pass_stat: {
      deep: true,
      handler(val) {
        this.change_btn = !val
      },
    },
  },
  async mounted() {
    this.token = this.$route.query.token
    if (this.token == undefined) {
      this.status = VIEW_STATUS.ERROR
      this.message =
        'Este enlace es inválido: no posee un token de recuperación de contraseña'
    }

    this.busq.token = String(this.token)
  },
  methods: {
    get_password(val) {
      this.password = val.pass
      this.pass_stat = val.state
    },

    async handleSubmit(e) {
      e.preventDefault()

      this.status = VIEW_STATUS.LOADING
      const response = await fetch('/api2/auth/reset_password', {
        method: 'POST',
        body: JSON.stringify({
          token: this.token,
          password: this.password,
          time: this.months,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (response.status === 200) {
        this.status = VIEW_STATUS.OK
        this.message = OK_MESSAGE
        setTimeout(() => {
          this.$router.push({ name: this.NAMED_ROUTES.LOGIN })
        }, 5000)
      } else {
        this.status = VIEW_STATUS.ERROR
        this.message =
          (await response.json()).detail[0].msg ||
          'Hubo un problema reestableciendo su contraseña.'
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
