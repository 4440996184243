<template>
  <div
    :id="id"
    ref="container"
    class="donut"
    :style="`
      --holeSize: calc(${currentInnerDiameter}px - 0.5rem);
      --fullWidth: ${fullWidth}px;
    `"
  >
    <svg
      preserveAspectRatio="xMidYMid meet"
      :viewBox="`0 0 ${fullWidth} ${height}`"
    >
      <g :transform="`translate(${fullWidth / 2}, ${height / 2})`" />
    </svg>
    <div class="hole" :class="{ visible: isHoleVisible, customBorder: border }">
      <slot />
    </div>
  </div>
</template>
<script>
import * as d3 from 'd3'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
    height: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      default: null,
    },
    margin: {
      type: Object,
      default: () => ({ top: 0, left: 0, right: 0, bottom: 0 }),
    },
    colors: {
      type: Array,
      default: () => [],
    },
    thickness: {
      type: Number,
      default: 24,
    },
    animationDuration: {
      type: Number,
      default: 1000,
    },
    border: {
      type: Boolean,
      default: false,
    },
    labelMaxWidth: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      fullWidth: 0,
      currentWidth: 0,
      currentHeight: 0,
      isHoleVisible: false,
      holeTimeout: null,
    }
  },
  computed: {
    horizontalMargins() {
      return this.margin.left + this.margin.right
    },
    verticalMargins() {
      return this.margin.top + this.margin.bottom
    },
    radius() {
      return (
        Math.min(
          this.fullWidth - this.horizontalMargins,
          this.height - this.verticalMargins
        ) / 2
      )
    },
    generator() {
      return d3
        .pie()
        .value((d) => d.amount)
        .sort(null)
    },
    innerRadius() {
      return this.radius - this.thickness
    },
    innerDiameter() {
      return this.innerRadius * 2
    },
    currentRadius() {
      return (
        Math.min(
          this.currentWidth - this.horizontalMargins,
          this.currentHeight - this.verticalMargins
        ) / 2
      )
    },
    currentInnerRadius() {
      return this.currentRadius - this.thickness
    },
    currentInnerDiameter() {
      return this.currentInnerRadius * 2
    },
    svg() {
      return d3.select(`#${this.id} svg g`)
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.plotGraph()
      },
    },
    colors: {
      deep: true,
      handler() {
        this.updateColors()
      },
    },
  },
  mounted() {
    this.fullWidth = this.width || this.$refs.container.clientWidth
    window.addEventListener('resize', this.handleResize)
    this.plotGraph()
    this.holeTimeout = setTimeout(this.handleResize, this.animationDuration)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      if (this.holeTimeout) clearTimeout(this.holeTimeout)
      this.currentWidth = this.$refs.container.clientWidth
      this.currentHeight = this.$refs.container.clientHeight
    },
    wrap(text, width) {
      if (!text) return
      text.each(function () {
        const text = d3.select(this)
        const phrases = text.text().split(/\n/)
        text.text(null)
        phrases.forEach((phrase, idx) => {
          let words = phrase.split(/\s+/).reverse(),
            word,
            line = []
          const lineHeight = 1.1, // ems
            y = text.attr('y'),
            dy = parseFloat(text.attr('dy') || (idx !== 0 && lineHeight) || 0)
          let tspan = text
            .append('tspan')
            .attr('x', 0)
            .attr('y', y)
            .attr('dy', dy + 'em')
          while ((word = words.pop())) {
            line.push(word)
            tspan.text(line.join(' '))
            if (tspan.node().getComputedTextLength() > width) {
              line.pop()
              tspan.text(line.join(' '))
              line = [word]
              tspan = text
                .append('tspan')
                .attr('x', 0)
                .attr('y', y)
                .attr('dy', lineHeight + dy + 'em')
                .text(word)
            }
          }
        })
      })
    },
    getDatumSineAndCosineWithPadding(datum, arc) {
      const pos = arc.centroid(datum)
      // Compute the hypotenuse with a padding (.9)
      const h = Math.sqrt(pos[0] ** 2 + pos[1] ** 2) * 0.9
      if (h === 0)
        return {
          sine: 0,
          cosine: 0,
        }
      return {
        sine: pos[1] / h,
        cosine: pos[0] / h,
      }
    },
    plotGraph() {
      this.isHoleVisible = false
      setTimeout(() => (this.isHoleVisible = true), this.animationDuration)

      // generate interpolations for animations
      const angleInterpolation = d3.interpolate(
        this.generator.startAngle()(),
        this.generator.endAngle()()
      )
      const outerRadiusInterpolation = d3.interpolate(0, this.radius)
      const innerRadiusInterpolation = d3.interpolate(0, this.innerRadius)

      // Generate slices
      const color = d3.scaleOrdinal().range(this.colors)
      const arc = d3.arc()
      this.svg.selectAll('path').remove()
      const chart = this.svg
        .selectAll('path')
        .data(this.generator(this.data))
        .join('path')
        .attr('class', 'slice')
        .attr('fill', (d) => color(d.data.label))

      // animate graph
      chart
        .transition()
        .duration(this.animationDuration)
        .attrTween('d', (d) => {
          let originalEnd = d.endAngle
          return (t) => {
            let currentAngle = angleInterpolation(t)
            if (currentAngle < d.startAngle) return ''
            d.endAngle = Math.min(currentAngle, originalEnd)
            return arc(d)
          }
        })
      this.svg
        .transition()
        .duration(this.animationDuration)
        .tween('arcRadii', () => {
          return (t) =>
            arc
              .innerRadius(innerRadiusInterpolation(t))
              .outerRadius(outerRadiusInterpolation(t))
        })

      // generate arc for labels
      const labelArc = d3
        .arc()
        .innerRadius(this.innerRadius)
        .outerRadius(this.radius)

      // Add the labels
      this.svg
        .selectAll('text')
        .data(this.generator(this.data))
        .join('text')
        .text((d) => d.data.label)
        .call(this.wrap, this.labelMaxWidth)
        .attr('transform', (d) => {
          const { sine, cosine } = this.getDatumSineAndCosineWithPadding(
            d,
            labelArc
          )
          return `translate(${cosine * this.radius}, ${sine * this.radius})`
        })
        .attr('class', 'labels')
        .attr('y', (d, i) => {
          const element = d3.selectAll(`#${this.id} .labels`).nodes()[i]
          const { sine } = this.getDatumSineAndCosineWithPadding(d, labelArc)
          return (sine - 1) * element.clientHeight * 0.2
        })
        .style('text-anchor', (d) => {
          const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
          return midangle < Math.PI
            ? 'start'
            : midangle == Math.PI
            ? 'middle'
            : 'end'
        })
    },
    updateColors() {
      const color = d3.scaleOrdinal().range(this.colors)

      this.svg
        .selectAll('path')
        .data(this.generator(this.data))
        .join('path')
        .attr('fill', (d) => color(d.data.label))
    },
  },
}
</script>

<style lang="scss">
.donut {
  position: relative;
  width: var(--fullWidth);

  & .hole {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: calc(var(--holeSize) / -2);
    margin-left: calc(var(--holeSize) / -2);
    width: var(--holeSize);
    height: var(--holeSize);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: 0.5s;
    overflow: hidden;
    border-radius: 50%;

    &.visible {
      opacity: 1;
    }

    &.customBorder {
      border: 8px solid var(--pink);
    }
  }
}
</style>
