<template v-if="show === true">
  <d-dropdown-item
    class="custom-card-body justify-content-center block"
    :to="notification.study ? `/study/${notification.study.id}/view` : null"
  >
    <h6 class="text-center">{{ notification.title }}</h6>

    <p
      v-for="val in formatNotificationContent(notification.content)"
      :key="val.id"
      class="text-center custom-text-center-notif"
    >
      {{ val }}
    </p>
  </d-dropdown-item>
</template>

<script>
export default {
  name: 'Notification',
  props: {
    notification: { type: Object, required: true, default: () => {} },
    index: { type: Number, required: true },
    timeUntilDismissed: { type: Number, required: false, default: null },
    // show: { type: Boolean, default: true },
  },
  data() {
    return {
      show: true,
      step: 250,
    }
  },
  computed: {},
  watch: {
    timeUntilDismissed(newTime) {
      if (newTime === 0) {
        this.hideNotification()
      }
    },
  },
  mounted() {
    // this.dismissThisNotification()
    // this.handleTimeChange(this.timeUntilDismissed, this.step)
  },
  methods: {
    async handleTimeChange(time, step) {
      for (let index = 0; index < time / step; index++) {
        await new Promise((resolve) => {
          setTimeout(() => {
            //eslint-disable-next-line vue/no-mutating-props
            this.timeUntilDismissed -= step
            resolve()
          }, step)
        })
      }
    },
    dismissThisNotification() {
      setTimeout(() => {
        this.hideNotification()
      }, this.timeUntilDismissed)
    },
    hideNotification() {
      this.show = false
      this.$emit('hide')
      return
    },
    formatNotificationContent(content) {
      let numbersOfSplices = content.length / 35 //cool numbers of characters :)
      var firstSlice = content.slice(0, 35)
      let array = []
      array.push(firstSlice)
      if (numbersOfSplices > 1 && numbersOfSplices > 2) {
        var secondSlice = content.slice(35, 71)
        array.push(secondSlice)
      } else if (numbersOfSplices > 2 && numbersOfSplices < 3) {
        var thirdSlice = content.slice(71, content.length)
        array.push(thirdSlice)
      }
      return array
    },
  },
}
</script>

<style lang="scss" scoped>
.notification {
  height: 20rem;
  width: 20rem;
}
.custom-card-body {
  border-top: 1px solid lightgray; /* Agrega un borde gris */
  border-bottom: 1px solid lightgray; /* Agrega un borde gris */
}
.custom-text-center-notif {
  margin-bottom: 0.5rem;
}
</style>
