<template>
  <form @submit="handleSave">
    <management-header :title="`${isNew ? 'Nuevo' : 'Editar'} biomarcador`">
      <button
        type="submit"
        :disabled="!isValid || !isDirty"
        class="btn btn-pill btn-accent"
      >
        {{ isNew ? 'Crear' : 'Actualizar' }}
      </button>
      <button
        v-if="!isNew"
        type="button"
        :disabled="!isValid"
        class="btn btn-pill btn-secondary"
        @click="handleToggleStatus"
      >
        {{ isEnabled ? 'Deshabilitar' : 'Habilitar' }}
      </button>
      <button
        type="button"
        class="btn btn-pill btn-secondary"
        @click="handleDiscard"
      >
        {{ isDirty ? 'Descartar' : 'Volver' }}
      </button>
    </management-header>

    <d-card class="mb-4">
      <d-card-body>
        <v-input
          v-model="biomarkerName"
          :error="errors.name"
          name="name"
          placeholder="Nombre del biomarcador"
        />
        <v-input
          v-model="biomarkerCode"
          :error="errors.code"
          name="code"
          placeholder="Código del biomarcador"
          help="Código identificador en CSVs y Dirmod"
        />
      </d-card-body>
    </d-card>
    <d-card class="mb-4">
      <d-card-body>
        <h5>Tumores primarios para los que está habilitado</h5>
        <div class="primary-tumors">
          <v-checkbox
            v-for="tumor in primaryTumors"
            :id="`tumor-${tumor.id}`"
            :key="tumor.id"
            v-model="biomarkerPrimaryTumors"
            :value="tumor"
            :name="`tumor-${tumor.code}`"
          >
            {{ tumor.name }}
          </v-checkbox>
        </div>
      </d-card-body>
    </d-card>

    <d-card class="mb-4 bibliography">
      <d-card-body>
        <h5>Literatura asociada</h5>
        <div class="header">
          <v-input
            v-model="bibliographyIdentifiers"
            name="new_bibliography_identifiers"
            placeholder="Identificador único"
            help="Por ej el ISSN, DOI, etc"
          />
          <v-input
            v-model="bibliographyAuthors"
            name="new_bibliography_authors"
            placeholder="Autores de la bibliografía"
          />
          <v-input
            v-model="bibliographyTitle"
            name="new_bibliography_title"
            placeholder="Titulo"
          />
          <v-input
            v-model="bibliographyUrl"
            name="new_bibliography_url"
            placeholder="Url"
          />
          <button
            type="button"
            class="btn btn-secondary"
            :disabled="!(bibliography.identifiers && bibliography.url)"
            @click="addBibliography"
          >
            Agregar bibliografía
          </button>
        </div>
        <hr />
        <template v-for="(bibliography, idx) in biomarkerBibliographies">
          <div :key="idx" class="col">
            <v-input
              :value="bibliography.identifiers"
              :name="`${idx}_new_bibliography_identifiers`"
              placeholder="Identificador único"
              @input="
                setBiomarkerBibliography({
                  value: $event,
                  index: idx,
                  key: 'identifiers',
                })
              "
            />
            <v-input
              :value="bibliography.authors"
              :name="`${idx}_new_bibliography_authors`"
              placeholder="Autores de la bibliografía"
              @input="
                setBiomarkerBibliography({
                  value: $event,
                  index: idx,
                  key: 'authors',
                })
              "
            />
            <v-input
              :value="bibliography.title"
              :name="`${idx}_new_bibliography_title`"
              placeholder="Titulo"
              @input="
                setBiomarkerBibliography({
                  value: $event,
                  index: idx,
                  key: 'title',
                })
              "
            />
            <v-input
              :value="bibliography.url"
              :name="`${idx}_new_bibliography_url`"
              placeholder="Url"
              @input="
                setBiomarkerBibliography({
                  value: $event,
                  index: idx,
                  key: 'url',
                })
              "
            />
            <v-checkbox
              value="E"
              :name="`${idx}_bibliography_row_status`"
              :model="[bibliography.rowStatus]"
              @change="
                setBiomarkerBibliography({
                  value: $event === bibliography.rowStatus ? 'D' : 'E',
                  index: idx,
                  key: 'rowStatus',
                })
              "
            >
              {{
                bibliography.rowStatus === 'E' ? 'Habilitado' : 'Deshabilitado'
              }}
            </v-checkbox>
          </div>
          <hr :key="`${idx}-hr`" />
        </template>
      </d-card-body>
    </d-card>
  </form>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

import VInput from '@/components/Form/VInput.vue'
import VCheckbox from '@/components/Form/VCheckbox.vue'
// import VColorPicker from '@/components/Form/VColorPicker.vue'
import ManagementHeader from '@/components/ManagementHeader.vue'

export default {
  components: {
    VInput,
    VCheckbox,
    ManagementHeader,
  },
  props: {
    biomarkerId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      colorOptions: [
        { label: 'positivo', color: '#FF83A5' },
        { label: 'negativo', color: '#2AD8A0' },
        { label: 'estudio no completo', color: '#849DFF' },
        { label: 'muestra insuficiente', color: '#999999' },
      ],
    }
  },
  computed: {
    ...mapGetters('biomarkers', [
      'isNew',
      'isValid',
      'isDirty',
      'hasErrors',
      'errors',
      'result',
      'bibliography',
      'biomarkerResults',
      'biomarkerBibliographies',
    ]),
    ...mapGetters('primaryTumors', ['primaryTumors']),
    ...mapState('labelMappings', ['studyTestLabels']),
    isEnabled() {
      return this.$store.state.biomarkers.biomarker.rowStatus === 'E'
    },
    biomarkerName: {
      get() {
        return this.$store.state.biomarkers.biomarker.name
      },
      set(name) {
        this.setBiomarkerName(name)
      },
    },
    biomarkerCode: {
      get() {
        return this.$store.state.biomarkers.biomarker.code
      },
      set(code) {
        this.setBiomarkerCode(code)
      },
    },
    biomarkerPrimaryTumors: {
      get() {
        return this.$store.state.biomarkers.biomarker.primaryTumors
      },
      set(primaryTumor) {
        this.setBiomarkerPrimaryTumor(primaryTumor)
      },
    },
    resultName: {
      get() {
        return this.$store.state.biomarkers.result.name
      },
      set(name) {
        this.setResultName(name)
      },
    },
    resultCode: {
      get() {
        return this.$store.state.biomarkers.result.code
      },
      set(code) {
        this.setResultCode(code)
      },
    },
    resultColor: {
      get() {
        return this.$store.state.biomarkers.result.color
      },
      set(color) {
        this.setResultColor(color)
      },
    },
    resultStatisticsGrouper: {
      get() {
        return this.$store.state.biomarkers.result.statisticsGrouper
      },
      set(statisticsGrouper) {
        this.setResultStatisticsGrouper(statisticsGrouper)
      },
    },
    statisticsGrouperOptions() {
      if (!('statisticsGrouper' in this.studyTestLabels)) return []
      return Object.entries(this.studyTestLabels.statisticsGrouper).map(
        ([value, text]) => ({ value, text })
      )
    },
    bibliographyIdentifiers: {
      get() {
        return this.$store.state.biomarkers.bibliography.identifiers
      },
      set(identifiers) {
        this.setBibliographyIdentifiers(identifiers)
      },
    },
    bibliographyAuthors: {
      get() {
        return this.$store.state.biomarkers.bibliography.authors
      },
      set(authors) {
        this.setBibliographyAuthors(authors)
      },
    },
    bibliographyTitle: {
      get() {
        return this.$store.state.biomarkers.bibliography.title
      },
      set(title) {
        this.setBibliographyTitle(title)
      },
    },
    bibliographyUrl: {
      get() {
        return this.$store.state.biomarkers.bibliography.url
      },
      set(url) {
        this.setBibliographyUrl(url)
      },
    },
  },
  async mounted() {
    await this.getBiomarkers()
    await this.getPrimaryTumors()
    await this.setBiomarker(this.biomarkerId)
    await this.fetchStudyTestLabels()
  },
  methods: {
    ...mapActions('primaryTumors', ['getPrimaryTumors']),
    ...mapActions('biomarkers', [
      'getBiomarkers',
      'saveBiomarker',
      'setBiomarker',
      'setBiomarkerName',
      'setBiomarkerCode',
      'setBiomarkerPrimaryTumor',
      'setBiomarkerResult',
      'setResultCode',
      'setResultName',
      'setResultColor',
      'setResultStatisticsGrouper',
      'addResult',
      'setBiomarkerBibliography',
      'setBibliographyIdentifiers',
      'setBibliographyAuthors',
      'setBibliographyTitle',
      'setBibliographyUrl',
      'addBibliography',
      'setBiomarkerRowStatus',
    ]),
    ...mapActions('labelMappings', ['fetchStudyTestLabels']),
    async handleSave(e) {
      if (e) e.preventDefault()
      await this.saveBiomarker()
      if (!this.hasErrors)
        this.$router.push({ name: this.NAMED_ROUTES.BIOMARKERS })
    },
    handleDiscard() {
      this.$router.push({ name: this.NAMED_ROUTES.BIOMARKERS })
    },
    isCodeDisabled(biomarkerPrimaryTumors, result) {
      // these three results are hardcoded in PHP PDF parsing
      // They can't be edited for now, until
      // we have a proper way to define PDF parsing results
      return (
        biomarkerPrimaryTumors &&
        biomarkerPrimaryTumors.map((tumor) => tumor.code).includes('colon') &&
        ['rejected', 'genetic_alterations_detected', 'no_alteration'].includes(
          result.code
        )
      )
    },
    handleToggleStatus() {
      const action = this.isEnabled ? 'deshabilitar' : 'habilitar'
      const result =
        window.confirm(`Al ${action} ${this.biomarkerName} se ${action}án las configuraciones de biomarcadores para países que estén asociadas.

También se ${action}án los resultados asociados a ${this.biomarkerName}.`)
      if (!result) return
      const newRowStatus = this.isEnabled ? 'D' : 'E'
      this.setBiomarkerRowStatus(newRowStatus)
      this.handleSave()
    },
  },
}
</script>
<style lang="scss" scoped>
.primary-tumors {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;
  > * {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

@media screen and (min-width: 768px) {
  .primary-tumors {
    grid-template-columns: repeat(3, 1fr);
    > * {
      justify-content: left;
    }
  }
}

.result .header,
.result .col,
.bibliography .header,
.bibliography .col {
  width: 100%;
  display: inline-grid;
  gap: 16px;
  grid-template-columns: 1fr;
  align-items: center;

  .form-group {
    margin: 0;
  }
}

@media screen and (min-width: 768px) {
  .result .header,
  .result .col,
  .bibliography .header,
  .bibliography .col {
    grid-template-columns: 1fr 1fr;

    > :last-child {
      grid-column: span 2;
    }
  }
}

@media screen and (min-width: 1024px) {
  .result .header,
  .result .col {
    grid-template-columns: 1fr 1fr 25px 1fr 120px;
    > :last-child {
      grid-column: span 1;
    }
  }

  .bibliography .header,
  .bibliography .col {
    grid-template-columns: 1fr 1fr 1fr 0.5fr 130px;
    > :last-child {
      grid-column: span 1;
    }
  }

  .result .col hr {
    display: none;
  }
  .bibliography .col hr {
    display: none;
  }
}

.header button {
  margin-bottom: 1.2rem;
}

select {
  height: 3.2rem !important;
}
</style>
