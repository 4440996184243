import { TUMOR_TYPES } from '@/utils/constants'

export default {
  setPrimaryTumor({ commit, state }, { primaryTumorId, primaryTumorCode }) {
    commit('updateStudyFilters', {
      field: 'primaryTumor',
      value: primaryTumorId,
    })  
    if (
      primaryTumorCode !== TUMOR_TYPES.COLON &&
      state.patient.diagnosisLocation
    )
      commit('updatePatientFilters', {
        field: 'diagnosisLocation',
        value: null,
      })
    if (
      ![
        TUMOR_TYPES.LUNG,
        TUMOR_TYPES.BLADDER,
        TUMOR_TYPES.GASTRIC,
        TUMOR_TYPES.ESOPHAGUS,
        TUMOR_TYPES.GASTROESOPHAGEAL,
      ].includes(primaryTumorCode) &&
      state.patient.smokerStatus
    )
      commit('updatePatientFilters', { field: 'smokerStatus', value: null })
    if (
      ![
        TUMOR_TYPES.LUNG,
        TUMOR_TYPES.GASTRIC,
        TUMOR_TYPES.ESOPHAGUS,
        TUMOR_TYPES.GASTROESOPHAGEAL,
      ].includes(primaryTumorCode)
    )
      state.patient.diagnosis &&
        commit('updatePatientFilters', { field: 'diagnosis', value: null })
  },
}
