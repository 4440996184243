<template>
  <div>
    <hr />
    <div v-if="!isKRASG12C">
      <form-label
        v-if="umbrella || biomarkersTests.length > 1"
        class="mb-2 mt-4"
        required
      >
        Código del sobre para {{ pharma.name }}
      </form-label>
      <form-label v-else>
        Código del sobre de
        {{
          biomarkersTests
            .find((bmk) => bmk.testId === pharma.biomarkerId)
            .testCode.toUpperCase()
        }}
        para {{ pharma.name }}
      </form-label>
    </div>

    <form-label v-else> Código para {{ pharma.name }}</form-label>
    <div v-if="!inputMethod && requiresSelectMethodInput">
      <p class="mb-2">
        Necesitamos acceso a la cámara de su dispositivo para escanear el código
        QR del sobre.<br />
        También puede ingresar el código manualmente.
      </p>
      <d-button-group
        size="large"
        class="w-100"
        aria-label="Seleccione un método para ingresar el código de sobre"
      >
        <d-button
          v-if="pharma.requiresQrOption && !isKRASG12C"
          :class="dontHaveCode ? 'btn-outline-accent' : 'btn-accent'"
          @click="() => (inputMethod = 'qr')"
        >
          Habilitar cámara
        </d-button>
        <d-button
          :class="
            !pharma.requiresQrOption ? 'btn-accent' : 'btn-outline-accent'
          "
          @click="() => (inputMethod = 'manually')"
        >
          Ingresar código manualmente
        </d-button>
        <d-button
          v-if="pharma.admitsEnvelopeAbscense"
          :class="dontHaveCode ? 'btn-accent' : 'btn-outline-accent'"
          :value="null"
          @click="
            () => {
              dontHaveCode = true
              $emit('input', {
                value: null,
                dontHavePharmaEnvelopeCode: true,
                dontHaveEnvelopeCode: true,
                testIds: biomarkersTests
                  .filter((t) => t.pharmaId === pharma.id)
                  .map((t) => t.testId),
                pharmaId: pharma.id,
                isValidCode: true,
                requiresValidationWithNewApi:
                  pharma.requiresValidationWithNewApi,
                validateEnvelopeWithNewApi: pharma.validateEnvelopeWithNewApi,
              })
            }
          "
        >
          No tengo código de sobre
        </d-button>
      </d-button-group>
    </div>

    <div v-if="inputMethod === 'qr'">
      <h5 class="text-center">Por favor enfoque el Código QR con la cámara</h5>
      <qrcode-stream
        v-if="!qrReaderStatus.error"
        ref="qr_reader"
        :paused="qrReaderStatus.paused"
        @init="QRReaderInitialized"
        @decode="decodedQRCode"
      />
      <p v-else>
        Lamentablemente no pudimos escanear el Código QR.<br />
        {{ qrReaderError }}
      </p>
      <center>
        <button
          class="btn btn-outline-accent btn-lg mt-4"
          @click="() => (inputMethod = 'manually')"
        >
          Ingresar código manualmente
        </button>
      </center>
    </div>

    <div v-else-if="inputMethod === 'manually'">
      <div v-if="isKRASG12C" class="form-group">
        <p class="mb-2">
          Por favor ingrese el codigo que inicia con p- seguido de los restantes
          7 digitos
        </p>
        <d-input
          v-model.lazy="envelopeCode"
          name="envelope_code"
          placeholder="Ej: p-ABCDEFG"
          class="uppercase large-form-text"
          :state="isValidCode"
        />
        <d-form-invalid-feedback>
          Codigo Invalido. Comuniquese con su representante de {{ pharma.name }}
        </d-form-invalid-feedback>
      </div>
      <div v-else class="form-group">
        <d-input
          v-model.lazy="envelopeCode"
          name="envelope_code"
          placeholder="Ej: BE42EXT"
          class="uppercase large-form-text"
          :state="isValidCode"
        />
        <d-form-invalid-feedback>
          Codigo Invalido. Comuniquese con su representante de {{ pharma.name }}
        </d-form-invalid-feedback>
      </div>
      <div v-if="pharma.requiresQrOption == true && !isKRASG12C">
        <p>Ingrese el código de 7 caracteres que se encuentra en el sobre.</p>
        <img src="/images/demo/demo-code-highlighted.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
import FormLabel from '@/components/Form/Label'
import { TUMOR_TYPES } from '@/utils/constants'
import { debounce } from 'lodash'

import axios from 'axios'

export default {
  components: {
    QrcodeStream,
    FormLabel,
  },
  props: {
    biomarkersTests: {
      value: {
        type: Array,
        required: false,
        default: [],
      },
    },
    country: { type: Object, default: () => {} },
    umbrella: { type: Object, default: () => null },
    value: {
      type: String,
      required: false,
    },
    pharma: {
      type: Object,
      required: true,
    },
    dontHavePharmaEnvelopeCode: {
      type: Boolean,
      default: false,
    },
    tumor: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      dontHaveCode: false,
      isValidCode: false,
      inputMethod: null,
      envelopeCode: null,
      isKRASG12C: false,
      areRepeatedTests: false,
      bloqueoPeticion: false,
      validationRequest: false,
      qrReaderStatus: {
        error: null,
        paused: false,
      },
    }
  },
  computed: {
    requiresSelectMethodInput() {
      return this.pharma.admitsEnvelopeAbscense || this.pharma.requiresQrOption
    },
  },
  watch: {
    envelopeCode(newCode) {
      if (this.validationRequest) {
        this.validationRequest.cancel()
      }
      // Realizar la solicitud de validación después de 300 ms de inactividad
      this.validationRequest = debounce(() => {
        this.setEnvelopeCode(newCode)
      }, 300)
      this.validationRequest()
    },
    value: {
      handler() {
        if (this.value) {
          this.inputMethod = 'manually'
          this.envelopeCode = this.value
        }
      },
      immediate: true,
    },
    isValidCode: {
      handler(value) {
        if (value) {
          // console.log(
          //   'activo el nuevo watcher del componente pharmaEnvelopeCode',
          //   value
          // )
          this.$emit('input', {
            value: this.envelope_code,
            dontHavePharmaEnvelopeCode: false,
            dontHaveEnvelopeCode: false,
            pharmaId: this.pharma.id,
            testIds: this.biomarkersTests
              .filter((t) => t.pharmaId === this.pharma.id)
              .map((t) => t.testId),
            isValidCode: false,
            requiresValidationWithNewApi:
              this.pharma.requiresValidationWithNewApi,
            validateEnvelopeWithNewApi: this.pharma.validateEnvelopeWithNewApi,
          })
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    if (!this.envelopeCode) {
      // console.log('soy nueva condicion')
      this.isValidCode = false
      this.$emit('input', {
        value: null,
        dontHavePharmaEnvelopeCode: false,
        dontHaveEnvelopeCode: false,
        pharmaId: this.pharma.id,
        testIds: this.biomarkersTests
          .filter((t) => t.pharmaId === this.pharma.id)
          .map((t) => t.testId),
        isValidCode: false,
        requiresValidationWithNewApi: this.pharma.requiresValidationWithNewApi,
        validateEnvelopeWithNewApi: this.pharma.validateEnvelopeWithNewApi,
      })
    }
    await this.setKRASG12C()
    if (this.dontHavePharmaEnvelopeCode) {
      this.dontHaveCode = true
    }
    if (!this.requiresSelectMethodInput || this.isKRASG12C) {
      this.inputMethod = 'manually'
    }
  },
  updated() {
    if (
      this.pharma.requiresValidationWithNewApi &&
      this.isRepeated(this.biomarkersTests)
    ) {
      this.areRepeatedTests = true
    } else {
      this.areRepeatedTests = false
    }
  },
  methods: {
    isRepeated(array) {
      const set = new Set()
      return array.some((element) => {
        if (set.has(element.pharmaId)) {
          return true // Elemento repetido encontrado
        } else {
          set.add(element.pharmaId)
          return false
        }
      })
    },
    async setKRASG12C() {
      if (
        this.biomarkersTests.find((bmk) =>
          bmk.testCode.toUpperCase().startsWith('KRAS_G12C')
        )
      ) {
        this.isKRASG12C = true
        return
      }
      this.isKRASG12C = false
      return
    },
    async setEnvelopeCode(value) {
      // console.log('activo setEnvelopeCode', value)
      this.envelopeCode = value
      await this.validateEnvelopeCode()
      this.$emit('input', {
        value,
        dontHavePharmaEnvelopeCode: false,
        dontHaveEnvelopeCode: false,
        pharmaId: this.pharma.id,
        testIds: this.biomarkersTests
          .filter((t) => t.pharmaId === this.pharma.id)
          .map((t) => t.testId),
        isValidCode: this.isValidCode,
        requiresValidationWithNewApi: this.pharma.requiresValidationWithNewApi,
        validateEnvelopeWithNewApi: this.pharma.validateEnvelopeWithNewApi,
      })
    },

    async validateEnvelopeCode() {
      var actual_val = this.envelopeCode
      actual_val = actual_val.toUpperCase()
      if (!this.envelopeCode) {
        {
          this.isValidCode = false
          return
        }
      }
      if (this.biomarkersTests) {
        if (
          (this.pharma.validateEnvelopeWithNewApi ||
            this.pharma.requiresValidationWithNewApi) &&
          !this.umbrella
        ) {
          // let biomarkerTest = this.biomarkersTests.find(
          //   (bmk) => bmk.testId == this.pharma.biomarkerId
          // )
          const asked_code = (
            await axios.get(
              `/api2/envelopes_codes/validate_envelope_code/${this.envelopeCode}/${this.country.id}/${this.pharma.id}/${this.pharma.biomarkerId}`
            )
          ).data
          this.isValidCode = asked_code
          return
        }
        if (
          (this.pharma.validateEnvelopeWithNewApi ||
            this.pharma.requiresValidationWithNewApi) &&
          this.umbrella
        ) {
          const asked_code = (
            await axios.get(
              `/api2/envelopes_codes/validate_umbrella_envelope_code/${this.envelopeCode}/${this.country.id}/${this.biomarkersTests[0].pharmaId}/${this.umbrella.id}`
            )
          ).data
          this.isValidCode = asked_code
          return
        }
      }
      if (!this.pharma.validateEnvelopeWithServer) {
        this.isValidCode = !!actual_val
        return
      } else if (
        this.pharma.name !== 'GSK' &&
        actual_val.length !== 7 &&
        actual_val.slice(2).length !== 7
      ) {
        this.isValidCode = false
        return
      }

      if (this.pharma.name === 'Amgen') {
        const asked_code = await axios
          .get(`/api2/biomarker/tumor/${this.tumor}`)
          .then((response) => response.data.code.toUpperCase())
          .catch((error) => console.log(error.message))

        if (asked_code === TUMOR_TYPES.LUNG.toUpperCase()) {
          //eslint-disable-next-line no-useless-escape
          var chars_esp_lung = /[ `!@#$%^&*()_+\=[\]{};':"\\|,.<>/?~]/

          if (
            !(actual_val.startsWith('P-') && !chars_esp_lung.test(actual_val))
          ) {
            this.isValidCode = false
            return
          }
          if (
            actual_val.startsWith('P-') &&
            this.bloqueoPeticion == false &&
            actual_val.length == 9
          ) {
            await this.axios
              .get(
                `/api2/study/study/get_study_by_filter?filter_field=envelope_code&value=${actual_val}`
              )
              .then((response) => {
                if (response.data === true) {
                  this.isValidCode = false
                  return
                } else {
                  this.isValidCode = true
                }
              })
          }
        }
        if (asked_code === TUMOR_TYPES.COLON.toUpperCase()) {
          //eslint-disable-next-line no-useless-escape
          var chars_esp_colon = /[ `!@#$%^&*()_+-\=[\]{};':"\\|,.<>/?~]/

          if (
            chars_esp_colon.test(actual_val) &&
            actual_val.length !== 7 &&
            this.bloqueoPeticion == false
          ) {
            this.isValidCode = false
            return
          } else {
            await this.axios
              .get(
                `/api2/study/study/get_study_by_filter?filter_field=envelope_code&value=${actual_val}`
              )
              .then((response) => {
                // console.log('response', response.data)
                if (response.data === true) {
                  this.isValidCode = false
                  return
                } else {
                  this.isValidCode = true
                }
              })
          }
        }
      }
      if (this.pharma.name === 'GSK') {
        const asked_code = await axios
          .get(`/api2/biomarker/tumor/${this.tumor}`)
          .then((response) => response.data.code.toUpperCase())
          .catch((error) => console.log(error.message))

        if (asked_code === TUMOR_TYPES.ENDOMETRIUM.toUpperCase()) {
          //eslint-disable-next-line no-useless-escape
          if (!actual_val.startsWith('GE-') || actual_val.length != 10) {
            this.isValidCode = false
            return
          }
          if (
            actual_val.startsWith('GE-') &&
            actual_val.length == 10 &&
            !this.bloqueoPeticion
          ) {
            await this.axios
              .get(
                `/api2/study/study/get_study_by_filter?filter_field=envelope_code&value=${actual_val}`
              )
              .then((response) => {
                // console.log('response', response.data)
                if (response.data === true) {
                  this.isValidCode = false
                  return
                } else {
                  this.isValidCode = true
                }
              })
          }
        }
        if (asked_code === TUMOR_TYPES.OVARIO.toUpperCase()) {
          //eslint-disable-next-line no-useless-escape

          if (!actual_val.startsWith('GO-') || actual_val.length != 10) {
            this.isValidCode = false
            return
          }
          if (
            actual_val.startsWith('GO-') &&
            actual_val.length == 10 &&
            this.bloqueoPeticion == false
          ) {
            await this.axios
              .get(
                `/api2/study/study/get_study_by_filter?filter_field=envelope_code&value=${actual_val}`
              )
              .then((response) => {
                // console.log('response', response.data)
                if (response.data === true) {
                  this.isValidCode = false
                  return
                } else {
                  this.isValidCode = true
                }
              })
          }
        }
      }
      // console.log('validcode-m', this.isValidCode)
      await this.axios
        .get(`/api/envelope/checkCode?code=${actual_val}`)
        .then((response) => {
          // console.log('soy la respuesta', response)

          if (!response.data.valid && !this.isValidCode) {
            //3: invalido y no existe
            // console.log('validcode-m+3', this.isValidCode)
            this.isValidCode = false
            return
          }

          if (!response.data.valid && this.isValidCode) {
            //4: invalido y existe
            // console.log('validcode-m+4', this.isValidCode)
            this.isValidCode = false
            return
          }
          if (response.data.valid && !this.isValidCode) {
            //1: valido y existe
            // console.log('validcode-m+2', this.isValidCode)
            this.isValidCode = false
          }
          if (response.data.valid && this.isValidCode) {
            //2: no valido y no existe
            // console.log('validCode-m+5')
            this.isValidCode = response.data.valid
          }
        })
        .finally(() => {
          this.bloqueoPeticion = true
          setTimeout(() => {
            this.bloqueoPeticion = false
            // console.log('peticionn')
          }, 1)
        })
    },
    async QRReaderInitialized(promise) {
      try {
        await promise
        this.qrReaderStatus.error = null
      } catch (error) {
        const errorMapping = {
          NotAllowedError:
            'No se permitió el acceso a la cámara. Puede intentar habilitar el sitio desde su explorador en preferencias de privacidad -> Cámara y habilitar manualmente pluton.biomakers.net.',
          NotFoundError:
            'Este dispositivo no cuenta con una cámara compatible.',
          NotSupportedError: 'Esta página no se sirvió via SSL.',
          NotReadableError: 'Hubo un error en la cámara del dispositivo.',
          OverconstrainedError:
            'Hubo un error en la cámara / especificaciones del dispositivo.',
          StreamApiNotSupportedError:
            'Su navegador no soporta esta funcionalidad.',
        }
        this.qrReaderStatus.error =
          errorMapping[error.name] ||
          'Su dispositivo no soporta esta funcionalidad.'
      }
    },

    decodedQRCode(decoded) {
      const envelopeCode = decoded.split('/')
      if (decoded.indexOf('biomakers.net') == -1 || envelopeCode.length != 5) {
        alert(
          'Código QR inválido, intente nuevamente o ingrese el código de forma manual.'
        )
        this.qrReaderStatus.paused = true
        this.$nextTick(() => {
          this.qrReaderStatus.paused = false
        })
        return
      }

      this.inputMethod = 'manually'
      this.setEnvelopeCode(envelopeCode[4])
    },
  },
}
</script>
