<template lang="html">
  <div>
    <d-col>
      <management-header :title="title">
        <router-link
          :to="{ name: NAMED_ROUTES.PANELS_CREATE }"
          tag="button"
          class="btn btn-pill btn-accent"
        >
          Agregar
        </router-link>
        <router-link
          :to="{ name: NAMED_ROUTES.GENES_PANELS_UMBRELLAS }"
          tag="button"
          class="btn btn-pill btn-secondary"
          >Volver</router-link
        >
      </management-header>
      <h3 v-if="panels.length == 0" style="color: red">
        Todavía no hay Paneles configurados
      </h3>
      <d-row v-else>
        <d-col v-for="panels in panels" :key="panels.name" md="4" class="p-4">
          <router-link
            :to="{ name: NAMED_ROUTES.PANEL, params: { panelId: panels.id } }"
            tag="div"
            class="card p-4 h-100"
          >
            <header>
              <div class="d-flex justify-content-between">
                <h3>{{ panels.name }}</h3>
              </div>
              <p :class="panels.rowStatus === 'E' ? 'enabled' : 'disabled'">
                {{ panels.rowStatus === 'E' ? 'Habilitado' : 'Deshabilitado' }}
              </p>
              <div class="d-flex-content-between">
                <div
                  v-if="
                    panel_genes_configuration.find(
                      (element) => element.panelId === panels.id
                    ) != undefined
                  "
                >
                  <p>
                    {{
                      panel_genes_configuration.filter(
                        (g_id) =>
                          g_id.panelId === panels.id && g_id.rowStatus === 'E'
                      ).length
                    }}
                    genes configurados
                  </p>
                </div>
              </div>
            </header>
          </router-link>
        </d-col>
      </d-row>
    </d-col>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ManagementHeader from '@/components/ManagementHeader.vue'

export default {
  return() {},
  components: {
    ManagementHeader,
  },
  computed: {
    ...mapGetters('panels', [
      'panels',
      'panel',
      'umbrella',
      'genes',
      'panel_genes_configuration',
    ]),
    title() {
      return this.panels.length === 1
        ? 'Panel'
        : `${this.panels.length} paneles`
    },
  },
  async mounted() {
    //do something after mounting vue instance
    await this.getPanels()
    await this.getUmbrellas()
    await this.getGenes()
    await this.getPanelGenesConfiguration()
  },
  methods: {
    ...mapActions('panels', [
      'getPanels',
      'getUmbrellas',
      'getGenes',
      'getPanelGenesConfiguration',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.card {
  cursor: pointer;
  .enabled {
    color: green;
  }
  .disabled {
    color: red;
  }
  h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 400;
    margin: 0;
  }
}
</style>
