import axios from 'axios'

import { parse_pydantic_errors } from '@/utils'
import { ROW_STATUS } from '@/utils/constants'

const getEmptyPharma = () => ({
  code: null,
  name: null,
  requirements: null,
  requiresSignedConsent: false,
  validateEnvelopeWithServer: false,
  requiresPharmaEnvelopeCode: false,
  validateEnvelopeWithNewApi: false,
  rowStatus: 'E',
})

const getDefaultState = () => ({
  status: null,
  errors: {},
  dirty: false,
  pharmas: [],
  pharma: getEmptyPharma(),
})

const getters = {
  pharmas: (state) => state.pharmas,
  pharmasEnabled: (state) =>
    state.pharmas.filter((pharma) => pharma.rowStatus === ROW_STATUS.ENABLED),
  isDirty: (state) => state.dirty,
  isValid: (state) =>
    !['', null].includes(state.pharma.name) &&
    !['', null].includes(state.pharma.code),
  isNew: (state) =>
    !state.pharmas.map((pharma) => pharma.id).includes(state.pharma.id),
  hasErrors: (state) => Object.entries(state.errors).length > 0,
  errors: (state) => state.errors,
}

const actions = {
  async getPharmas({ commit, state }) {
    if (state.status === 'loading') return
    commit('REQUEST_INIT')
    try {
      const pharmas = (await axios.get('/api2/pharma/')).data
      commit('SET_PHARMAS', pharmas)
      commit('REQUEST_SUCCESS')
    } catch (err) {
      commit('REQUEST_ERROR', err)
    }
  },
  async savePharma({ commit, state, getters }) {
    if (!getters.isDirty) return
    const method = getters.isNew ? 'post' : 'put'
    const url =
      method === 'post' ? '/api2/pharma/' : `/api2/pharma/${state.pharma.id}`

    commit('REQUEST_INIT')
    try {
      const pharma = (
        await axios({
          method,
          url,
          data: { ...state.pharma },
        })
      ).data
      commit('REQUEST_SUCCESS')

      const pharmas = [...state.pharmas]
      if (method === 'post') {
        pharmas.push(pharma)
      } else {
        const index = pharmas.findIndex((c) => c.id === pharma.id)
        pharmas[index] = pharma
      }
      commit('SET_PHARMAS', pharmas)
    } catch (err) {
      commit('REQUEST_ERROR', parse_pydantic_errors(err.response.data))
    }
  },
  setPharma({ commit, state }, pharmaId) {
    const pharma = state.pharmas.find((pharma) => pharma.id === pharmaId)
    if (pharma) commit('SET_PHARMA', { ...pharma })
    else commit('SET_PHARMA', getEmptyPharma())
  },
  setPharmaName({ commit }, name) {
    commit('SET_PHARMA_NAME', name)
  },
  setPharmaCode({ commit }, code) {
    commit('SET_PHARMA_CODE', code)
  },
  setPharmaRequirements({ commit }, requirements) {
    commit('SET_PHARMA_REQUIREMENTS', requirements)
  },
  setPharmaRequiresSignedConsent({ commit }, val) {
    commit('SET_PHARMA_REQUIRES_SIGNED_CONSENT', val)
  },
  setPharmaValidateEnvelopeWithServer({ commit }, val) {
    commit('SET_PHARMA_VALIDATE_ENVELOPE_WITH_SERVER', val)
  },
  setPharmaRequiresPharmaEnvelopeCode({ commit }, val) {
    commit('SET_PHARMA_REQUIRES_PHARMA_ENVELOPE_CODE', val)
  },
  setPharmaValidateEnvelopeWithNewApi({ commit }, val) {
    commit('SET_PHARMA_VALIDATE_PHARMA_ENVELOPE_CODE_WITH_NEW_API', val)
  },
  setPharmaAdmitsEnvelopeAbsense({ commit }, val) {
    commit('SET_PHARMA_ADMITS_ENVELOPE_ABSENSE', val)
  },
  setPharmaRequiresQrOption({ commit }, val) {
    commit('SET_PHARMA_REQUIRES_QR_OPTION', val)
  },
  setPharmaRowStatus({ commit }, val) {
    commit('SET_PHARMA_ROW_STATUS', val)
  },
}

const mutations = {
  REQUEST_INIT(state) {
    state.status = 'loading'
  },
  REQUEST_SUCCESS(state) {
    state.status = 'success'
    state.errors = {}
    state.dirty = false
  },
  REQUEST_ERROR(state, message) {
    state.status = 'error'
    state.errors = message
  },
  SET_PHARMAS(state, pharmas) {
    state.pharmas = pharmas
  },
  SET_PHARMA(state, pharma) {
    state.pharma = pharma
  },
  SET_PHARMA_NAME(state, name) {
    state.dirty = true
    state.pharma.name = name
  },
  SET_PHARMA_CODE(state, code) {
    state.dirty = true
    state.pharma.code = code
  },
  SET_PHARMA_REQUIREMENTS(state, requirements) {
    state.dirty = true
    state.pharma.requirements = requirements
  },
  SET_PHARMA_REQUIRES_SIGNED_CONSENT(state, val) {
    state.dirty = true
    state.pharma.requiresSignedConsent = val
  },
  SET_PHARMA_VALIDATE_ENVELOPE_WITH_SERVER(state, val) {
    state.dirty = true
    state.pharma.validateEnvelopeWithServer = val
  },
  SET_PHARMA_REQUIRES_PHARMA_ENVELOPE_CODE(state, val) {
    state.dirty = true
    state.pharma.requiresPharmaEnvelopeCode = val
  },
  SET_PHARMA_VALIDATE_PHARMA_ENVELOPE_CODE_WITH_NEW_API(state, val) {
    state.dirty = true
    state.pharma.validateEnvelopeWithNewApi = val
  },
  SET_PHARMA_ADMITS_ENVELOPE_ABSENSE(state, val) {
    state.dirty = true
    state.pharma.admitsEnvelopeAbscense = val
  },
  SET_PHARMA_REQUIRES_QR_OPTION(state, val) {
    state.dirty = true
    state.pharma.requiresQrOption = val
  },
  SET_PHARMA_ROW_STATUS(state, val) {
    state.dirty = true
    state.pharma.rowStatus = val
  },
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  actions,
  mutations,
}
