<template>
  <header>
    <div>
      <h2>{{ title }}</h2>
    </div>
    <div>
      <slot />
    </div>
  </header>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  h2 {
    font-size: 1.625rem;
    font-weight: 500;
    line-height: 1;
    margin: 0;
  }

  div:last-child {
    display: flex;
    gap: 0.5rem;
  }
}
</style>
