import axios from 'axios';

export const getStudyCSVFields = async () => {
  const response = await axios.get('/api2/study/csv/fields')
  if (response.data && response.status === 200)
    return response.data;
};

export const postStudyCSV = async (formData) => {
  return await axios.post('/api2/study/csv', formData)
};
