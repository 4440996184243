<template>
  <div>
    <d-card
      v-for="(pickup_center, i) in value"
      :key="pickup_center.__vue_id"
      class="inner-card mb-4"
    >
      <d-card-header>
        Punto de Retiro
        <a v-if="i > 0" style="float: right" @click="deleteItem(pickup_center)"
          ><i class="material-icons">close</i></a
        >
      </d-card-header>
      <d-card-body>
        <d-form>
          <div class="form-group">
            <form-label required> Nombre </form-label>
            <d-input
              v-model="pickup_center.name"
              type="text"
              placeholder="Nombre de la institución"
              @change="$v.current_item.$each[i].name.$touch()"
            />
            <d-form-invalid-feedback
              v-if="
                $v.current_item.$each[i].name.$invalid &&
                $v.current_item.$each[i].name.$dirty
              "
              :force-show="true"
            >
              Este campo es obligatorio
            </d-form-invalid-feedback>
          </div>

          <div class="form-group">
            <form-label required> Calle y número </form-label>
            <d-input
              v-model="pickup_center.address_1"
              type="text"
              placeholder="Calle y número"
              @change="$v.current_item.$each[i].address_1.$touch()"
            />
            <d-form-invalid-feedback
              v-if="
                $v.current_item.$each[i].address_1.$invalid &&
                $v.current_item.$each[i].address_1.$dirty
              "
              :force-show="true"
            >
              Este campo es obligatorio
            </d-form-invalid-feedback>
          </div>

          <div class="form-group">
            <form-label>Piso / Oficina</form-label>
            <d-input
              v-model="pickup_center.address_2"
              type="text"
              placeholder="Piso / Oficina"
              @change="$v.current_item.$each[i].address_2.$touch()"
            />
            <d-form-invalid-feedback
              v-if="
                $v.current_item.$each[i].address_2.$invalid &&
                $v.current_item.$each[i].address_2.$dirty
              "
              :force-show="true"
            >
              Este campo es obligatorio
            </d-form-invalid-feedback>
          </div>

          <div class="form-group">
            <form-label>Sector</form-label>
            <d-input
              v-model="pickup_center.sector"
              type="text"
              placeholder="Sector"
              @change="$v.current_item.$each[i].sector.$touch()"
            />
            <d-form-invalid-feedback
              v-if="
                $v.current_item.$each[i].sector.$invalid &&
                $v.current_item.$each[i].sector.$dirty
              "
              :force-show="true"
            >
              Este campo es obligatorio
            </d-form-invalid-feedback>
          </div>

          <div class="form-group">
            <form-label required> Código Postal </form-label>
            <d-input
              v-model="pickup_center.postal_code"
              type="text"
              placeholder="Código Postal"
              @change="$v.current_item.$each[i].postal_code.$touch()"
            />
            <d-form-invalid-feedback
              v-if="
                $v.current_item.$each[i].postal_code.$invalid &&
                $v.current_item.$each[i].postal_code.$dirty
              "
              :force-show="true"
            >
              Este campo es obligatorio
            </d-form-invalid-feedback>
          </div>

          <div class="form-group">
            <form-label required> Provincia o Estado </form-label>
            <d-form-select
              v-if="country === 'Argentina'"
              v-model="pickup_center.state"
              name="state"
              :options="administrative_areas_level_1"
              @change="$v.current_item.$each[i].state.$touch()"
            />
            <d-input
              v-else
              v-model="pickup_center.state"
              type="text"
              placeholder="Provincia o Estado"
              @change="$v.current_item.$each[i].state.$touch()"
            />
            <d-form-invalid-feedback
              v-if="
                $v.current_item.$each[i].state.$invalid &&
                $v.current_item.$each[i].state.$dirty
              "
              :force-show="true"
            >
              Este campo es obligatorio
            </d-form-invalid-feedback>
          </div>

          <div class="form-group">
            <form-label required> Localidad </form-label>
            <d-input
              v-model="pickup_center.county"
              type="text"
              placeholder="Localidad"
              @change="$v.current_item.$each[i].county.$touch()"
            />
            <d-form-invalid-feedback
              v-if="
                $v.current_item.$each[i].county.$invalid &&
                $v.current_item.$each[i].county.$dirty
              "
              :force-show="true"
            >
              Este campo es obligatorio
            </d-form-invalid-feedback>
          </div>

          <div class="form-group">
            <form-label required> Teléfono </form-label>
            <d-input
              v-model="pickup_center.telephone_1"
              type="text"
              placeholder="Teléfono de Contacto"
              @change="$v.current_item.$each[i].telephone_1.$touch()"
            />
            <d-form-invalid-feedback
              v-if="
                $v.current_item.$each[i].telephone_1.$invalid &&
                $v.current_item.$each[i].telephone_1.$dirty
              "
              :force-show="true"
            >
              Este campo es obligatorio
            </d-form-invalid-feedback>
          </div>

          <div class="form-group">
            <form-label>Información Adicional</form-label>
            <d-form-textarea
              v-model="pickup_center.additional_information"
              type="text"
              placeholder="Información adicional para retirar el envío (optativo)"
              @change="$v.current_item.$each[i].additional_information.$touch()"
            />
          </div>
        </d-form>
      </d-card-body>
    </d-card>

    <p class="text-center">
      <d-button
        pill
        type="button"
        outline
        class="btn-outline-accent mx-auto"
        @click="addItem"
      >
        Agregar otro punto de retiro
      </d-button>
    </p>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import PlutonBaseMixin from 'src/components/mixins/PlutonBaseMixin'
import FormLabel from 'src/components/Form/Label'

export default {
  name: 'PickupLocations',
  components: { FormLabel },
  mixins: [PlutonBaseMixin],
  props: {
    value: {
      required: true,
      type: Array,
      default: () => [],
    },
    country: {
      type: String,
      default: '',
    },
  },
  data() {
    const current_item = this.value
    return {
      current_item: current_item,
    }
  },
  computed: {
    ...mapGetters('auth', ['user', 'role']),
  },
  validations: {
    current_item: {
      required,
      $each: {
        name: { required },
        address_1: { required },
        address_2: {},
        sector: {},
        county: { required },
        postal_code: { required },
        state: { required },
        telephone_1: { required },
        additional_information: {},
      },
    },
  },
  mounted() {
    if (!this.value.length) this.addItem()
  },
  methods: {
    deleteItem(item) {
      // eslint-disable-next-line vue/no-mutating-props
      this.value.splice(this.value.indexOf(item), 1)
    },
    addItem() {
      const newLocation = {
        name: '',
        address_1: '',
        address_2: '',
        sector: '',
        county: '',
        postal_code: '',
        telephone_1: '',
        additional_information: '',
        state: null,
      }
      if (this.role === 'doctor') newLocation.doctor_id = this.user.relationId
      // eslint-disable-next-line vue/no-mutating-props
      this.value.push(newLocation)
    },
  },
}
</script>

<style scoped lang="scss"></style>
