var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('study-list-header'),_c('div',{staticClass:"charts"},[(_vm.barchart)?_c('bar-chart',{attrs:{"id":"studies-by-period","height":235,"data":_vm.barchart,"colors":"var(--purple-light)"}},[_vm._v(" Estudios solicitados ")]):_vm._e(),(_vm.studiesByStatus.data.length !== 0)?_c('bar-horizontal-chart',{attrs:{"id":"studies-by-status","height":235,"data":_vm.studiesByStatus.data,"y-domain":_vm.studiesByStatus.yDomain,"z-domain":['0', '1'],"colors":['var(--purple-light)', 'var(--grey-3)'],"margin":_vm.studiesByStatusMargin,"show-stack-labels":true,"min-width":600}},[_vm._v(" Estudios por estado ")]):_vm._e()],1),_c('d-row',[_c('d-col',[_c('d-row',{staticClass:"align-items-center my-4"},[_c('d-col',{staticClass:"mt-3 mt-lg-0",attrs:{"cols":"12","md":"8"}},[_c('d-form-input',{attrs:{"type":"text","name":"doctorInputField","placeholder":"Ingrese un código de sobre, número de muestra o nombre de oncólogo","value":_vm.filters.study.search},on:{"input":function($event){return _vm.throttledSearch($event, 'value')}},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}}),(_vm.checkForSearching == true)?_c('div',{staticStyle:{"color":"#5629ff"}},[_vm._v(" Buscando... ")]):_vm._e()],1),_c('d-col',{staticClass:"mt-3 mt-lg-0",attrs:{"cols":"12","md":"4"}},[_c('pagination',{attrs:{"total-count":_vm.totalCount,"current-page":_vm.currentPage,"current-count":_vm.studies.length,"limit":_vm.limit,"next-page":_vm.nextPage,"go-to-page":_vm.goToPage,"previous-page":_vm.previousPage,"position-class":{
              'justify-content-center': true,
              'justify-content-lg-end': true,
            }}})],1)],1),_c('d-row',{staticClass:"studies"},[(_vm.studies.length == 0)?_c('d-col',{attrs:{"cols":"12","md":"4","offset-md":"4"}},[_c('d-card',{staticClass:"card-small mb-4"},[_c('d-card-header',{staticClass:"border-bottom"},[_c('h6',{staticClass:"m-0"},[_vm._v("Estudios")]),_c('div',{staticClass:"block-handle"})]),_c('d-card-body',[(!_vm.isLab())?_c('p',[_vm._v("No se encontraron estudios.")]):_vm._e(),(_vm.isLab())?_c('p',[_vm._v(" No se encontraron estudios con los filtros solicitados. ")]):_vm._e()])],1)],1):_vm._e(),_vm._l((_vm.studies),function(study,id){return _c('d-col',{key:("study-" + id),staticClass:"py-3",attrs:{"cols":"12","lg":"4"}},[(study.umbrellaId != undefined)?_c('study-card',{attrs:{"study":study,"status-labels":_vm.studyLabels.status,"umbrella-name":_vm.umbrellasEnabled.find(function (u) { return u.id == study.umbrellaId; }).name}}):_c('study-card',{attrs:{"study":study,"status-labels":_vm.studyLabels.status,"umbrella-name":null}})],1)})],2),_c('d-row',{staticClass:"align-items-center mt-3"},[_c('d-col',{staticClass:"mt-3 mt-lg-0",attrs:{"cols":"12"}},[_c('pagination',{attrs:{"total-count":_vm.totalCount,"current-page":_vm.currentPage,"current-count":_vm.studies.length,"limit":_vm.limit,"next-page":_vm.nextPage,"go-to-page":_vm.goToPage,"previous-page":_vm.previousPage,"position-class":{
              'justify-content-center': true,
              'justify-content-lg-end': true,
            }}})],1)],1)],1)],1),(
      _vm.PSPNotifications.length > 0 &&
      _vm.$store.state.auth.user.notificationsReaded == false
    )?_c('d-modal',{staticStyle:{"overflow":"auto"},attrs:{"size":"sm","centered":true}},[_c('div',{staticClass:"inline justify-content-center"},[_c('d-modal-title',{staticClass:"text-center",staticStyle:{"padding-left":"2.5rem"}},[_vm._v("Avisos")]),_c('d-button',{staticClass:"bg-white border-white py-1 px-1",staticStyle:{"position":"sticky","bottom":"1%","left":"66%"},attrs:{"id":"closeNotificationModal","type":"button"},on:{"click":function($event){return _vm.readAllNotifications()}}},[_c('span',{staticClass:"material-icons text-accent",staticStyle:{"font-size":"15px"}},[_vm._v("close")])]),_c('d-tooltip',{attrs:{"target":"#closeNotificationModal"}},[_vm._v("Cerrar dialogo de notificaciones")])],1),_c('div',{staticClass:"justify-content-center"},_vm._l((_vm.PSPNotifications),function(notification,index){return _c('notification',{key:notification.id,attrs:{"notification":notification,"index":index,"time-until-dismissed":null},on:{"hide":function($event){return _vm.hideNotification(notification)}}})}),1)]):_vm._e(),_c('router-link',{staticClass:"floating-action-button",attrs:{"to":{ name: _vm.NAMED_ROUTES.STUDY_CREATE }}},[_c('span',{staticClass:"material-icons"},[_vm._v("add")]),_c('span',{staticClass:"text"},[_vm._v("Agregar estudio")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }