import { NAMED_ROUTES } from '@/utils/constants'

export default function () {
  return [
    {
      title: 'Estudios',
      requiresUserType: ['lab', 'doctor'],
      items: [
        {
          title: 'Estudios',
          to: NAMED_ROUTES.STUDIES,
          icon: 'list',
          nested: 'study-filters',
          displayCondition: (context) =>
            [NAMED_ROUTES.STUDIES, NAMED_ROUTES.STATS].includes(
              context.$route.name
            ),
        },
      ],
    },
    {
      title: 'Gestión',
      requiresUserType: ['admin'],
      items: [
        {
          title: 'Usuarios',
          to: NAMED_ROUTES.USERS,
          icon: 'groups',
        },
        {
          title: 'Países',
          to: NAMED_ROUTES.COUNTRIES,
          icon: 'flag',
          excludeIf: [{ role: 'capsManager' }],
        },
        {
          title: 'Laboratorios',
          to: NAMED_ROUTES.LABS,
          icon: 'group_work',
          excludeIf: [{ role: 'capsManager' }],
        },
        {
          title: 'Tipos de tumor',
          to: NAMED_ROUTES.TUMORS,
          icon: 'health_and_safety',
          excludeIf: [{ role: 'capsManager' }],
        },
        {
          title: 'Resultados',
          to: NAMED_ROUTES.RESULTS,
          icon: 'assignment',
          excludeIf: [{ role: 'capsManager' }],
        },
        {
          title: 'Biomarcadores',
          to: NAMED_ROUTES.BIOMARKERS,
          icon: 'biotech',
          excludeIf: [{ role: 'capsManager' }],
        },
        {
          title: 'Genes, paneles y umbrellas',
          to: NAMED_ROUTES.GENES_PANELS_UMBRELLAS,
          icon: 'blur_linear',
          excludeIf: [{ role: 'capsManager' }],
        },
        {
          title: 'Enviar pedidos a trazabilidad',
          to: NAMED_ROUTES.ORDERS_TO_SEND,
          icon: 'send',
          excludeIf: [{ role: 'capsManager' }],
        },
        {
          title: 'Farmacéuticas',
          to: NAMED_ROUTES.PHARMAS,
          icon: 'science',
          excludeIf: [{ role: 'capsManager' }],
        },
        {
          title: 'Credenciales de API',
          to: NAMED_ROUTES.OAUTH_CREDENTIALS,
          icon: 'lock',
          excludeIf: [{ role: 'capsManager' }],
        },
        {
          title: 'Cargar estudios',
          to: NAMED_ROUTES.BULK_STUDIES,
          icon: 'file_upload',
          excludeIf: [{ role: 'capsManager' }],
        },
      ],
    },
    {
      title: 'General',
      items: [
        {
          title: 'Ayuda',
          to: NAMED_ROUTES.HELP,
          icon: 'help',
        },
      ],
    },
  ]
}
