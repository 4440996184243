<template>
  <div :class="{ 'has-error': error, 'is-disabled': disabled }">
    <input
      :type="type"
      :name="name"
      :placeholder="placeholder"
      :value="value"
      :disabled="disabled"
      @input="$emit('input', $event.target.value)"
    >
    <label
      :for="name"
      :data-content="placeholder"
    >
      <span class="hidden--visually">{{ placeholder }}</span>
    </label>
    <span class="text-bottom">{{ bottomText }}</span>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    value: {
      type: [Number, String],
      required: false,
    },
    type: {
      type: String,
      default: 'text'
    },
    error: {
      type: String,
      default: null,
    },
    help: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bottomText() {
      return this.error || this.help;
    },
  },
}
</script>

<style lang="scss" scoped>
.hidden--visually {
  border: 0;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

input {
  width: 100%;
  background: rgba(0,0,0,0.05);
  padding: 1.2rem 1rem 0.4rem;
  font-size: 1rem;
  border-width: 0 0 2px;
  border-radius: 5px 5px 0 0;

  &::placeholder {
    color: rgba(0, 0, 0, 0);
  }

  &:focus {
    outline: none;
  }
}

label {
  margin: 0;
  display: block;
  position: relative;
  max-height: 0;
  pointer-events: none;

  &::before {
    content: attr(data-content);
    display: inline-block;
    filter: blur(0);
    backface-visibility: hidden;
    transform-origin: left top;
    transition: transform 0.2s ease;
    left: 1rem;
    position: relative;
  }
}

input:placeholder-shown + label::before {
  transform: translate3d(0, -2.2rem, 0) scale3d(1, 1, 1);
}

label::before,
input:focus + label::before {
  transform: translate3d(0, -3.12rem, 0) scale3d(0.82, 0.82, 1);
}

.text-bottom {
  font-size: 0.8rem;
  height: 0.8rem;
  padding-left: 1rem;
}

.has-error {
  & input {
    border-bottom-color: #b00020;
  }

  & .text-bottom, label {
    color: #b00020;
  }
}

.is-disabled {
  opacity: 0.8;
}
</style>
