import axios from 'axios'

export const getDefaultState = () => ({
  status: null,
  errors: {},
  dirty: false,
  frontEndConfigs: [],
})

const getters = {
  dirty: (state) => state.dirty,
  errors: (state) => state.errors,
  status: (state) => state.status,
  frontEndConfigs: (state) => state.frontEndConfigs,
}
const actions = {
  async getFrontEndConfigurationsForCreateStudy({ commit }) {
    commit('REQUEST_INIT')
    try {
      const data = (
        await axios.get(
          '/api2/frontend_configurations/all_frontend_labels_for_insurance_details'
        )
      ).data
      commit('SET_ALL_FRONTEND_LABELS_FOR_INSURANCE_DETAILS', data)
      commit('REQUEST_SUCCESS')
    } catch (e) {
      commit('REQUEST_ERROR', e)
    }
  },
}
const mutations = {
  REQUEST_INIT(state) {
    state.dirty = true
    state.status = 'loading'
  },
  REQUEST_SUCCESS(state) {
    state.status = 'success'
    state.errors = {}
    state.dirty = false
  },
  REQUEST_ERROR(state, error) {
    state.errors = error
    state.status = 'error'
    state.dirty = false
  },
  SET_ALL_FRONTEND_LABELS_FOR_INSURANCE_DETAILS(state, data) {
    state.dirty = true
    state.frontEndConfigs = data
  },
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  actions,
  mutations,
}
