<template>
    <div
      v-if="!printMode"
      class="text-center"
    >
      <a
        href="#"
        @click="toogleZoom"
      >
        <span :class="jansen ? 'jansen-color':''" style="font-size: 16px;">A</span>
        <span :class="jansen ? 'jansen-color':''" style="font-size: 11px !important;">A</span>
        <i :class="jansen ? 'jansen-color':''" class="material-icons">{{ zoomIcon }}</i>
      </a>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default { 
  data() {
    return {
      jansen: false,
    }
  },
  mounted() {
    const URL_JANSEN_STG ="https://janssen.pluton-stg.biomakers.net/#/login"
    const URL_JANSEN_PROD_V1 = "https://janssen.pluton.biomakers.net/#/login"    
    const URL_JANSEN_PROD_V2 = "https://janssen.biomakers.net/#/login"
    let url = window.location.href
    if (url === URL_JANSEN_STG || url === URL_JANSEN_PROD_V1 || url === URL_JANSEN_PROD_V2) this.jansen = true
  },
  computed: {
    zoomIcon() {
      return this.$store.state.ui.zoom ? 'arrow_downward' : 'arrow_upward'
    },
    printMode() {
      return !!this.$route.query.print
    }
  },
  methods: {
    ...mapMutations('ui', [
      'toogleZoom',
    ]),
  },
}
</script>



<style scoped lang="scss">
.jansen-color {
  color: whitesmoke;
}
</style>