<template>
  <div>
    <management-header :title="title">
      <router-link
        :to="{ name: NAMED_ROUTES.TUMOR_CREATE }"
        tag="button"
        class="btn btn-pill btn-accent"
      >
        Agregar
      </router-link>
    </management-header>

    <d-row>
      <d-col v-for="tumor in primaryTumors" :key="tumor.id" md="4" class="p-4">
        <router-link
          :to="{
            name: NAMED_ROUTES.TUMOR,
            params: { primaryTumorId: tumor.id },
          }"
          tag="div"
          class="card p-4 h-100"
        >
          <header>
            <h3>{{ tumor.name }}</h3>
            <p :class="tumor.rowStatus === 'E' ? 'enabled' : 'disabled'">
              {{ tumor.rowStatus === 'E' ? 'Habilitado' : 'Deshabilitado' }}
            </p>
            <p v-if="tumor.bibliographies.length">
              {{ tumor.bibliographies.length }} referencia{{
                tumor.bibliographies.length > 1 ? 's' : ''
              }}
              a literatura asociada
            </p>
          </header>
          <aside>
            <img :src="tumor.icon" :alt="`Ícono de tumor ${tumor.name}`" />
          </aside>
        </router-link>
      </d-col>
    </d-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ManagementHeader from '@/components/ManagementHeader.vue'
import { NAMED_ROUTES } from '@/utils/constants'

export default {
  components: {
    ManagementHeader,
  },
  data() {
    return {
      NAMED_ROUTES,
    }
  },
  computed: {
    ...mapGetters('primaryTumors', ['primaryTumors']),
    title() {
      return this.primaryTumors.length === 1
        ? '1 tipo de tumor'
        : `${this.primaryTumors.length} tipos de tumor`
    },
  },
  async mounted() {
    await this.getPrimaryTumors()
  },
  methods: {
    ...mapActions('primaryTumors', ['getPrimaryTumors']),
  },
}
</script>

<style lang="scss" scoped>
.card {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 400;
    margin: 0;
  }

  .enabled {
    color: green;
  }
  .disabled {
    color: red;
  }

  aside {
    width: 5rem;
  }
}
</style>
