var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('hr'),(!_vm.isKRASG12C)?_c('div',[(_vm.umbrella || _vm.biomarkersTests.length > 1)?_c('form-label',{staticClass:"mb-2 mt-4",attrs:{"required":""}},[_vm._v(" Código del sobre para "+_vm._s(_vm.pharma.name)+" ")]):_c('form-label',[_vm._v(" Código del sobre de "+_vm._s(_vm.biomarkersTests .find(function (bmk) { return bmk.testId === _vm.pharma.biomarkerId; }) .testCode.toUpperCase())+" para "+_vm._s(_vm.pharma.name)+" ")])],1):_c('form-label',[_vm._v(" Código para "+_vm._s(_vm.pharma.name))]),(!_vm.inputMethod && _vm.requiresSelectMethodInput)?_c('div',[_vm._m(0),_c('d-button-group',{staticClass:"w-100",attrs:{"size":"large","aria-label":"Seleccione un método para ingresar el código de sobre"}},[(_vm.pharma.requiresQrOption && !_vm.isKRASG12C)?_c('d-button',{class:_vm.dontHaveCode ? 'btn-outline-accent' : 'btn-accent',on:{"click":function () { return (_vm.inputMethod = 'qr'); }}},[_vm._v(" Habilitar cámara ")]):_vm._e(),_c('d-button',{class:!_vm.pharma.requiresQrOption ? 'btn-accent' : 'btn-outline-accent',on:{"click":function () { return (_vm.inputMethod = 'manually'); }}},[_vm._v(" Ingresar código manualmente ")]),(_vm.pharma.admitsEnvelopeAbscense)?_c('d-button',{class:_vm.dontHaveCode ? 'btn-accent' : 'btn-outline-accent',attrs:{"value":null},on:{"click":function () {
            _vm.dontHaveCode = true
            _vm.$emit('input', {
              value: null,
              dontHavePharmaEnvelopeCode: true,
              dontHaveEnvelopeCode: true,
              testIds: _vm.biomarkersTests
                .filter(function (t) { return t.pharmaId === _vm.pharma.id; })
                .map(function (t) { return t.testId; }),
              pharmaId: _vm.pharma.id,
              isValidCode: true,
              requiresValidationWithNewApi:
                _vm.pharma.requiresValidationWithNewApi,
              validateEnvelopeWithNewApi: _vm.pharma.validateEnvelopeWithNewApi,
            })
          }}},[_vm._v(" No tengo código de sobre ")]):_vm._e()],1)],1):_vm._e(),(_vm.inputMethod === 'qr')?_c('div',[_c('h5',{staticClass:"text-center"},[_vm._v("Por favor enfoque el Código QR con la cámara")]),(!_vm.qrReaderStatus.error)?_c('qrcode-stream',{ref:"qr_reader",attrs:{"paused":_vm.qrReaderStatus.paused},on:{"init":_vm.QRReaderInitialized,"decode":_vm.decodedQRCode}}):_c('p',[_vm._v(" Lamentablemente no pudimos escanear el Código QR."),_c('br'),_vm._v(" "+_vm._s(_vm.qrReaderError)+" ")]),_c('center',[_c('button',{staticClass:"btn btn-outline-accent btn-lg mt-4",on:{"click":function () { return (_vm.inputMethod = 'manually'); }}},[_vm._v(" Ingresar código manualmente ")])])],1):(_vm.inputMethod === 'manually')?_c('div',[(_vm.isKRASG12C)?_c('div',{staticClass:"form-group"},[_c('p',{staticClass:"mb-2"},[_vm._v(" Por favor ingrese el codigo que inicia con p- seguido de los restantes 7 digitos ")]),_c('d-input',{staticClass:"uppercase large-form-text",attrs:{"name":"envelope_code","placeholder":"Ej: p-ABCDEFG","state":_vm.isValidCode},model:{value:(_vm.envelopeCode),callback:function ($$v) {_vm.envelopeCode=$$v},expression:"envelopeCode"}}),_c('d-form-invalid-feedback',[_vm._v(" Codigo Invalido. Comuniquese con su representante de "+_vm._s(_vm.pharma.name)+" ")])],1):_c('div',{staticClass:"form-group"},[_c('d-input',{staticClass:"uppercase large-form-text",attrs:{"name":"envelope_code","placeholder":"Ej: BE42EXT","state":_vm.isValidCode},model:{value:(_vm.envelopeCode),callback:function ($$v) {_vm.envelopeCode=$$v},expression:"envelopeCode"}}),_c('d-form-invalid-feedback',[_vm._v(" Codigo Invalido. Comuniquese con su representante de "+_vm._s(_vm.pharma.name)+" ")])],1),(_vm.pharma.requiresQrOption == true && !_vm.isKRASG12C)?_c('div',[_c('p',[_vm._v("Ingrese el código de 7 caracteres que se encuentra en el sobre.")]),_c('img',{attrs:{"src":"/images/demo/demo-code-highlighted.png"}})]):_vm._e()]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"mb-2"},[_vm._v(" Necesitamos acceso a la cámara de su dispositivo para escanear el código QR del sobre."),_c('br'),_vm._v(" También puede ingresar el código manualmente. ")])}]

export { render, staticRenderFns }