<template>
  <div>
    <study-list-header />
    <breadcrumbs :crumbs="currentBreadcrumbs" />
    <div v-if="!primaryTumorStats.length" class="noInfo">
      No hay información de estudios completados que cumplan con los filtros
      actualmente seleccionados.
    </div>
    <div v-else class="title">
      <h2 v-if="step === 'general'">
        Estudios con alteraciones sobre estudios totales
      </h2>
      <h2 v-else-if="step === 'tumor'">Resultados generales</h2>
      <div v-if="step !== 'biomarker'" class="legend">
        <span class="positive">Positivo</span>
        <span class="negative">Negativo</span>
      </div>
    </div>
    <template
      v-if="['general', 'tumor'].includes(step) && primaryTumors.length"
    >
      <div
        v-for="tumor in primaryTumorStats"
        :key="tumor.tumorId"
        class="tumor"
        :class="step === 'general' ? 'hoverable' : ''"
        v-on="
          step === 'general'
            ? {
                click: () =>
                  setPrimaryTumor({
                    primaryTumorId: tumor.tumorId,
                    primaryTumorCode: getTumor(tumor) && getTumor(tumor).code,
                  }),
              }
            : {}
        "
      >
        <div>
          <donut
            :id="`donut-${tumor.tumorId}`"
            :data="tumor.alterations"
            :colors="getColorsByAlterations(tumor)"
            :height="280"
            :margin="{ top: 22, left: 22, right: 22, bottom: 22 }"
          >
            <img
              :src="getTumor(tumor).icon"
              :alt="`Ícono de tumor ${getTumor(tumor).name}`"
            />
            <span class="name">{{ getTumor(tumor).name }}</span>
            <span class="total">{{ tumor.successfulStudies }}</span>
            <span>estudio{{ checkPlurals(tumor.successfulStudies) }}</span>
            <span class="altered"
              >{{ getAlterationsAmount(tumor) }} con alteraciones</span
            >
          </donut>
          <bar-chart
            :id="`stack-${tumor.tumorId}`"
            :height="280"
            :data="tumor.byDate"
            :x-domain="tumor.sharedDates"
            :y-domain="sharedYDomain"
            :z-domain="['negative', 'positive']"
            :colors="['var(--teal)', 'var(--pink)']"
          />
          <donut
            v-if="tumor.cleanedAlteration.length"
            :id="`donut-${tumor.tumorId}-by-biomarker`"
            :data="tumor.cleanedAlteration"
            :colors="getColorsByArray(tumor.cleanedAlteration)"
            :height="280"
            :margin="{ top: 26, left: 26, right: 26, bottom: 26 }"
            :border="true"
          >
            <span class="total altered">{{
              getAlterationsByStudyTestAmount(tumor)
            }}</span>
            <span class="altered">alteraciones</span>
          </donut>
          <div v-else class="no-alterations-by-biomarker">
            No hay alteraciones por biomarcador para este tumor
          </div>
        </div>
        <p>
          {{ tumor.successfulStudies }} estudio{{
            checkPlurals(tumor.successfulStudies)
          }}
          exitoso{{ checkPlurals(tumor.successfulStudies) }} ({{
            Math.round(
              (tumor.successfulStudies * 100) /
                (tumor.successfulStudies + tumor.insufficientStudies)
            )
          }}%), {{ tumor.insufficientStudies }} estudio{{
            checkPlurals(tumor.insufficientStudies)
          }}
          insuficiente{{ checkPlurals(tumor.insufficientStudies) }} ({{
            Math.round(
              (tumor.insufficientStudies * 100) /
                (tumor.successfulStudies + tumor.insufficientStudies)
            )
          }}%)
        </p>
      </div>
    </template>
    <template
      v-if="
        'tumor' === step && primaryTumorStats.length && biomarkerStats.length
      "
    >
      <h2
        v-if="
          step === 'tumor' &&
          biomarkerStats.filter((stats) => stats.positive > 0)
        "
      >
        Positividad por biomarcador
      </h2>
      <div
        v-for="biomarker in biomarkerStats.filter(
          (stats) => stats.positive > 0
        )"
        :key="`biomarker-bar-${biomarker.id}`"
        class="biomarker"
        :class="step === 'tumor' ? 'hoverable' : ''"
        @mouseover="mouseHoverHandler(biomarker.id)"
        @mouseleave="mouseHoverHandler(-1)"
        v-on="
          step === 'tumor'
            ? {
                click: () =>
                  updateStudyTestFilters({
                    field: 'biomarkerId',
                    value: [biomarker.id],
                  }),
              }
            : {}
        "
      >
        <positivity-bar
          :biomarker="biomarker"
          :name="biomarkers.find((b) => b.id === biomarker.id).name"
        />
      </div>
    </template>
    <div
      v-if="step === 'biomarker' && currentBiomarker"
      class="biomarker-positivity"
    >
      <h2>Positividad</h2>
      <positivity-bar :biomarker="currentBiomarker" />
    </div>
    <template v-if="'biomarker' === step && biomarkerSpecificResults.length">
      <h2>Alteraciones</h2>
      <div>
        <bar-horizontal-chart
          :id="`barchart-${currentBiomarkerId}-specific-results`"
          :height="500"
          :width="1500"
          :data="biomarkerAxis.processedData"
          :y-domain="biomarkerAxis.x"
          :z-domain="biomarkerAxis.processedData.map((val) => val.group)"
          :colors="getColorsByArray(biomarkerSpecificResults)"
          :show-stack-labels="false"
          :margin="{ top: 0, right: 150, bottom: 20, left: 600 }"
        />

        <!-- <bar-chart
          :id="`barchart-${currentBiomarkerId}-specific-results`"
          :height="1000"
          :width="2000"
          :data="biomarkerSpecificResults"
          :xDomain="biomarkerAxis.x"
          :yDomain="biomarkerYDomain"
          :colors="getColorsByArray(biomarkerSpecificResults)"
          :margin="{ top: 16, right: 0, bottom: 16, left: 0 }"
        /> -->
      </div>
    </template>
    <bibliographies
      v-if="step === 'tumor' && primaryTumorStats.length"
      :bibliographies="
        getTumor(primaryTumorStats[0]).bibliographies.filter(
          (b) => b.rowStatus === ROW_STATUS.ENABLED
        )
      "
    />
    <bibliographies
      v-if="step === 'biomarker' && primaryTumorStats.length"
      :bibliographies="
        currentBiomarker.bibliographies != undefined
          ? currentBiomarker.bibliographies.filter(
              (b) => b.rowStatus === ROW_STATUS.ENABLED
            )
          : []
      "
    />
  </div>
</template>

<script>
import * as d3 from 'd3'
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

import { ROW_STATUS } from '@/utils/constants'
import StudyListHeader from '@/components/StudyListHeader'
import BarChart from '@/components/Charts/BarChart'
import Donut from '@/components/Charts/Donut'
import Breadcrumbs from '@/components/Breadcrumbs'
import Bibliographies from '@/components/Bibliographies'
import PositivityBar from '@/components/PositivityBar'
import BarHorizontalChart from '@/components/Charts/BarHorizontalChart'

export default {
  components: {
    StudyListHeader,
    BarChart,
    Donut,
    Breadcrumbs,
    Bibliographies,
    PositivityBar,
    BarHorizontalChart,
  },
  data() {
    return {
      ROW_STATUS,
      step: '', // 'general' | 'tumor' | 'biomarker'
      timeout: null,
      biomarkerIdHover: -1,
      primaryTumorStats: [],
      biomarkerStats: [],
      sharedYDomain: [],
      biomarkerSpecificResults: [],
    }
  },
  computed: {
    ...mapGetters('primaryTumors', ['primaryTumors']),
    ...mapGetters('biomarkers', ['biomarkers']),
    ...mapState('filters', {
      filters: (state) => state,
    }),
    biomarkerAxis() {
      let xAxis = []
      let yAxis = []
      let processedBiomarkersSpecificResults = []
      this.biomarkerSpecificResults.forEach((values) => {
        xAxis.push(values.label)
        yAxis.push(values.amount)
        if (
          !processedBiomarkersSpecificResults.includes(
            (val) => val.label === values.label
          )
        ) {
          processedBiomarkersSpecificResults.push({
            group: values.label,
            ...values,
          })
        }
      })
      return {
        x: xAxis,
        y: yAxis,
        processedData: processedBiomarkersSpecificResults,
      }
    },
    biomarkerYDomain() {
      return [0, d3.max(this.biomarkerAxis.y)]
    },
    currentBreadcrumbs() {
      const crumbs = [
        {
          name: 'Inicio',
          onClick: () => {
            this.setPrimaryTumor({
              primaryTumorId: null,
              primaryTumorCode: null,
            })
            this.updateStudyTestFilters({
              field: 'biomarkerId',
              value: [],
            })
          },
        },
      ]
      if (
        ['tumor', 'biomarker'].includes(this.step) &&
        this.filters.study.primaryTumor
      )
        crumbs.push({
          name: this.getTumor({ tumorId: this.filters.study.primaryTumor })
            .name,
          onClick: () =>
            this.updateStudyTestFilters({
              field: 'biomarkerId',
              value: [],
            }),
        })
      if (this.step === 'biomarker' && this.currentBiomarker)
        crumbs.push({
          name: this.currentBiomarker.name,
        })
      return crumbs
    },
    getTumor() {
      return (tumor) =>
        !tumor ? {} : this.primaryTumors.find((t) => tumor.tumorId == t.id)
    },
    currentBiomarkerId() {
      if (
        !this.filters.studyTest.biomarkerId ||
        this.filters.studyTest.biomarkerId.length > 1 ||
        this.filters.studyTest.biomarkerId.length === 0
      )
        return null
      return this.filters.studyTest.biomarkerId[0]
    },
    currentBiomarker() {
      if (!this.currentBiomarkerId) return null
      const biomarker = this.biomarkers.find(
        (b) => b.id === this.currentBiomarkerId
      )
      if (!biomarker) return { name: 'Biomarcador no identificado' }

      return {
        ...biomarker,
        ...(this.biomarkerStats.find((stat) => stat.id === biomarker.id) || {}),
      }
    },
    getColorsByAlterations() {
      return (tumor) =>
        tumor.alterations.map(
          (stat) =>
            (stat.result === 'positive' && 'var(--pink)') || 'var(--teal)'
        )
    },
    getColorsByArray() {
      const colors = [
        '#FFB42D',
        '#FFD002',
        '#FFF04C',
        '#D7E82E',
        '#9ED46E',
        '#2AD7C7',
        '#4BA7C6',
        '#596FCC',
        '#7255C9',
        '#B454C3',
        '#D74E4E',
        '#FF7C2D',
      ]
      return (arr) => {
        if (!arr) return colors
        // Linspace array of colors
        const step = Math.round(colors.length / arr.length)
        let options = Array.from(
          { length: arr.length },
          (_, i) => step * i
        ).map((i) => colors[i % colors.length])

        if (this.biomarkerIdHover > -1) {
          const greys = d3
            .scaleLinear()
            .domain([0, arr.length])
            .range(['#DFDFDF', '#6b7280'])
          options = options.map((c, i) =>
            arr[i].id === this.biomarkerIdHover ? c : greys(i)
          )
        }
        return options
      }
    },
    checkPlurals() {
      return (num) => (num === 1 ? '' : 's')
    },
    getAlterationsAmount() {
      return (tumor) => {
        const positive = tumor.alterations.find(
          (alteration) => alteration.result === 'positive'
        )
        return positive ? positive.amount : 0
      }
    },
    getAlterationsByStudyTestAmount() {
      return (tumor) => {
        return tumor.alterationsByBiomarker.reduce(
          (acc, biomarker) => acc + biomarker.positive,
          0
        )
      }
    },
    getPercentage() {
      return (biomarker, key) =>
        Math.round(
          (biomarker[key] * 100) / (biomarker.negative + biomarker.positive)
        )
    },
  },
  watch: {
    '$store.state.filters': {
      handler: async function (newFilters) {
        const encodedFilters = `?filters=${encodeURIComponent(
          JSON.stringify(newFilters)
        )}`
        this.primaryTumorStats = (
          await this.axios.get(`/api2/stats/primary_tumors${encodedFilters}`)
        ).data.map((tumor) => {
          tumor.cleanedAlteration = tumor.alterationsByBiomarker.filter(
            (alt) => alt.positive > 0
          )
          return tumor
        })
        this.fetchStudyTestLabels({ filters: newFilters, for_stats: true })

        const amounts = this.primaryTumorStats.flatMap((stats) =>
          stats.byDate.map((d) => d.amount)
        )
        this.sharedYDomain = [0, d3.max(amounts)]

        if (this.currentBiomarkerId) {
          this.step = 'biomarker'
          this.biomarkerIdHover = -1
          this.biomarkerStats =
            this.primaryTumorStats[0].alterationsByBiomarker.filter(
              (b) => this.currentBiomarkerId === b.id && b.successfulStudies > 0
            )
          this.biomarkerSpecificResults = (
            await this.axios.get(
              `/api2/stats/biomarker/${this.currentBiomarkerId}${encodedFilters}`
            )
          ).data
        } else if (this.primaryTumorStats.length === 1) {
          this.step = 'tumor'
          this.biomarkerStats =
            this.primaryTumorStats[0].alterationsByBiomarker.filter(
              (b) => b.successfulStudies > 0
            )
        } else if (this.filters.study.primaryTumor) {
          this.step = 'tumor'
        } else if (this.primaryTumorStats.length > 1) this.step = 'general'
        else {
          this.biomarkerSpecificResults = []
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    this.getBiomarkers()
    this.getPrimaryTumors()
    this.fetchStudyLabels()
    this.fetchPatientLabels()
    this.fetchStudyTestLabels({ filters: {}, for_stats: true })
    this.getSampleTypes()
  },
  methods: {
    ...mapActions('countries', ['getSampleTypes']),
    ...mapActions('labelMappings', [
      'fetchStudyLabels',
      'fetchPatientLabels',
      'fetchStudyTestLabels',
    ]),
    ...mapActions('biomarkers', ['getBiomarkers']),
    ...mapActions('primaryTumors', ['getPrimaryTumors']),
    ...mapActions('filters', ['setPrimaryTumor']),
    ...mapMutations('filters', ['updateStudyTestFilters']),
    mouseHoverHandler(biomarkerId) {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.biomarkerIdHover = biomarkerId
      }, 200)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
h2 {
  font-size: var(--font-lg);
  color: var(--grey-2);
  font-weight: 500;
  margin-bottom: 5px;
}

.noInfo {
  justify-content: center;
  font-size: var(--font-lg);
  height: 50vh;
  display: flex;
  align-items: center;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .legend {
    font-size: var(--font-sm);
    display: flex;
    color: var(--grey-2);

    > * {
      padding-left: 30px;
      display: flex;
      align-items: center;
      gap: 6px;

      &:before {
        content: '';
        display: block;
        border-radius: var(--radius);
        width: 12px;
        height: 4px;
      }
      &.positive:before {
        background-color: var(--pink);
      }
      &.negative:before {
        background-color: var(--teal);
      }
    }
  }
}

.tumor,
.biomarker {
  border-radius: var(--radius);
  padding: 12px;
  color: var(--grey-2);
  transition: 0.1s ease-in;

  &.hoverable {
    background-color: white;
    box-shadow: var(--shadow-sm);
    cursor: pointer;

    &:hover {
      box-shadow: var(--shadow-lg);
    }
  }
}

.tumor {
  align-items: center;
  margin: 30px 0;
  font-size: var(--font-md);
  line-height: var(--font-lg);

  > div {
    display: flex;
    gap: 30px;
    align-items: center;

    @include phone-only {
      flex-direction: column;
    }

    > * {
      flex: 1 1 0%;

      @include phone-only {
        min-width: 100%;
      }
    }

    .bar {
      flex-basis: 15%;
      flex-shrink: 0;
    }
  }

  img {
    width: 60px;
  }

  .name {
    font-size: var(--font-lg);
    font-weight: bold;
  }
  .total {
    font-size: var(--font-xl);
    line-height: 35px;
  }
  .altered {
    color: var(--pink);
  }

  & p {
    margin: 1rem 0 0 0;
    font-weight: light;
    grid-column: 1 / 4;
  }
}

.biomarker {
  margin: 16px 0;
}

.biomarker-positivity {
  padding-bottom: 30px;
}

.biomarker-specific-results {
  display: flex;
  justify-content: center;
  color: var(--pink);
  font-size: var(--font-md);

  h3 {
    font-size: var(--font-lg);
    color: var(--pink);
    line-height: 19px;
    margin: 0;
  }

  .total {
    font-size: var(--font-xl);
  }
}
</style>
