<template>
  <div
    id="top-div"
    :class="{ zoom }"
  >
      <component :is="layout">
        <router-view />
      </component>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('ui', [
      'zoom'
    ]),
    layout() {
      return this.$route.meta.layout || 'default-layout'
    },
  },
}
</script>

<style lang="scss">
  #top-div {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .zoom {
    zoom: 1.2;
  }

  .inner-card .card-body {
    box-shadow: none !important;
  }

.__ripple__container {
  color: inherit;
  border-radius: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 0;
  pointer-events: none;
  contain: strict;
}
.__ripple__animation {
  color: inherit;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background: currentColor;
  opacity: 0;
  transition: 0.4s cubic-bezier(0, 0, 0.2, 1);
  pointer-events: none;
  overflow: hidden;
  will-change: transform, opacity;
}
.__ripple__animation--center {
  transition: none;
}
.__ripple__animation--visible {
  opacity: 0.15;
}
</style>
