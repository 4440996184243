var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{class:[
    'main-sidebar',
    'col-12',
    'col-md-3',
    'col-lg-2',
    'px-0',
    _vm.sidebarVisible ? 'open' : '' ]},[_c('div',{staticClass:"main-navbar"},[_c('nav',{staticClass:"\n        d-flex\n        justify-content-center\n        navbar\n        align-items-center\n        navbar-light\n        p-0\n      "},[_c('div',{staticClass:"button-link",on:{"click":_vm.navigateBack}},[(_vm.displayBackButton)?_c('i',{staticClass:"material-icons d-sm-inline d-md-none"},[_vm._v("arrow_back")]):_vm._e()]),_c('router-link',{staticClass:"navbar-brand flex-grow-1 mr-0",attrs:{"to":{ name: _vm.DEFAULT_NAMED_VIEW_BY_ROLE[_vm.role] }}},[_c('img',{attrs:{"id":"main-logo","src":require("@/assets/images/logos/pluton-2x-white-sidebar.png"),"alt":"Home"}}),(!_vm.hideLogoText)?_c('span',{staticClass:"d-md-inline ml-1"}):_vm._e()]),_c('div',{staticClass:"button-link toggle-sidebar",on:{"click":function($event){return _vm.handleToggleSidebar()}}},[_c('i',{staticClass:"material-icons d-sm-inline d-md-none"},[_vm._v("close")])])],1)]),(_vm.items)?_c('div',{staticClass:"nav-wrapper"},_vm._l((_vm.items),function(nav,navIdx){return _c('div',{key:navIdx,staticClass:"section"},[(
          nav.title &&
          !!nav.items &&
          nav.items.length &&
          _vm.userIsAuthorized(nav, _vm.$auth)
        )?_c('div',[_c('h6',{staticClass:"main-sidebar__nav-title"},[_vm._v(" "+_vm._s(nav.title)+" ")]),_c('d-nav',{staticClass:"nav--no-borders flex-column"},_vm._l((nav.items.filter(
              function (item) { return _vm.userIsAuthorized(item, _vm.$auth) && !_vm.excludeItem(item, _vm.$auth); }
            )),function(item,navItemIdx){return _c('li',{key:navItemIdx,staticClass:"nav-item dropdown",on:{"click":_vm.handleToggleSidebar}},[_c('router-link',{attrs:{"to":{ name: item.to },"tag":"div"}},[_c('a',{directives:[{name:"d-toggle",rawName:"v-d-toggle",value:(("snc-" + navIdx + "-" + navItemIdx)),expression:"`snc-${navIdx}-${navItemIdx}`"}],class:[
                  'nav-link',
                  item.items && item.items.length ? 'dropdown-toggle' : '' ],attrs:{"href":"#"}},[(item.icon)?_c('div',{staticClass:"item-icon-wrapper"},[_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(item.icon))])]):_vm._e(),(item.title)?_c('span',[_vm._v(_vm._s(item.title))]):_vm._e()])]),(_vm.shouldDisplayNested(item))?_c(item.nested,{tag:"component",staticClass:"nav-link d-none d-md-block"}):_vm._e(),(item.items && item.items.length)?_c('d-collapse',{staticClass:"dropdown-menu dropdown-menu-small",attrs:{"id":("snc-" + navIdx + "-" + navItemIdx),"accordion":"sidebar-items-accordion"}},_vm._l((item.items),function(subItem,subItemIdx){return _c('d-dropdown-item',{key:subItemIdx,attrs:{"href":subItem.href,"to":subItem.to}},[_vm._v(" "+_vm._s(subItem.title)+" ")])}),1):_vm._e()],1)}),0)],1):_vm._e()])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }