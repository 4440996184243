/*global axios_request_count:writable*/
/*exported axios_request_count*/

import Vue from 'vue'
import ShardsVue from 'shards-vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import auth from '@websanova/vue-auth'
import driverAuthBearer from '@websanova/vue-auth/drivers/auth/bearer.js'
import driverHttpAxios from '@websanova/vue-auth/drivers/http/axios.1.x.js'
import driverRouterVueRouter from '@websanova/vue-auth/drivers/router/vue-router.2.x.js'
import VueMoment from 'vue-moment'
import Loading from 'vue-loading-overlay'

import 'vue-loading-overlay/dist/vue-loading.css'
import 'bootstrap/dist/css/bootstrap.css'
import './assets/shards-dashboard-pro/shards-dashboards.scss'
import './assets/scss/_variables.scss'
import './assets/scss/charts.scss'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import { NAMED_ROUTES, DEFAULT_NAMED_VIEW_BY_ROLE } from '@/utils/constants'

import LoginLayout from '@/layouts/LoginLayout.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'

Vue.use(Loading)
Vue.use(VueMoment)
Vue.use(ShardsVue)

Vue.component('LoginLayout', LoginLayout)
Vue.component('DefaultLayout', DefaultLayout)

Vue.prototype.$eventHub = new Vue()

Vue.config.productionTip = false

// axios_request_count this is defined in index.html
axios.interceptors.request.use(
  function (config) {
    axios_request_count += 1
    return config
  },
  function (error) {
    axios_request_count -= 1
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function (response) {
    axios_request_count -= 1
    return response
  },
  function (error) {
    axios_request_count -= 1
    return Promise.reject(error)
  }
)

Vue.use(VueAxios, axios)

Vue.router = router
Vue.prototype.NAMED_ROUTES = NAMED_ROUTES
Vue.prototype.DEFAULT_NAMED_VIEW_BY_ROLE = DEFAULT_NAMED_VIEW_BY_ROLE

Vue.use(auth, {
  auth: driverAuthBearer,
  http: driverHttpAxios,
  router: driverRouterVueRouter,
  refreshData: { enabled: false },
  fetchData: {
    url: '/api2/auth/user',
    method: 'GET',
    enabled: true,
    // token: 'token',
  },
  loginData: {
    url: '/api2/auth/login',
    method: 'POST',
    redirect: '/',
    fetchUser: true,
  },
  rolesVar: 'relationType',
})

window.VueRoot = new Vue({
  router,
  store,
  data: {
    loader_object: null,
    state: {
      alerts: {
        success_message: '',
        warning_message: '',
      },
      user: {
        logged_in: false,
        user_details: {},
      },
    },
  },
  render: (h) => h(App),
}).$mount('#app')
