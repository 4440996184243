<template>
  <form @submit="miniPost">
    <d-card class="mb-4">
      <d-card-body>
        <div class="row">
          <h3>{{ isUmbrellaNew ? 'Nuevo' : 'Editar' }} Umbrella</h3>
          <div class="col">
            <span :class="umbrella.rowStatus === 'E' ? 'enabled' : 'disabled'">
              {{ umbrella.rowStatus === 'E' ? 'Habilitado' : 'Deshabilitado' }}
            </span>
          </div>
        </div>
        <v-input
          id="name"
          v-model="umbrellaName"
          :errors="errors.name"
          name="name"
          placeholder="Nombre del Umbrella"
          @input="checkName()"
        />
        <p v-if="isUmbrellaNew && !nameIsAvailable" style="color: red">
          Este nombre de Umbrella ya existe
        </p>
        <p v-if="isNotName" style="color: red">Escriba un nombre de umbrella</p>

        <v-input
          id="code"
          v-model="umbrellaCode"
          :errors="errors.code"
          name="code"
          placeholder="Código del Umbrella"
          @input="checkCode()"
        />
        <p v-if="isUmbrellaNew && !codeIsAvailable" style="color: red">
          Este código de Umbrella ya existe
        </p>
        <p v-if="isNotCode" style="color: red">Escriba un Código de umbrella</p>
        <d-row>
          <d-col>
            <h5>Configuración de los Biomarcadores del umbrella</h5>
            <div class="header">
              <div class="form-group">
                <label>Biomarcador</label>
                <d-form-select
                  id="biomarkersFormSelect"
                  v-model="biomarkerValue"
                  @input="checkSelectedValue"
                >
                  <option value="null" disabled default>
                    Seleccione un biomarcador
                  </option>
                  <option
                    v-for="opt in biomarkersOptions"
                    :key="opt.text"
                    :value="opt.value"
                  >
                    {{ opt.text }}
                  </option>
                </d-form-select>
                <button
                  type="button"
                  class="btn btn-pill btn-accent"
                  :disabled="
                    bmkIsAlreadyConfigured || biomarkerValue.code === ''
                  "
                  @click="
                    setBiomarkerToUmbrella(biomarkerValue)
                    checkSelectedValue()
                  "
                >
                  Agregar Biomarcador
                </button>
                <h6 v-if="bmkIsAlreadyConfigured" class="text-danger">
                  Este biomarcador ya está configurado
                </h6>
                <div class="form-group">
                  <table
                    id="biomarkersConfigured"
                    class="
                      table-responsive
                      table
                      table-striped
                      table-borderless
                      table-center
                    "
                    style="width: 99%"
                  >
                    <colgroup>
                      <col style="width: 50%" />
                      <col style="width: 10%" />
                      <col style="width: 1%" />
                    </colgroup>
                    <thead>
                      <th>Biomarcadores</th>
                      <th>Agregar Configuración</th>
                      <th>Borrar</th>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in umbrella.biomarkers"
                        :key="item.name"
                        class="align-self-center"
                      >
                        <td class="align-middle sticky-column">
                          {{ item.name }}
                        </td>
                        <td>
                          <button
                            type="button"
                            class="
                              align-middle
                              sticky-column
                              material-icons
                              btn-accent
                              custom-btn-umbrella
                            "
                            @click="openConfigModal(item.code)"
                          >
                            view_agenda
                          </button>
                        </td>
                        <td>
                          <button
                            type="button"
                            class="
                              align-middle
                              sticky-column
                              material-icons
                              btn-accent
                              custom-btn-umbrella
                            "
                            @click="setBiomarkerToUmbrella(item)"
                          >
                            delete
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="header">
              <div class="form-group">
                <h5>Panel</h5>
                <label>Panel Asociado</label>
                <d-form-select
                  id="panelFormSelect"
                  v-model="panelId"
                  :options="panelsOptions"
                  @input="() => (isNotPanel = false)"
                >
                  <option value="null" disabled>Seleccione un Panel</option>
                </d-form-select>
              </div>
            </div>
            <div>
              <div>
                <h5>Estado del umbrella</h5>
                <d-form-select
                  id="enabled"
                  v-model="umbrellaRowStatus"
                  :options="rowStatusOptions"
                  :disabled="isUmbrellaNew"
                >
                  <option value="null" disabled>Seleccione un estado</option>
                </d-form-select>
              </div>
            </div>
          </d-col>
        </d-row>
      </d-card-body>
    </d-card>
    <div>
      <div
        style="
          padding: 1px;
          display: inline;
          margin-left: 40%;
          width: 20%;
          height: 20%;
        "
      >
        <button
          v-if="panel_has_created == false"
          type="submit"
          class="btn btn-pill btn-accent"
          :disabled="
            isDirty &&
            isUmbrellaNew &&
            (!nameIsAvailable ||
              !codeIsAvailable ||
              isNotName ||
              isNotCode ||
              isNotPanel)
          "
          @click="checkName()"
        >
          <span
            class="text"
            style="align-items:center centerright: 30px;
          bottom: 10px;
          height: 10px;
          padding: 0 5px;
          display: inline;
          gap: 2px;"
            >{{ !isUmbrellaNew ? 'Editar Umbrella' : 'Agregar Umbrella' }}</span
          >
        </button>

        <button
          type="button"
          name="button"
          class="btn btn-pill btn-secondary"
          style="margin-left: 5px"
          @click="handleDiscard"
        >
          Volver
        </button>
      </div>
    </div>
    <d-modal
      v-if="showModal"
      size="lg"
      class="custom"
      @close="handleCloseConfigModal"
    >
      <d-modal-header>
        <d-modal-title class="text-align-center"
          >Configuración Biomarcador x farma</d-modal-title
        ></d-modal-header
      >
      <d-modal-body>
        <div class="header">
          <div class="form-group">
            <label>Farmaceuticas asociadas</label>
            <d-form-select
              :options="pharmaOptions"
              @change="setBiomarkerPharmaConfig($event)"
            ></d-form-select>
            <p v-if="pharmaIsAlreadyConfigured" class="text-danger">
              Esta farma ya esta configurada
            </p>
          </div>
          <div class="form-group">
            <table class="align-center">
              <colgroup>
                <col style="width: 3%" />
                <col style="width: 3%" />
              </colgroup>
              <thead>
                <th scope="col">Farmacéutica</th>
                <th scope="col">Borrar</th>
              </thead>
              <tbody>
                <tr
                  v-for="pharma in biomarkerValue.pharmas"
                  :key="pharma.id"
                  class="align-self-center"
                >
                  <td class="align-middle sticky-column">{{ pharma.name }}</td>
                  <td class="align-middle sticky-column">
                    <button
                      type="button"
                      class="material-icons btn-accent"
                      style="border-radius: 15%"
                      @click="deletePharmaConfig(pharma.code)"
                    >
                      delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="form-group">
              <button
                class="btn btn-primary"
                type="button"
                @click="saveBiomarkerPharmasConfig"
              >
                Guardar cambios
              </button>
            </div>
          </div>
        </div>
      </d-modal-body>
    </d-modal>
  </form>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import VInput from '@/components/Form/VInput.vue'

export default {
  components: {
    VInput,
  },
  props: {
    umbrellaId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      panel_has_created: false,
      nameIsAvailable: true,
      editBmkConfig: false,
      isNotName: false,
      codeIsAvailable: true,
      isNotCode: false,
      isNotPanel: true,
      bmkIsAlreadyConfigured: false,
      pharmaIsAlreadyConfigured: false,
      showModal: false,
      biomarkerValue: {
        index: null,
        code: '',
        name: '',
        pharmas: [],
      },
    }
  },
  computed: {
    ...mapGetters('panels', [
      'panels',
      'panelsEnabled',
      'genes',
      'panel',
      'genesEnabled',
      'errors',
      'isUmbrellaNew',
      'umbrella',
      'umbrellas',
    ]),
    ...mapGetters('biomarkers', ['biomarkers', 'biomarkersEnabled']),
    ...mapGetters('pharmas', ['pharmasEnabled']),
    ...mapGetters('panels', ['isDirty']),
    ...mapState('panels', ['panel', 'umbrella', 'umbrellas', 'dirty']),
    ...mapState('biomarkers', ['biomarkers']),
    ...mapState('pharmas', ['pharmas']),

    umbrellaName: {
      get() {
        return this.$store.state.panels.umbrella.name
      },
      set(name) {
        this.setUmbrellaName(name)
      },
    },
    umbrellaCode: {
      get() {
        return this.$store.state.panels.umbrella.code
      },
      set(code) {
        this.setUmbrellaCode(code)
      },
    },
    biomarkersConfigured: {
      get() {
        return this.$store.state.panels.umbrella.biomarkers
      },
      set(bmk) {
        this.setBiomarkerToUmbrella(bmk)
      },
    },
    biomarkersOptions() {
      return this.biomarkersEnabled.map((bmk) => ({
        value: bmk,
        text: bmk.name,
      }))
    },
    panelsOptions() {
      return this.panelsEnabled.map((panel) => ({
        value: panel.id,
        text: panel.name,
      }))
    },
    panelId: {
      get() {
        return this.umbrella.panelsId
      },
      set(id) {
        this.setPanelId(id)
      },
    },
    umbrellaRowStatus: {
      get() {
        return this.$store.state.panels.umbrella.rowStatus
      },
      set(rs) {
        this.setUmbrellaRS(rs)
      },
    },
    rowStatusOptions() {
      return {
        E: ['E'],
        D: ['D'],
      }
    },
    pharmaOptions() {
      return this.pharmasEnabled.map((ph) => ({
        value: ph.id,
        text: ph.name,
      }))
    },
  },

  async mounted() {
    //do something after mounting vue instance
    await this.getPanels()
    await this.getBiomarkers()
    await this.getGenes()
    await this.getUmbrellas()
    await this.setUmbrella(this.umbrellaId)
    await this.getPharmas()
  },
  methods: {
    ...mapActions('panels', [
      'getUmbrellas',
      'getPanels',
      'getGenes',
      'setUmbrellaRS',
      'setUmbrellaName',
      'setUmbrellaCode',
      'setPanelId',
      'setBiomarkerToUmbrella',
      'saveUmbrella',
      'setUmbrella',
      'getBiomarkerIndexForUmbrellaConfig',
      'saveUmbrellaPharmaConfig',
    ]),
    ...mapActions('biomarkers', ['getBiomarkers']),
    ...mapActions('pharmas', ['getPharmas']),
    checkSelectedValue() {
      if (
        this.$store.state.panels.umbrella.biomarkers.find(
          (b) => b.code == this.biomarkerValue.code
        )
      ) {
        this.bmkIsAlreadyConfigured = true
      } else {
        this.bmkIsAlreadyConfigured = false
      }
    },
    checkName() {
      let panelName = document.getElementsByName('name')
      if (
        panelName[0].value.length == 0 ||
        panelName[0].value.trim().length == 0
      ) {
        this.isNotName = true
      } else if (
        this.umbrellas.some((umbrella) => umbrella.name === panelName[0].value)
      ) {
        this.nameIsAvailable = false
        this.isNotName = false
      } else {
        this.nameIsAvailable = true
        this.isNotName = false
      }
    },
    checkCode() {
      let panelCode = document.getElementsByName('code')
      if (
        panelCode[0].value.length == 0 ||
        panelCode[0].value.trim().length == 0
      ) {
        this.isNotCode = true
      } else if (
        this.umbrellas.some((umbrella) => umbrella.code === panelCode[0].value)
      ) {
        this.codeIsAvailable = false
        this.isNotCode = false
      } else {
        this.codeIsAvailable = true
        this.isNotCode = false
      }
    },
    getBiomarkerIndexForUmbrellaConfig(bmkCode) {
      return this.umbrella.biomarkers.findIndex((bmk) => bmk.code === bmkCode)
    },
    openConfigModal(bmkCode) {
      this.showModal = true
      let biomarkerIndex = this.getBiomarkerIndexForUmbrellaConfig(bmkCode)
      let biomarker = this.umbrella.biomarkers.find(
        (bmk) => bmk.code === bmkCode
      )
      this.biomarkerValue = {
        name: biomarker.name,
        code: biomarker.code,
        index: biomarkerIndex,
        pharmas: biomarker.pharmas ? biomarker.pharmas : [], //sacar hardcode
      }
    },
    handleCloseConfigModal() {
      this.showModal = false
      this.biomarkerValue = {
        name: null,
        code: null,
        index: null,
        pharmas: [],
      }
    },
    setBiomarkerPharmaConfig(value) {
      if (this.biomarkerValue.pharmas.find((ph) => ph.id == value)) {
        this.pharmaIsAlreadyConfigured = true
      } else {
        let pharma = this.pharmasEnabled.find((ph) => ph.id == value)
        this.biomarkerValue.pharmas.push(pharma)
      }
    },
    deletePharmaConfig(pharmaCode) {
      let idx = this.biomarkerValue.pharmas.findIndex(
        (ph) => ph.code === pharmaCode
      )
      this.biomarkerValue.pharmas.splice(idx, 1)
    },
    saveBiomarkerPharmasConfig() {
      this.saveUmbrellaPharmaConfig(this.biomarkerValue)
      this.handleCloseConfigModal()
    },
    async miniPost(e) {
      if (e) {
        e.preventDefault()
      }
      await this.saveUmbrella()
      this.handleDiscard()
    },
    handleDiscard() {
      this.setUmbrellaName(null)
      this.setUmbrellaCode(null)
      this.setPanelId(null)
      this.$router.push({ name: this.NAMED_ROUTES.UMBRELLAS })
    },
  },
}
</script>
<style lang="scss" scoped>
.custom-btn-umbrella {
  margin-top: 5%;
  margin-left: 20%;
  border-radius: 5%;
}

.result .header,
.result .col {
  width: 50%;
  display: inline;
  gap: 0.2px;
  grid-template-columns: 1fr;
  align-items: center;

  .form-group {
    margin: 0;
  }
}
.bibliography .header,
.bibliography .col {
  width: 100%;
  display: inline-grid;
  gap: 2px;
  grid-template-columns: 1fr;
  align-items: center;

  .form-group {
    margin: 0;
  }
}

@media screen and (min-width: 768px) {
  .result .header,
  .result .col,
  .bibliography .header,
  .bibliography .col {
    grid-template-columns: 1fr 1fr;

    > :last-child {
      grid-column: span 1;
    }
  }
}

@media screen and (min-width: 1024px) {
  .result .header,
  .result .col {
    grid-template-columns: 1fr 1fr 25px 1fr 120px;
    > :last-child {
      grid-column: span 1;
    }
  }

  .bibliography .header,
  .bibliography .col {
    grid-template-columns: 1fr 1fr 1fr 0.5fr 130px;
    > :last-child {
      grid-column: span 1;
    }
  }

  .result .col hr {
    display: none;
  }
  .bibliography .col hr {
    display: none;
  }
}

.header button {
  margin-bottom: 1.2rem;
}

select {
  height: 3.2rem !important;
}
.result button {
  transition: 15ms;
  border: none;
  background: transparent;
  outline: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);

  &:hover {
    transition: 15ms;
    background-color: rgba(0, 0, 0, 0.04);
  }
}
.enabled {
  color: green;
}
.disabled {
  color: red;
}
</style>
