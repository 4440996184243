<template>
  <div class="d-flex justify-content-center align-items-center container">
    <div class="auth-form">
      <d-card class="mb-4">
        <d-card-body border-theme="warning">
          <!-- Logo -->
          <div class="text-center mb-3">
            <img 
              src="../assets/images/logos/pluton-2x-color.png" 
              alt="Plutón"
            >
          </div>

          <!-- Title -->
          <h5 class="auth-form__title text-center mb-4">
            Recuperar contraseña
          </h5>

          <!-- Form Fields -->
          <d-form @submit="handleSubmit">
            <div class="form-group">
              <label>Email</label>
              <d-input 
                v-model="email" 
                type="email" 
                placeholder="Ingrese su email"
                :disabled="status == 'loading'"
              />
            </div>

            <d-alert
              v-if="['error', 'ok'].includes(status)"
              :theme="status === 'error' ? 'danger' : 'success'"
              show="5"
              dismissible
            >
              {{ message }}
            </d-alert>

            <div class="text-center">
              <d-button
                theme="accent"
                pill
                type="submit" 
                :disabled="status === 'loading' || !email"
              >
                {{ status === 'loading' ? 'Cargando...' : 'Recuperar contraseña' }}
              </d-button>

              <small class="mt-3 d-block">
                <router-link
                  :to="{ name: NAMED_ROUTES.LOGIN }"
                  class="text-dark"
                >
                  Ingresar al sistema
                </router-link>
              </small>
            </div>
          </d-form>
        </d-card-body>
      </d-card>
    </div>
  </div>
</template>

<script>
import PlutonBaseMixin from '@/components/mixins/PlutonBaseMixin';

const VIEW_STATUS = Object.freeze({
  START: 'start',
  LOADING: 'loading',
  ERROR: 'error',
  OK: 'ok'
});

const OK_MESSAGE = "Se envió un enlace de recuperación de contraseña al correo ingresado.";

export default {
  name: 'ForgotPassword',
  mixins: [PlutonBaseMixin],
  data() {
    return {
      email: "",
      status: VIEW_STATUS.START,
      message: OK_MESSAGE,
    };
  },
  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      this.status = VIEW_STATUS.LOADING
      const response = await fetch('/api2/auth/forgot_password', {
        method: "POST",
        body: JSON.stringify({ email: this.email}),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        this.status = VIEW_STATUS.OK;
        this.message = OK_MESSAGE;
      } else {
        this.status = VIEW_STATUS.ERROR;
        this.message = (await response.json()).detail[0].msg
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
