<template>
  <div>
    <div v-if="numbersOfAdditionalInputs > 0">
      <d-input-group
        v-for="(item, index) in numbersOfAdditionalInputs"
        :key="item"
      >
        <d-input
          v-model="inputs[index]"
          class="p-1 w-25"
          :placeholder="subLabel"
          :disabled="disabled"
        /><d-input-group-addon append
          ><d-button
            :id="`deleteInput-${index}`"
            class="btn-danger p-1"
            size="sm"
            :disabled="disabled"
            @click.prevent.stop="
              numbersOfAdditionalInputs -= 1
              inputs.splice(index, 1)
            "
            ><span class="material-icons custom-icon-button"
              >remove</span
            ></d-button
          ></d-input-group-addon
        >
        <d-tooltip
          v-if="!disabled"
          :target="`#deleteInput-${index}`"
          class="text-accent"
          :triggers="['hover']"
        >
          Puede borrar este elemento apretando -
        </d-tooltip>
      </d-input-group>
    </div>
    <div v-if="!!inputs">
      <p
        v-if="inputs.every((i) => !i)"
        class="text-accent custom-text-component-2"
      >
        Agregue {{ itemName.endsWith('a') ? 'una' : 'un' }} {{ itemName }}
      </p>
      <p v-else-if="inputs.some((i) => !i)" class="text-danger">
        Este campo es obligatorio *
      </p>
    </div>

    <d-button
      id="addNewInput"
      class="btn-accent"
      :disabled="disabled"
      @click.prevent.stop="
        numbersOfAdditionalInputs += 1
        inputs.push('')
      "
      ><span>Agregar {{ itemsName }}</span></d-button
    >
  </div>
</template>

<script>
export default {
  name: 'InputButtonList',
  props: {
    label: {
      type: String,
      required: false,
      default: 'Ingrese un valor. Agregue otro con el boton +',
    },
    subLabel: {
      type: String,
      required: false,
      default: 'Ingrese un valor. Borre con el boton -',
    },
    passedInputs: {
      type: String,
      required: false,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    itemName: {
      type: String,
      required: false,
      default: () => 'elemento',
    },
    itemsName: {
      type: String,
      required: false,
      default: () => 'elementos',
    },
  },
  data() {
    return {
      numbersOfAdditionalInputs: 0,
      inputs: [],
      processedInputs: null,
    }
  },

  watch: {
    inputs(val) {
      if (val.some((input) => input == '')) {
        this.$emit('listIsValid', '')
      } else {
        this.$emit('listIsValid', val)
      }
    },
  },
  mounted() {
    if (typeof this.passedInputs == 'string') {
      let copyInput = JSON.parse(JSON.stringify(this.passedInputs))
      this.processedInputs = copyInput.split(',')
    }
    if (this.processedInputs) {
      this.inputs = this.processedInputs
      this.numbersOfAdditionalInputs = this.processedInputs.length
    }
  },
}
</script>
<style scoped>
.custom-icon-button {
  font-size: 18px;
  align-content: center;
  vertical-align: middle;
}

.custom-text-component-2 {
  margin-bottom: 0%;
}
</style>
