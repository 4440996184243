<template>
  <div>
    <management-header :title="title">
      <router-link
        :to="{ name: NAMED_ROUTES.PHARMA_CREATE }"
        tag="button"
        class="btn btn-pill btn-accent"
      >
        Agregar
      </router-link>
    </management-header>

    <d-row>
      <d-col
        v-for="pharma in pharmas"
        :key="pharma.id"
        md="4"
        class="p-4"
      >
        <router-link
          :to="{ name: NAMED_ROUTES.PHARMA, params: { pharmaId: pharma.id }}"
          tag="div"
          class="card p-4 h-100"
        >
          <header>
            <h3>{{ pharma.name }}</h3>
            <p :class="pharma.rowStatus === 'E' ? 'enabled' : 'disabled'">
              {{ pharma.rowStatus === 'E' ? 'Habilitado' : 'Deshabilitado' }}
            </p>
          </header>
        </router-link>
      </d-col>
    </d-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ManagementHeader from '@/components/ManagementHeader.vue'

export default {
  components: {
    ManagementHeader,
  },
  computed: {
    ...mapGetters('pharmas', [
      'pharmas',
    ]),
    title() {
      return this.pharmas.length === 1 ? '1 farmacéutica'
        : `${ this.pharmas.length } farmacéuticas`
    }
  },
  async mounted() {
    this.getPharmas()
  },
  methods: {
    ...mapActions('pharmas', [
      'getPharmas',
    ]),
  }
}
</script>

<style lang="scss" scoped>
.card {
  cursor: pointer;

  h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 400;
    margin: 0;
  }

  .enabled {
    color: green;
  }
  .disabled {
    color: red;
  }
}
</style>
