<template>
  <form @submit="saveThisGene">
    <div id="inicio">
      <management-header :title="'Genes'">
        <router-link
          :to="{ name: NAMED_ROUTES.GENES_PANELS_UMBRELLAS }"
          tag="button"
          class="btn btn-pill btn-secondary"
          >Volver
        </router-link>
      </management-header>
      <d-card class="mb4">
        <d-card-body>
          <h3>Agregar Gen</h3>
          <v-input
            id="name"
            v-model="geneName"
            name="name"
            :errors="errors.name"
            placeholder="Nombre del gen"
            @input="checkName"
          />
          <p v-if="!editGene && !nameIsAvailable" style="color: red">
            Este nombre de gen ya existe
          </p>
          <p v-if="!editGene && isNotName" style="color: red">
            Escriba un nombre de Gen válido
          </p>
          <v-input
            id="code"
            v-model="geneCode"
            name="code"
            :errors="errors.name"
            placeholder="Código del gen"
            @input="checkCode"
          />
          <p v-if="!editGene && !codeIsAvailable" style="color: red">
            Este código de gen ya está configurado
          </p>
          <p v-if="!editGene && isNotCode" style="color: red">
            Escriba un código de Gen válido
          </p>
          <div>
            <label>Estado</label>
            <d-form-select
              id="enabled"
              v-model="geneRowStatus"
              :options="rowStatusOptions"
              :disabled="isGeneNew"
            >
              <option value="null" disabled>Seleccione un estado</option>
            </d-form-select>
          </div>
          <button
            name="addGene"
            class="btn btn-pill btn-accent"
            :disabled="
              !editGene &&
              (!nameIsAvailable || !codeIsAvailable || isNotName || isNotCode)
            "
            @click="
              checkName
              checkCode
            "
          >
            {{ editGene === true ? 'Editar gen' : 'Agregar Gen' }}
          </button>
          <button
            type="button"
            class="btn btn-pill btn-secondary"
            @click="handleDiscard"
          >
            Descartar
          </button>
        </d-card-body>
        <d-card-body class="result">
          <h3>Genes configurados</h3>
          <hr />
          <div class="row">
            <div class="col">
              <h6>Gen</h6>
            </div>
            <div class="col">
              <h6>Código</h6>
            </div>
            <div class="col">
              <h6>Estado</h6>
            </div>
            <div class="col">
              <h6>Editar</h6>
            </div>
          </div>
          <template v-for="(gene, idx) in genesConfigured">
            <div :key="idx" class="row">
              <div class="col">
                <span>{{ genesConfigured[idx].name }}</span>
              </div>
              <div class="col">
                <span>{{ genesConfigured[idx].code }}</span>
              </div>
              <div class="col">
                <span
                  :class="
                    genesConfigured[idx].rowStatus === 'E'
                      ? 'enabled'
                      : 'disabled'
                  "
                >
                  {{
                    genesConfigured[idx].rowStatus === 'E'
                      ? 'Habilitado'
                      : 'Deshabilitado'
                  }}
                </span>
              </div>
              <div class="col">
                <button
                  type="button"
                  class="material-icons"
                  @click="updateGene(genesConfigured[idx])"
                >
                  <a href="#name">view_agenda</a>
                </button>
              </div>
            </div>
          </template>
        </d-card-body>
      </d-card>
    </div>
  </form>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import VInput from '@/components/Form/VInput.vue'
import ManagementHeader from '@/components/ManagementHeader.vue'

export default {
  components: {
    VInput,
    ManagementHeader,
  },
  data() {
    return {
      editGene: false,
      nameIsAvailable: true,
      codeIsAvailable: true,
      isNotName: false,
      isNotCode: false,
    }
  },
  computed: {
    ...mapGetters('panels', [
      'genes',
      'genesEnabled',
      'panel_genes_configuration',
      'isGeneNew',
    ]),
    ...mapState('panels', ['gene', 'genes', 'genesEnabled']),
    genesConfigured() {
      return this.$store.state.panels.genes
    },
    geneName: {
      get() {
        return this.$store.state.panels.gene.name
      },
      set(name) {
        this.setGeneName(name)
      },
    },
    geneCode: {
      get() {
        return this.$store.state.panels.gene.code
      },
      set(code) {
        this.setGeneCode(code)
      },
    },
    geneRowStatus: {
      get() {
        return this.$store.state.panels.gene.rowStatus
      },
      set(rs) {
        this.setGeneRs(rs)
      },
    },
    rowStatusOptions() {
      return {
        E: ['E'],
        D: ['D'],
      }
    },
  },
  watch: {
    genesConfigured() {},
  },
  async mounted() {
    //do something after mounting vue instance
    await this.getGenes()
    await this.getPanelGenesConfiguration()
  },
  methods: {
    ...mapActions('panels', [
      'getGenes',
      'getPanelGenesConfiguration',
      'setGeneName',
      'setGeneCode',
      'errors',
      'saveGene',
      'setIdForGene',
      'setGeneRs',
    ]),
    async handleDiscard(e) {
      if (e) {
        e.preventDefault()
      }
      await this.setIdForGene(null)
      await this.setGeneName('')
      await this.setGeneCode('')
      await this.setGeneRs('E')
      this.editGene = false
      this.isNotName = false
      this.isNotCode = false
      this.nameIsAvailable = true
      this.codeIsAvailable = true
    },
    async saveThisGene(e) {
      if (e) {
        e.preventDefault()
      }
      await this.saveGene()
      await this.handleDiscard()
    },
    async updateGene(geneIdx, error) {
      if (error) {
        error.preventDefault()
      }
      await this.setIdForGene(geneIdx.id)
      await this.setGeneName(geneIdx.name)
      await this.setGeneCode(geneIdx.code)
      await this.setGeneRs(geneIdx.rowStatus)
      this.editGene = true
    },
    checkName() {
      let geneName = document.getElementsByName('name')
      if (
        geneName[0].value.length == 0 ||
        geneName[0].value.trim().length == 0
      ) {
        this.isNotName = true
      } else if (this.genes.some((gene) => gene.name === geneName[0].value)) {
        this.nameIsAvailable = false
        this.isNotName = false
      } else {
        this.nameIsAvailable = true
        this.isNotName = false
      }
    },
    checkCode() {
      let geneCode = document.getElementsByName('code')
      if (
        geneCode[0].value.length == 0 ||
        geneCode[0].value.trim().length == 0
      ) {
        this.isNotCode = true
      } else if (this.genes.some((gene) => gene.code === geneCode[0].value)) {
        this.codeIsAvailable = false
        this.isNotCode = false
      } else {
        this.codeIsAvailable = true
        this.isNotCode = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.result .header,
.result .col {
  width: 50%;
  display: inline;
  gap: 0.2px;
  grid-template-columns: 1fr;
  align-items: center;

  .form-group {
    margin: 0;
  }
}
.bibliography .header,
.bibliography .col {
  width: 100%;
  display: inline-grid;
  gap: 2px;
  grid-template-columns: 1fr;
  align-items: center;

  .form-group {
    margin: 0;
  }
}

@media screen and (min-width: 768px) {
  .result .header,
  .result .col,
  .bibliography .header,
  .bibliography .col {
    grid-template-columns: 1fr 1fr;

    > :last-child {
      grid-column: span 1;
    }
  }
}

@media screen and (min-width: 1024px) {
  .result .header,
  .result .col {
    grid-template-columns: 1fr 1fr 25px 1fr 120px;
    > :last-child {
      grid-column: span 1;
    }
  }

  .bibliography .header,
  .bibliography .col {
    grid-template-columns: 1fr 1fr 1fr 0.5fr 130px;
    > :last-child {
      grid-column: span 1;
    }
  }

  .result .col hr {
    display: none;
  }
  .bibliography .col hr {
    display: none;
  }
}

.header button {
  margin-bottom: 1.2rem;
}

select {
  height: 3.2rem !important;
}
.result button {
  transition: 15ms;
  border: none;
  background: transparent;
  outline: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);

  &:hover {
    transition: 15ms;
    background-color: rgba(0, 0, 0, 0.04);
  }
}
.enabled {
  color: green;
}
.disabled {
  color: red;
}
</style>
