import Vue from 'vue'
import Vuex from 'vuex'

import ui from './ui'
import auth from './auth'
import filters from './filters'
import studies from './studies'
import labelMappings from './labelMappings'
import countries from './countries'
import pharmas from './pharmas'
import biomarkers from './biomarkers'
import primaryTumors from './primaryTumors'
import labs from './labs'
import panels from './panels'
import results from './results'
import dbFrontendConfigurations from './dbFrontendConfigurations'
import testsGrouper from './testsGrouper'
Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    ui,
    auth,
    filters,
    labelMappings,
    studies,
    countries,
    pharmas,
    biomarkers,
    labs,
    primaryTumors,
    panels,
    results,
    dbFrontendConfigurations,
    testsGrouper,
  },
})
