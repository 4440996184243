import { validationMixin } from 'vuelidate'
import owasp from 'owasp-password-strength-test'

export default {
  mixins: [validationMixin],
  methods: {
    setSuccessMessage(msg) {
      this.$root.state.alerts.success_message = msg
      window.scrollTo(0, 0)
    },
    isIE11() {
      return !!window.MSInputMethodContext && !!document.documentMode
    },
    isPasswordStrong(password) {
      owasp.config({
        minLength: 8,
        minOptionalTestsToPass: 2,
      })
      return !!owasp.test(password).strong
    },
    getFormDataWithObject(obj) {
      const form_data = new FormData()
      const form_json = JSON.stringify(obj)
      const form_json_blob = new Blob([form_json], { type: 'application/json' })
      form_data.append('document', form_json_blob)
      return form_data
    },
    catchAjaxError(error) {
      const response = error.response
      if (response && response.data && response.data.errors)
        this.alertFormError(response.data.errors)

      if (response && response.data && response.data.exception)
        this.alertFormError(response.data.exception)

      if (!response) {
        this.alertFormError(error)
        console.log('Unhandled error')
        console.log(error)
      }
      this.setLoading(false)
    },
    alertFormError(message) {
      alert(message)
    },
    isValid() {
      this.$v.$touch()
      return !this.$v.$invalid
    },
    getid() {
      return this.$auth.user()['relationId']
    },
    isDoctor() {
      return this.$auth.user().relationType == 'doctor'
    },
    isLab() {
      return this.$auth.user().relationType == 'lab'
    },
    getFullName(obj) {
      return obj['first_name'] + ' ' + obj['last_name']
    },
    attemptLoginWithParams(params, success_callback) {
      if (params['validation_check'] === undefined)
        // skip showing the loading animation for each call
        this.setLoading(true)
      else this.setLoading(false)

      const loginData = new FormData()
      Object.keys(params).forEach((key) => loginData.append(key, params[key]))

      this.$auth.login({
        data: loginData,
        success() {
          this.$root.state.user.logged_in = true
          this.login_failed = false
          this.setLoading(false)
          if (success_callback !== undefined) success_callback()
        },
        error(error) {
          if (error.response.status == 428)
            this.login_failed_message =
              'Usuario no activado, por favor haga click en el mail de validación'
          else if (error.response.status == 408)
            this.login_failed_message =
              'Contraseña expirada por favor restablezca su contraseña'
          else this.login_failed_message = 'Usuario o contraseña incorrectos'

          this.setLoading(false)
          this.login_failed = true
        },
        rememberMe: true,
      })
    },
    setLoading(val) {
      if (this.isIE11()) return

      if (val) {
        this.$root.state.alerts.success_message =
          this.$root.state.alerts.warning_message = ''
        this.$root.loader_object = this.$loading.show({
          loader: 'spinner',
          canCancel: false,
          color: '#5436F5',
        })
      } else {
        if (this.$root.loader_object !== null) this.$root.loader_object.hide()
      }
      Object.values(this.$refs).forEach((el) => {
        if (el === undefined) return

        if (el.className && el.className.indexOf('loader-button') !== -1) {
          if (val) el.attributes['original_text'] = el.textContent
          el.textContent =
            (val && 'Espere...') || el.attributes['original_text']
          el.disabled = val
        }
      })
    },
  },
  data() {
    return {
      administrative_areas_level_1: [
        { value: null, text: 'Seleccione una provincia' },
        {
          value: 'Ciudad Autónoma de Buenos Aires',
          text: 'Ciudad Autónoma de Buenos Aires',
        },
        { value: 'Buenos Aires', text: 'Buenos Aires' },
        { value: 'Catamarca', text: 'Catamarca' },
        { value: 'Córdoba', text: 'Córdoba' },
        { value: 'Corrientes', text: 'Corrientes' },
        { value: 'Chaco', text: 'Chaco' },
        { value: 'Chubut', text: 'Chubut' },
        { value: 'Entre Ríos', text: 'Entre Ríos' },
        { value: 'Formosa', text: 'Formosa' },
        { value: 'Jujuy', text: 'Jujuy' },
        { value: 'La Pampa', text: 'La Pampa' },
        { value: 'La Rioja', text: 'La Rioja' },
        { value: 'Mendoza', text: 'Mendoza' },
        { value: 'Misiones', text: 'Misiones' },
        { value: 'Neuquén', text: 'Neuquén' },
        { value: 'Río Negro', text: 'Río Negro' },
        { value: 'Salta', text: 'Salta' },
        { value: 'San Juan', text: 'San Juan' },
        { value: 'San Luis', text: 'San Luis' },
        { value: 'Santa Cruz', text: 'Santa Cruz' },
        { value: 'Santa Fe', text: 'Santa Fe' },
        { value: 'Santiago del Estero', text: 'Santiago del Estero' },
        { value: 'Tucumán', text: 'Tucumán' },
        {
          value: 'Tierra del Fuego, Antártida e Islas del Atlántico Sur',
          text: 'Tierra del Fuego, Antártida e Islas del Atlántico Sur',
        },
      ],
      loader_object: null,
    }
  },
}
