<template>
    <d-container >
      <d-row no-gutters class="h-100" align-v="center">
        <d-col lg="8" md="10" class="auth-form mx-auto my-auto">
          <div class="my-4" />

          <d-card>
            <d-card-body border-theme="warning">
              <!-- Logo -->
              <div class="cont-logos">
                <img v-if="jansen"
                  class="mx-auto mb-3"
                  src="../assets/images/logos/jansen.png"
                  alt="Jansen"
                />
                <img
                  class="mx-auto mb-3"
                  src="../assets/images/logos/pluton-2x-color.png"
                  alt="Pluton"
                />
              </div>
              <!-- Title -->
              <h5 class="auth-form__title text-center mb-4">
                Acceda a su cuenta
              </h5>

              <div
                v-if="login_failed"
                class="alert alert-warning fade show px-2 my-40 text-center"
                role="alert"
              >
                {{ login_failed_message }}
              </div>

              <!-- Form Fields -->
              <d-form @submit="handleSubmit">
                <div class="form-group">
                  <label>Email</label>
                  <d-input
                    v-model="email"
                    type="email"
                    placeholder="Ingrese su email"
                  />
                </div>
                <div class="form-group">
                  <label>Contraseña</label>
                  <d-input
                    v-model="password"
                    type="password"
                    placeholder="Ingrese su contraseña"
                  />
                </div>
                <div class="form-group mb-3 d-table mx-auto">
                  <!-- <d-checkbox v-model="remember_me">Recu&eacute;rdame en este dispositivo</d-checkbox> -->
                </div>

                <button
                  ref="loading_button"
                  type="submit"
                  class="btn btn-pill btn-accent d-table mx-auto loader-button"
                  :disabled="!email || !password"
                >
                  Ingresar
                </button>

                <router-link
                  :to="{ name: NAMED_ROUTES.FORGOT_PASSWORD }"
                  class="d-block text-dark text-center mt-3"
                >
                  <small>Reestablecer contraseña</small>
                </router-link>
              </d-form>
            </d-card-body>
          </d-card>

          <p class="text-center mt-4" :class="jansen ? 'jansen-color':''">
            Primera vez en la Plataforma Plutón?<br />
            <router-link
              :to="{ name: NAMED_ROUTES.REGISTER }"
              tag="button"
              class="btn btn-outline-accent btn-pill mx-auto my-4 d-block"
              :class="jansen ? 'jansen-button':''"
            >
              Crear Cuenta
            </router-link>
          </p>
          <!-- Meta Details -->
          <div class="auth-form__meta d-flex mt-4" />
        </d-col>
      </d-row>
    </d-container>
</template>

<script>
import PlutonBaseMixin from '@/components/mixins/PlutonBaseMixin'

export default {
  name: 'Login',
  mixins: [PlutonBaseMixin],
  props: {
    msg: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      is_loading: false,
      email: '',
      password: '',
      remember_me: true,
      login_failed: false,
      login_failed_message: 'Usuario o contraseña incorrectos',
      jansen: false,
    }
  },
  mounted() {
    const URL_JANSEN_STG ="https://janssen.pluton-stg.biomakers.net/#/login"
    const URL_JANSEN_PROD_V1 = "https://janssen.pluton.biomakers.net/#/login"    
    const URL_JANSEN_PROD_V2 = "https://janssen.biomakers.net/#/login"
    let url = window.location.href 
    if (url === URL_JANSEN_STG || url === URL_JANSEN_PROD_V1 || url === URL_JANSEN_PROD_V2) this.jansen = true
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      return this.attemptLoginWithParams({
        username: this.email,
        password: this.password,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.loading {
  border: 2px solid #000 !important;
}

.jansen-color {
  color: whitesmoke;
}

.jansen-button {
  color: whitesmoke;
  border: whitesmoke 1px solid;
}
.jansen-button:hover {
  color: #004782;
  background-color: whitesmoke;
}
.cont-logos {
  flex-direction: row;
  text-align: center;
}
</style>
