var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('d-container',{staticClass:"h-100",attrs:{"fluid":""}},[_c('d-row',{staticClass:"h-100"},[(!_vm.printMode)?_c('div',[_c('main-sidebar',{attrs:{"items":_vm.sidebarItems}})],1):_vm._e(),_c('d-col',{staticClass:"main-content p-0",attrs:{"cols":12,"tag":"main","lg":_vm.printMode ? 12 : 10,"md":_vm.printMode ? 12 : 9,"offset-lg":_vm.printMode ? 0 : 2,"offset-md":_vm.printMode ? 0 : 3}},[(!_vm.printMode)?_c('main-navbar'):_vm._e(),(_vm.alerts.success_message)?_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"alert alert-success alert-dismissible fade show m-0",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.alerts.success_message)+" "),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"alert","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])]):_vm._e(),(_vm.alerts.warning_message)?_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"alert alert-warning alert-dismissible fade show m-0",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.alerts.warning_message)+" "),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"alert","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])]):_vm._e(),(
          [_vm.NAMED_ROUTES.STUDIES, _vm.NAMED_ROUTES.STATS].includes(_vm.$route.name)
        )?_c('aside',{class:[
          'd-md-none',
          'main-sidebar',
          'p-4',
          'col-12',
          _vm.mobileFiltersVisible ? 'open' : '' ]},[_c('study-filters')],1):_vm._e(),_c('div',{staticClass:"container-fluid main-content-container p-4"},[_c('router-view')],1),_c('zoom'),(!_vm.printMode)?_c('main-footer'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }