var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-0"},[_c('div',{staticClass:"d-none d-md-block text-center"},[_c('d-button',{staticClass:"btn-accent",on:{"click":function($event){return _vm.handleClearFilters(true)}}},[_vm._v(" Borrar filtros ")])],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Paciente")]),_c('d-form-input',{attrs:{"id":"Patient-label","name":"Patient","value":_vm.patient.name,"type":"text","placeholder":"Nombre y Apellido"},on:{"input":function($event){return _vm.throttledSearch('name', $event)}},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}}),(_vm.checkForSearching == true)?_c('div',[_vm._v("Buscando...")]):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Sexo")]),_c('d-form-select',{staticClass:"text-truncate",attrs:{"name":"gender","value":_vm.patient.gender,"options":_vm.genderOptions},on:{"change":function($event){return _vm.updatePatientFilters({ field: 'gender', value: $event })}}})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Tumor Primario")]),_c('d-form-select',{staticClass:"text-truncate",attrs:{"value":_vm.study.primaryTumor,"options":_vm.primaryTumorOptions},on:{"change":function (event) { return _vm.setPrimaryTumor({
            primaryTumorId: event,
            primaryTumorCode: _vm.currentPrimaryTumorCode,
          }); }}})],1),(_vm.currentPrimaryTumorCode === _vm.TUMOR_TYPES.COLON)?_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"location"}},[_vm._v("Localización del tumor")]),_c('d-form-select',{staticClass:"text-truncate",attrs:{"id":"location","value":_vm.patient.diagnosisLocation,"options":_vm.diagnosisLocationOptions},on:{"change":function($event){return _vm.updatePatientFilters({ field: 'diagnosisLocation', value: $event })}}})],1):_vm._e(),(_vm.currentPrimaryTumorCode === _vm.TUMOR_TYPES.BLADDER)?_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"previousTherapies"}},[_vm._v("Terapias previas")]),_c('d-form-select',{staticClass:"text-truncate",attrs:{"id":"previousTherapies","value":_vm.patient.previousTherapies,"options":_vm.previousTherapiesOptions},on:{"change":function($event){return _vm.updatePatientFilters({ field: 'previousTherapies', value: $event })}}})],1):_vm._e(),(
      [_vm.TUMOR_TYPES.LUNG, _vm.TUMOR_TYPES.BLADDER].includes(
        _vm.currentPrimaryTumorCode
      )
    )?_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"smoker_status"}},[_vm._v("Condición fumador")]),_c('d-form-select',{staticClass:"text-truncate",attrs:{"id":"smoker_status","value":_vm.patient.smokerStatus,"options":_vm.smokerStatusOptions},on:{"change":function($event){return _vm.updatePatientFilters({ field: 'smokerStatus', value: $event })}}})],1):_vm._e(),(
      [
        _vm.TUMOR_TYPES.LUNG,
        _vm.TUMOR_TYPES.GASTRIC,
        _vm.TUMOR_TYPES.ESOPHAGUS,
        _vm.TUMOR_TYPES.GASTROESOPHAGEAL ].includes(_vm.currentPrimaryTumorCode)
    )?_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"diagnosis"}},[_vm._v("Diagnóstico")]),_c('d-form-select',{staticClass:"text-truncate",attrs:{"id":"diagnosis","value":_vm.patient.diagnosis,"options":_vm.diagnosisOptions},on:{"change":function($event){return _vm.updatePatientFilters({ field: 'diagnosis', value: $event })}}})],1):_vm._e(),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Tipo de muestra")]),_c('d-form-select',{staticClass:"text-truncate",attrs:{"value":_vm.study.sampleType,"options":_vm.sampleTypeOptions},on:{"change":function($event){return _vm.updateStudyFilters({ field: 'sampleType', value: $event })}}})],1),_c('div',{staticClass:"form-group"},[_c('div',{directives:[{name:"d-toggle",rawName:"v-d-toggle.patient-diagnosis-stage-filter",modifiers:{"patient-diagnosis-stage-filter":true}}],staticClass:"d-flex justify-content-between checkbox-label"},[_c('label',{attrs:{"for":"diagnosisStage"}},[_vm._v("Estadío de la enfermedad")]),_c('span',{staticClass:"material-icons text-dark"},[_vm._v(" expand_more ")])]),_c('d-collapse',{staticClass:"checkbox-filter",attrs:{"id":"patient-diagnosis-stage-filter"}},[_c('d-checkbox',{attrs:{"checked":!this.patient.diagnosisStage.length,"disabled":!this.patient.diagnosisStage.length},on:{"change":function($event){return _vm.updatePatientFilters({ field: 'diagnosisStage', value: [] })}}},[_vm._v("Todos los estados")]),_c('d-checkbox',{attrs:{"id":"diagnosisStage","value":"explicit_null"},model:{value:(_vm.diagnosisStage),callback:function ($$v) {_vm.diagnosisStage=$$v},expression:"diagnosisStage"}},[_vm._v("No informa")]),_vm._l((Object.entries(
          _vm.patientLabels.diagnosisStage || {}
        )),function(ref){
        var key = ref[0];
        var value = ref[1];
return _c('d-checkbox',{key:key,attrs:{"name":"diagnosisStage","value":key},model:{value:(_vm.diagnosisStage),callback:function ($$v) {_vm.diagnosisStage=$$v},expression:"diagnosisStage"}},[_vm._v(_vm._s(value))])})],2)],1),_c('div',{staticClass:"form-group"},[_c('div',{directives:[{name:"d-toggle",rawName:"v-d-toggle.study-test-code-filter",modifiers:{"study-test-code-filter":true}}],staticClass:"d-flex justify-content-between checkbox-label"},[_c('label',{attrs:{"for":"studyTestCode"}},[_vm._v("Biomarcador")]),_c('span',{staticClass:"material-icons text-dark"},[_vm._v(" expand_more ")])]),_c('d-collapse',{staticClass:"checkbox-filter",attrs:{"id":"study-test-code-filter"}},[_c('d-checkbox',{attrs:{"checked":!_vm.studyTestCode.length,"disabled":!_vm.studyTestCode.length},on:{"change":function($event){return _vm.updateStudyTestFilters({ field: 'biomarkerId', value: [] })}}},[_vm._v("Todos los biomarcadores")]),_vm._l((Object.entries(
          _vm.filteredOptions(_vm.studyTestLabels, 'biomarkers')
        )),function(ref){
        var key = ref[0];
        var value = ref[1];
return _c('d-checkbox',{key:key,attrs:{"id":"studyTestCode","value":value.index},model:{value:(_vm.studyTestCode),callback:function ($$v) {_vm.studyTestCode=$$v},expression:"studyTestCode"}},[_vm._v(_vm._s(value.value))])})],2)],1),_c('div',{staticClass:"form-group"},[_c('div',{directives:[{name:"d-toggle",rawName:"v-d-toggle.study-test-panel-filter",modifiers:{"study-test-panel-filter":true}}],staticClass:"d-flex justify-content-between checkbox-label"},[_c('label',{attrs:{"for":"studyTestPanels"}},[_vm._v("Paneles")]),_c('span',{staticClass:"material-icons text-dark"},[_vm._v(" expand_more ")])]),_c('d-collapse',{staticClass:"checkbox-filter",attrs:{"id":"study-test-panel-filter"}},[_c('d-checkbox',{attrs:{"checked":!_vm.studyPanels.length,"disabled":!_vm.studyPanels.length},on:{"change":function($event){return _vm.updateStudyFilters({ field: 'umbrella', value: [] })}}},[_vm._v("Todos los paneles")]),_vm._l((Object.entries(_vm.studyLabels.umbrellas || {})),function(ref){
        var value = ref[0];
        var key = ref[1];
return _c('d-checkbox',{key:key,attrs:{"id":"studyTestPanels","value":value},model:{value:(_vm.studyPanels),callback:function ($$v) {_vm.studyPanels=$$v},expression:"studyPanels"}},[_vm._v(_vm._s(key))])})],2)],1),_c('div',{staticClass:"form-group"},[_c('div',{directives:[{name:"d-toggle",rawName:"v-d-toggle.study-test-result-filter",modifiers:{"study-test-result-filter":true}}],staticClass:"d-flex justify-content-between checkbox-label"},[_c('label',{attrs:{"for":"studyTestResult"}},[_vm._v("Resultado")]),_c('span',{staticClass:"material-icons text-dark"},[_vm._v(" expand_more ")])]),_c('d-collapse',{staticClass:"checkbox-filter",attrs:{"id":"study-test-result-filter"}},[_c('d-checkbox',{attrs:{"checked":!(_vm.studyTestResult && _vm.studyTestResult.length),"disabled":!(_vm.studyTestResult && _vm.studyTestResult.length)},on:{"change":function($event){return _vm.updateStudyTestFilters({ field: 'result', value: [] })}}},[_vm._v("Todos los resultados")]),_c('d-checkbox',{attrs:{"id":"studyTestResult","value":null},model:{value:(_vm.studyTestResult),callback:function ($$v) {_vm.studyTestResult=$$v},expression:"studyTestResult"}},[_vm._v("No informa")]),_vm._l((Object.entries(
          _vm.resultsFilteredOptions(_vm.studyTestLabels, 'result')
        )),function(ref){
        var key = ref[0];
        var value = ref[1];
return _c('d-checkbox',{key:key,attrs:{"name":"studyTestResult","value":value.value},model:{value:(_vm.studyTestResult),callback:function ($$v) {_vm.studyTestResult=$$v},expression:"studyTestResult"}},[_vm._v(_vm._s(value.index)+" ")])})],2)],1),_c('div',{staticClass:"form-group"},[_c('div',{directives:[{name:"d-toggle",rawName:"v-d-toggle.study-status-filter",modifiers:{"study-status-filter":true}}],staticClass:"d-flex justify-content-between checkbox-label"},[_c('label',{attrs:{"for":"studyStatus"}},[_vm._v("Estado")]),_c('span',{staticClass:"material-icons text-dark"},[_vm._v(" expand_more ")])]),_c('d-collapse',{staticClass:"checkbox-filter",attrs:{"id":"study-status-filter"}},[_c('d-checkbox',{attrs:{"checked":!_vm.studyStatus.length,"disabled":!_vm.studyStatus.length},on:{"change":function($event){return _vm.updateStudyFilters({ field: 'status', value: [] })}}},[_vm._v("Todos los estados")]),_vm._l((Object.entries(_vm.studyLabels.status || {})),function(ref){
        var key = ref[0];
        var value = ref[1];
return _c('d-checkbox',{key:key,attrs:{"id":"studyStatus","value":key},model:{value:(_vm.studyStatus),callback:function ($$v) {_vm.studyStatus=$$v},expression:"studyStatus"}},[_vm._v(_vm._s(value))])})],2)],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Fecha de Solicitud")]),_c('d-row',[_c('d-col',{staticClass:"pr-1 created-at-from",attrs:{"cols":"6"}},[_c('d-datepicker',{attrs:{"typeable":"","value":_vm.study.createdAtFrom,"placeholder":"Desde (YYYY-MM-DD)","language":_vm.calendarLang,"format":"yyyy-MM-dd","disabled-dates":_vm.disabledDates,"use-utc":true},on:{"selected":function($event){_vm.updateStudyFilters({
              field: 'createdAtFrom',
              value: ($event && $event.toISOString().split('T')[0]) || null,
            })}}})],1),_c('d-col',{staticClass:"pl-1 created-at-to",attrs:{"cols":"6"}},[_c('d-datepicker',{attrs:{"typeable":"","value":_vm.study.createdAtTo,"language":_vm.calendarLang,"placeholder":"Hasta (YYYY-MM-DD)","format":"yyyy-MM-dd","disabled-dates":_vm.disabledDates,"use-utc":true},on:{"selected":function($event){_vm.updateStudyFilters({
              field: 'createdAtTo',
              value: ($event && $event.toISOString().split('T')[0]) || null,
            })}}})],1)],1)],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Edad del paciente")]),_c('d-row',[_c('d-col',{staticClass:"pr-1",attrs:{"cols":"6"}},[_c('d-form-input',{attrs:{"value":_vm.patient.ageRangeStart,"min":"1","type":"number","placeholder":"Desde"},on:{"input":function($event){return _vm.throttledSearch('ageRangeStart', $event)}},model:{value:(_vm.desdeValue),callback:function ($$v) {_vm.desdeValue=$$v},expression:"desdeValue"}})],1),_c('d-col',{staticClass:"pl-1",attrs:{"cols":"6"}},[_c('d-form-input',{attrs:{"value":_vm.patient.ageRangeEnd,"type":"number","placeholder":"Hasta"},on:{"input":function($event){return _vm.throttledSearch('ageRangeEnd', $event)}},model:{value:(_vm.hastaValue),callback:function ($$v) {_vm.hastaValue=$$v},expression:"hastaValue"}})],1)],1)],1),_c('d-row',{staticClass:"d-flex d-md-none"},[_c('d-col',{attrs:{"cols":"6"}},[_c('d-button',{staticClass:"btn-accent",attrs:{"block-level":""},on:{"click":function () { return _vm.$eventHub.$emit('toggle-study-filters-sidebar'); }}},[_vm._v(" Filtrar ")])],1),_c('d-col',{attrs:{"cols":"6"}},[_c('d-button',{attrs:{"block-level":"","theme":"secondary"},on:{"click":function($event){return _vm.handleClearFilters(true)}}},[_vm._v(" Borrar Filtros ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }