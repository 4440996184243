<template>
  <form @submit="saveThisResult">
    <div>
      <h1 id="editResult">Resultados</h1>
      <d-card class="mb-4 result">
        <d-card-body>
          <h5>Agregar Resultado</h5>
          <div class="header">
            <div class="row">
              <div class="col">
                <v-input
                  id="code"
                  v-model="resultCode"
                  :error="errors.code"
                  name="code"
                  placeholder="Código del resultado"
                  help="Código identificador en CSVs y Dirmod"
                  @input="checkCode"
                />
                <p v-if="!codeIsAvailable && !editResult" style="color: red">
                  Este código de resultado ya existe
                </p>
                <p v-if="isNotCode && !editResult" style="color: red">
                  Escriba un código de resultado válido
                </p>
              </div>
              <div class="col">
                <v-input
                  v-model="resultName"
                  name="resultName"
                  placeholder="Nombre del resultado"
                />
              </div>
              <div class="col">
                <div class="form-group">
                  <d-form-select
                    v-model="resultStatisticsGrouper"
                    :options="statisticsGrouperOptions"
                  >
                    <option :value="null" disabled>
                      Seleccione un agrupador
                    </option>
                  </d-form-select>
                  <small class="form-text text-muted">
                    Agrupador para el cálculo de las estadísticas
                  </small>
                </div>
              </div>
              <div class="col">
                <v-color-picker v-model="resultColor" :options="colorOptions" />
              </div>
            </div>

            <d-form-select
              v-model="resultRowStatus"
              :options="rowStatusOptions"
              :disabled="isNew"
            >
              <option value="null" disabled>Seleccione un estado</option>
            </d-form-select>
            <small :class="result.rowStatus === 'E' ? 'enabled' : 'disabled'">{{
              result.rowStatus === 'E' ? 'E: Habilitado' : 'D: Deshabilitado'
            }}</small>
            <hr />
            <button
              href="#/management/results"
              type="submit"
              class="btn btn-pill btn-accent text-white"
              :disabled="
                !editResult &&
                (!codeIsAvailable || !codeIsAvailable || isNotCode)
              "
            >
              {{
                editResult === true ? 'Editar Resultado' : 'Agregar Resultado'
              }}
            </button>
            <button
              type="button"
              class="btn btn-pill btn-secondary"
              @click="handleDiscard"
            >
              Descartar
            </button>
          </div>
          <hr />
          <h5>Resultados configurados</h5>
          <div class="row">
            <div class="col">
              <h6>Id</h6>
            </div>
            <div class="col">
              <h6>Código</h6>
            </div>
            <div class="col">
              <h6>Nombre</h6>
            </div>
            <div class="col">
              <h6>Agrupador</h6>
            </div>
            <div class="col">
              <h6>Color</h6>
            </div>
            <div class="col">
              <h6>Estado</h6>
            </div>
            <div class="col">
              <h6>Editar</h6>
            </div>
          </div>
          <template v-for="(result, idx) in resultsConfigured">
            <div :key="idx" class="row">
              <div class="col">
                <h6>{{ result.id }}</h6>
              </div>
              <div class="col">
                <textarea
                  v-model="result.code"
                  disabled
                  style="
                    resize: none;
                    background-color: white;
                    border-color: white;
                  "
                ></textarea>
              </div>
              <div class="col">
                <textarea
                  v-model="result.name"
                  disabled
                  style="
                    resize: none;
                    background-color: white;
                    border-color: white;
                  "
                ></textarea>
              </div>
              <div class="col">
                <textarea
                  v-model="result.statisticsGrouper"
                  disabled
                  style="
                    resize: none;
                    background-color: white;
                    border-color: white;
                  "
                ></textarea>
              </div>
              <div class="col">
                <v-color-picker
                  v-model="result.color"
                  :options="colorOptions"
                  disabled="true"
                />
              </div>
              <div class="col">
                <span
                  :class="result.rowStatus === 'E' ? 'enabled' : 'disabled'"
                  >{{ result.rowStatus }}</span
                >
              </div>
              <div class="col">
                <a
                  href="#editResult"
                  type="button"
                  class="
                    material-icons
                    btn-accent
                    text-white text-center
                    sub-button
                  "
                  style="border-radius: 5px"
                  @click="updateResult(result)"
                >
                  view_agenda
                </a>
              </div>
              <hr :key="idx" />
            </div>
          </template>
        </d-card-body>
      </d-card>
    </div>
  </form>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'

import VInput from '@/components/Form/VInput.vue'
import VColorPicker from '@/components/Form/VColorPicker.vue'
export default {
  components: {
    VInput,
    VColorPicker,
    // ManagementHeader,
  },
  data() {
    return {
      nameIsAvailable: true,
      codeIsAvailable: true,
      isNotCode: false,
      editResult: false,
      colorOptions: [
        { label: 'positivo', color: '#FF83A5' },
        { label: 'negativo', color: '#2AD8A0' },
        { label: 'estudio no completo', color: '#849DFF' },
        { label: 'muestra insuficiente', color: '#999999' },
      ],
    }
  },
  computed: {
    ...mapGetters('results', [
      'result',
      'results',
      'errors',
      'hasErrors',
      'isNew',
    ]),
    ...mapState('labelMappings', ['studyTestLabels']),
    ...mapState('results', ['results']),

    resultsConfigured() {
      return this.$store.state.results.results
    },
    resultCode: {
      get() {
        return this.$store.state.results.result.code
      },
      set(code) {
        this.setCode(code)
      },
    },
    resultName: {
      get() {
        return this.$store.state.results.result.name
      },
      set(name) {
        this.setName(name)
      },
    },
    resultColor: {
      get() {
        return this.$store.state.results.result.color
      },
      set(color) {
        this.setColor(color)
      },
    },
    resultStatisticsGrouper: {
      get() {
        return this.$store.state.results.result.statisticsGrouper
      },
      set(statisticsGrouper) {
        this.setResultStatisticsGrouper(statisticsGrouper)
      },
    },
    statisticsGrouperOptions() {
      if (!('statisticsGrouper' in this.studyTestLabels)) return []
      return Object.entries(this.studyTestLabels.statisticsGrouper).map(
        ([value, text]) => ({ value, text })
      )
    },
    resultRowStatus: {
      get() {
        return this.result.rowStatus
      },
      set(rs) {
        this.setRowStatus(rs)
      },
    },
    rowStatusOptions() {
      return {
        E: ['E'],
        D: ['D'],
      }
    },
  },
  async mounted() {
    //do something after mounting vue instance
    await this.getResults()
    await this.fetchStudyTestLabels()
  },
  methods: {
    ...mapActions('results', [
      'setCode',
      'getResults',
      'setName',
      'setColor',
      'setResultStatisticsGrouper',
      'setResult',
      'setRowStatus',
      'saveResults',
      'setIdForResult',
    ]),
    ...mapActions('labelMappings', ['fetchStudyTestLabels']),
    async updateResult(resultIdx, error) {
      if (error) {
        error.preventDefault()
      }
      await this.setIdForResult(resultIdx.id)
      await this.setName(resultIdx.name)
      await this.setCode(resultIdx.code)
      await this.setResultStatisticsGrouper(resultIdx.statisticsGrouper)
      await this.setColor(resultIdx.color)
      await this.setRowStatus(resultIdx.rowStatus)
      this.editResult = true
    },
    async handleDiscard(e) {
      if (e) {
        e.preventDefault()
      }
      await this.setIdForResult(null)
      await this.setName('')
      await this.setCode('')
      await this.setResultStatisticsGrouper('')
      await this.setColor('#999999')
      await this.setRowStatus('E')
      this.editResult = false
      this.isNotCode = false
      this.codeIsAvailable = true
    },
    async saveThisResult(e) {
      if (e) {
        e.preventDefault()
      }
      await this.saveResults()
      await this.handleDiscard()
    },
    checkCode() {
      let code = document.getElementsByName('code')
      if (code[0].value.length == 0 || code[0].value.trim().length == 0) {
        this.isNotCode = true
      } else if (this.results.some((r) => r.code === code[0].value)) {
        this.codeIsAvailable = false
        this.isNotCode = false
      } else {
        this.codeIsAvailable = true
        this.isNotCode = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.enabled {
  color: green;
}
.disabled {
  color: red;
}
.sub-button {
  padding-top: 3px;
  padding-left: 1px;
  height: 2rem;
  width: 3rem;
}
</style>
