<template>
  <header class="studies-header">
    <nav>
      <router-link :to="{ name: NAMED_ROUTES.STATS }" class="btn">
        <i class="material-icons-outlined">pie_chart</i>
        Estadísticas
      </router-link>
      <router-link :to="{ name: NAMED_ROUTES.STUDIES }" class="btn">
        <i class="material-icons-outlined">view_agenda</i>
        Listado de estudios
      </router-link>
      <d-modal v-if="showModal">
        <d-modal-title>
          <hr style="visibility: hidden" />
          <h1 class="text-center">
            Advertencia!
            <span class="material-icons-outlined" style="font-size: 40px">
              warning
            </span>
          </h1>
        </d-modal-title>
        <d-modal-body>
          <p>
            No se pueden descargar tantos estudios. Por favor, apliqué algún
            filtro
          </p>
          <button
            type="button"
            class="btn btn-secondary"
            @click="() => (showModal = false)"
          >
            <span>Cerrar</span>
          </button>
        </d-modal-body>
      </d-modal>
    </nav>
    <div class="actions">
      <d-button
        block-level
        class="btn-accent d-md-none"
        @click="handleToggleMobileFilters"
      >
        Filtros
      </d-button>
      <div class="inline">
        <d-button
          theme="light"
          block-level
          :disabled="isLoadingCsv == true"
          @click="downloadCSV"
        >
          Descargar CSV
        </d-button>
        <i
          id="encoding-helper-tooltip"
          class="material-icons"
          style="cursor: pointer"
          >help_outline</i
        >
        <d-tooltip target="encoding-helper-tooltip" container=".tooltip">
          Para un lectura correcta del csv, el encoding de tu office tiene que
          ser utf-8
        </d-tooltip>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'StudyListHeader',
  data() {
    return {
      isLoadingCsv: false,
      showModal: false,
    }
  },
  computed: {
    ...mapState('filters', {
      filters: (state) => state,
    }),
  },
  watch: {
    isLoadingCsv(val) {
      if (val == true) {
        this.$root.loader_object = this.$loading.show({
          loader: 'dots',
          canCancel: false,
          color: '#5436F5',
        })
      } else {
        if (this.$root.loader_object !== null) this.$root.loader_object.hide()
      }
    },
  },
  methods: {
    handleToggleMobileFilters() {
      this.$eventHub.$emit('toggle-study-filters-sidebar')
    },
    async downloadCSV() {
      this.isLoadingCsv = true
      const response = await this.axios
        .put(
          '/api2/study/download_csv',
          { ...this.filters },
          { responseType: 'blob' }
        )
        .catch(() => {
          this.showModal = true
          this.isLoadingCsv = false
          return null
        })
      if (response != null) {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        )
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', 'estudios.csv')
        document.body.appendChild(link)
        link.click()
        link.remove()
        this.isLoadingCsv = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
.studies-header {
  display: flex;
  padding: 12px 54px;
  gap: 1rem;
  flex-basis: 50%;
  justify-content: space-between;
  align-items: center;
  margin: -1.5rem -1.5rem 24px -1.5rem;
  box-shadow: var(--shadow-md);
  background-color: white;

  @include phone-only {
    padding: 12px;
    flex-direction: column;
  }
}

.studies-header > * {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
.studies-header nav .btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: bold;

  &.router-link-active {
    background-color: var(--dim-05);
  }
}

@include phone-only {
  .studies-header nav {
    justify-content: center;
    & > * {
      min-width: 40%;
    }
  }
  .studies-header .actions {
    flex-direction: column;
  }
}

.inline {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.tooltip[role='tooltip'] {
  font-size: 0.85rem;
}
</style>
