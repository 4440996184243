<template>
  <div class="picker">
    <span
      class="dot"
      :style="{ backgroundColor: value }"
      @click="() => isOpen = !isOpen"
    />
    <div
      class="options"
      :class="{ isOpen }"
    >
      <span
        v-for="opt in options"
        :key="opt.id"
        class="dot"
        :style="{ backgroundColor: opt.color }"
        @click="() => handleClick(opt.color)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
      validator: options => {
        const neededKeys = ['label', 'color']
        const validItems = options
          .filter(opt => neededKeys.every(key => Object.keys(opt).includes(key)))

        return validItems.length === options.length
      }
    },
    value: {
      type: String,
      default: '#999'
    },
  },
  data() {
    return {
      isOpen: false
    }
  },
  created() {
    window.addEventListener('click', (event) => {
      event.stopPropagation()

      if (!(this.$el == event.target || this.$el.contains(event.target))) {
        this.isOpen = false
      }
    }, false)
  },
  methods: {
    handleClick (color) {
      this.$emit('change', color)
      this.$emit('input', color)
      this.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .dot {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
  }

  .picker {
    position: relative;
    height: 25px;
    width: 25px;
  }

  .options {
    display: none;
    position: absolute;
    right: calc(-50% - 52px);
    bottom: -55px;
    padding: 0.5rem;
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    z-index: 100;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 100%;
      width: 0;
      height: 0;
    }
    &::before {
      right: calc(50% - 9px);
      border: 11px solid transparent;
      border-bottom-color: rgba(0, 0, 0, 0.5);
    }
    &::after {
      right: calc(50% - 8px);
      border: 10px solid transparent;
      border-bottom-color: #fff;
    }

    &.isOpen {
      display: inline-flex;
    }

    & > * {
      margin: 0 0.25rem;
    }
  }
</style>
