import Vue from 'vue'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    user: { notifications: [], notificationsReaded: false },
    errors: {},
    status: '',
    dirty: false,
  },
  getters: {
    user() {
      return Vue.auth.user()
    },
    role() {
      const roleKey = Vue.auth.options.rolesVar
      return Vue.auth.user()[roleKey]
    },
    isLoggedIn() {
      return Vue.auth.check()
    },
  },
  actions: {
    async reloadNotificationsFromUser({ commit }, userId) {
      commit('REQUEST_INIT')
      try {
        let data = await axios.get(`/api2/notifications/?user_id=${userId}`)
          .data
        commit('reloadingUserData', data)
        commit('REQUEST_SUCCESS')
      } catch (error) {
        commit('REQUEST_ERROR', error)
      }
    },
    readAllNotifications({ commit }) {
      commit('READ_ALL_NOTIFICATIONS')
    },
  },
  mutations: {
    REQUEST_INIT(state) {
      state.status = 'loading'
    },
    REQUEST_SUCCESS(state) {
      state.status = 'success'
      state.errors = {}
      state.dirty = false
    },
    REQUEST_ERROR(state, message) {
      state.status = 'error'
      state.errors = message
    },
    reloadingUserData(state, data) {
      state.user.notifications = data
    },
    READ_ALL_NOTIFICATIONS(state) {
      state.user.notificationsReaded = true
    },
  },
}
