import axios from 'axios'
//
// import { parse_pydantic_errors } from '@/utils'
// import { ROW_STATUS } from '@/utils/constants'

const getEmptyResult = () => ({
  id: null,
  name: '',
  code: '',
  color: '#999999',
  statisticsGrouper: null,
  rowStatus: 'E',
})

const getDefaultState = () => ({
  status: null,
  errors: {},
  dirty: false,
  result: getEmptyResult(),
  results: [],
})

const getters = {
  result: (state) => state.result,
  results: (state) => state.results,
  hasErrors: (state) => Object.entries(state.errors).length > 0,
  errors: (state) => state.errors,
  dirty: (state) => state.dirty,
  isNew: (state) =>
    !(
      state.result.id && state.results.find((r) => r.code === state.result.code)
    ),
}

const actions = {
  async getResults({ commit, state }) {
    if (state.status === 'loading') return
    commit('REQUEST_INIT')
    try {
      const results = (await axios.get('/api2/study_test/results')).data
      commit('SET_RESULTS', results)
      commit('REQUEST_SUCCESS')
    } catch (e) {
      commit('REQUEST_ERROR', e)
    }
  },
  async saveResults({ commit, state, getters }) {
    const method = getters.isNew ? 'post' : 'put'
    const url =
      method === 'post'
        ? '/api2/study_test/results'
        : `/api2/study_test/results/${state.result.id}`
    commit('REQUEST_INIT')
    try {
      const result = (await axios({ method, url, data: { ...state.result } }))
        .data
      commit('REQUEST_SUCCESS')
      const results = [...state.results]
      if (method === 'post') {
        results.push(result)
      } else {
        const index = results.findIndex((r) => r.id === result.id)
        results[index] = result
      }
      commit('SET_RESULTS', results)
      commit('SET_SINGLE_RESULT', getEmptyResult())
    } catch (e) {
      commit('REQUEST_ERROR', e)
    }
  },
  setCode({ commit }, code) {
    commit('SET_CODE', code)
  },
  setName({ commit }, name) {
    commit('SET_NAME', name)
  },
  setColor({ commit }, color) {
    commit('SET_COLOR', color)
  },
  setResultStatisticsGrouper({ commit }, statisticsGrouper) {
    commit('SET_STATISTICS_GROUPER', statisticsGrouper)
  },
  setResult({ commit }, payload) {
    commit('SET_RESULT', payload)
  },
  setRowStatus({ commit }, rs) {
    commit('SET_ROW_STATUS', rs)
  },
  setIdForResult({ commit }, id) {
    commit('SET_RESULT_ID', id)
  },
}

const mutations = {
  REQUEST_INIT(state) {
    state.status = 'loading'
  },
  REQUEST_SUCCESS(state) {
    state.status = 'success'
    state.errors = {}
    state.dirty = false
  },
  REQUEST_ERROR(state, message) {
    state.status = 'error'
    state.errors = message
  },
  SET_CODE(state, code) {
    state.dirty = true
    state.result.code = code
  },
  SET_NAME(state, name) {
    state.dirty = true
    state.result.name = name
  },
  SET_COLOR(state, color) {
    state.dirty = true
    state.result.color = color
  },
  SET_STATISTICS_GROUPER(state, statisticsGrouper) {
    state.dirty = true
    state.result.statisticsGrouper = statisticsGrouper
  },
  SET_SINGLE_RESULT(state, result) {
    state.dirty = true
    state.result = result
  },
  SET_RESULT(state, { index, value, key }) {
    state.dirty = true
    const results = [...state.results]
    results[index][key] = value
    state.results = results
  },
  SET_RESULTS(state, results) {
    state.results = results
  },
  SET_ROW_STATUS(state, rs) {
    state.result.rowStatus = rs
  },
  SET_RESULT_ID(state, id) {
    state.dirty = true
    state.result.id = id
  },
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  actions,
  mutations,
}
