import axios from 'axios'

import { parse_pydantic_errors } from '@/utils'
import { ROW_STATUS } from '@/utils/constants'

const getEmptyBibliography = () => ({
  identifiers: '',
  authors: '',
  title: '',
  url: '',
  primaryTumorId: '',
  rowStatus: ROW_STATUS.ENABLED,
})

const getEmptyPrimaryTumor = () => ({
  name: '',
  hasSetIcon: false,
  code: '',
  icon: '',
  bibliographies: [],
})

const getDefaultState = () => ({
  status: null,
  errors: {},
  dirty: false,
  primaryTumors: [],
  bibliography: getEmptyBibliography(),
  primaryTumor: getEmptyPrimaryTumor(),
})

const getters = {
  bibliography: (state) => state.bibliography,
  bibliographies: (state) => state.primaryTumor.bibliographies,
  primaryTumors: (state) => state.primaryTumors,
  primaryTumorsEnabled: (state) =>
    state.primaryTumors.filter(
      (tumor) => tumor.rowStatus === ROW_STATUS.ENABLED
    ),

  primaryTumor: (state) => state.primaryTumor,
  isDirty: (state) => state.dirty,
  isValid: (state) =>
    !!state.primaryTumor.name &&
    !!state.primaryTumor.code &&
    (state.primaryTumor.hasSetIcon || !!state.primaryTumor.icon),
  isNew: (state) =>
    !(
      state.primaryTumor.id &&
      state.primaryTumors.find((tumor) => tumor.id === state.primaryTumor.id)
    ),
  hasErrors: (state) => Object.entries(state.errors).length > 0,
  errors: (state) => state.errors,
}

const actions = {
  async getPrimaryTumors({ commit, state }) {
    if (state.status === 'loading') return
    commit('REQUEST_INIT')
    try {
      const primaryTumors = (await axios.get('/api2/biomarker/primary_tumor'))
        .data
      commit('SET_PRIMARY_TUMORS', primaryTumors)
      commit('REQUEST_SUCCESS')
    } catch (err) {
      commit('REQUEST_ERROR', err)
    }
  },
  async savePrimaryTumor({ commit, state, getters }, event) {
    if (!getters.isDirty) return
    const method = getters.isNew ? 'post' : 'put'
    const url =
      method === 'post'
        ? '/api2/biomarker/primary_tumor'
        : `/api2/biomarker/primary_tumor/${state.primaryTumor.id}`

    commit('REQUEST_INIT')
    try {
      const tumor = (
        await axios({
          method,
          url,
          data: state.primaryTumor,
        })
      ).data
      if (event) {
        const iconFile = event.target.elements.namedItem('icon')
        if (iconFile && !!iconFile.files[0]) {
          const data = new FormData()
          data.append('icon', iconFile.files[0])
          await axios({
            method: 'post',
            url: `/api2/biomarker/primary_tumor/${tumor.id}/icon`,
            data,
          })
        }
      }
      const primaryTumors = (await axios.get('/api2/biomarker/primary_tumor'))
        .data
      commit('SET_PRIMARY_TUMORS', primaryTumors)
      commit('REQUEST_SUCCESS')
    } catch (err) {
      commit('REQUEST_ERROR', parse_pydantic_errors(err.response.data))
    }
  },
  setPrimaryTumorName({ commit }, name) {
    commit('SET_PRIMARY_TUMOR_NAME', name)
  },
  setPrimaryTumorCode({ commit }, code) {
    commit('SET_PRIMARY_TUMOR_CODE', code)
  },
  hasSetPrimaryTumorIcon({ commit }) {
    commit('HAS_SET_PRIMARY_TUMOR_ICON')
  },
  setPrimaryTumorBibliography({ commit }, payload) {
    commit('SET_PRIMARY_TUMOR_BIBLIOGRAPHY', payload)
  },
  setBibliographyIdentifiers({ commit }, identifiers) {
    commit('SET_BIBLIOGRAPHY_IDENTIFIERS', identifiers)
  },
  setBibliographyAuthors({ commit }, authors) {
    commit('SET_BIBLIOGRAPHY_AUTHORS', authors)
  },
  setBibliographyTitle({ commit }, title) {
    commit('SET_BIBLIOGRAPHY_TITLE', title)
  },
  setBibliographyUrl({ commit }, url) {
    commit('SET_BIBLIOGRAPHY_URL', url)
  },
  addBibliography({ commit, state }) {
    commit('ADD_BIBLIOGRAPHY', state.bibliography)
    commit('SET_BIBLIOGRAPHY', getEmptyBibliography())
  },
  setPrimaryTumorRowStatus({ commit }, value) {
    commit('SET_PRIMARY_TUMOR_ROW_STATUS', value)
  },
  setPrimaryTumor({ commit, state }, primaryTumorId) {
    const tumor = state.primaryTumors.find(
      (tumor) => tumor.id === primaryTumorId
    )
    if (tumor) commit('SET_PRIMARY_TUMOR', tumor)
    else commit('SET_PRIMARY_TUMOR', getEmptyPrimaryTumor())
  },
}

const mutations = {
  REQUEST_INIT(state) {
    state.status = 'loading'
  },
  REQUEST_SUCCESS(state) {
    state.status = 'success'
    state.errors = {}
    state.dirty = false
  },
  REQUEST_ERROR(state, message) {
    state.status = 'error'
    state.errors = message
  },
  CLEAR_ERRORS(state) {
    state.errors = {}
  },
  SET_PRIMARY_TUMORS(state, primaryTumors) {
    state.primaryTumors = primaryTumors
  },
  SET_PRIMARY_TUMOR_NAME(state, name) {
    state.dirty = true
    state.primaryTumor.name = name
  },
  SET_PRIMARY_TUMOR_CODE(state, code) {
    state.dirty = true
    state.primaryTumor.code = code
  },
  SET_PRIMARY_TUMOR_BIBLIOGRAPHY(state, { index, value, key }) {
    state.dirty = true
    const bibliographies = [...state.primaryTumor.bibliographies]
    bibliographies[index][key] = value
    state.primaryTumor.bibliographies = bibliographies
  },
  ADD_BIBLIOGRAPHY(state, bibliography) {
    state.dirty = true
    state.primaryTumor.bibliographies = [
      {
        ...bibliography,
        primaryTumorId: state.primaryTumor.id,
      },
      ...state.primaryTumor.bibliographies,
    ]
  },
  SET_BIBLIOGRAPHY(state, bibliography) {
    state.bibliography = { ...bibliography }
  },
  SET_BIBLIOGRAPHY_IDENTIFIERS(state, identifiers) {
    state.dirty = true
    state.bibliography.identifiers = identifiers
  },
  SET_BIBLIOGRAPHY_AUTHORS(state, authors) {
    state.dirty = true
    state.bibliography.authors = authors
  },
  SET_BIBLIOGRAPHY_TITLE(state, title) {
    state.dirty = true
    state.bibliography.title = title
  },
  SET_BIBLIOGRAPHY_URL(state, url) {
    state.dirty = true
    state.bibliography.url = url
  },
  SET_PRIMARY_TUMOR_ROW_STATUS(state, value) {
    state.dirty = true
    state.primaryTumor.rowStatus = value
  },
  SET_PRIMARY_TUMOR(state, tumor) {
    // deep copy data without memory refs
    state.primaryTumor = JSON.parse(JSON.stringify(tumor))
  },
  HAS_SET_PRIMARY_TUMOR_ICON(state) {
    state.primaryTumor.hasSetIcon = true
    state.dirty = true
  },
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  actions,
  mutations,
}
