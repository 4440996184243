<template>
  <div :class="jansen ? 'bkg-jansen': ''">
    <router-view />
    <zoom />
  </div>
</template>

<script>
import Zoom from '@/components/layout/Zoom.vue'

export default {
   data() {
    return {
      jansen: false,
    }
  },
  components:{
    Zoom,
  },
  mounted() {
    const URL_JANSEN_STG ="https://janssen.pluton-stg.biomakers.net/#/login"
    const URL_JANSEN_PROD_V1 = "https://janssen.pluton.biomakers.net/#/login"    
    const URL_JANSEN_PROD_V2 = "https://janssen.biomakers.net/#/login"
    let url = window.location.href
    if (url === URL_JANSEN_STG || url === URL_JANSEN_PROD_V1 || url === URL_JANSEN_PROD_V2) this.jansen = true
  },
}
</script>

<style scoped lang="scss">
  .bkg-jansen {
    background-color: #004782;
    height: 100vh;
    padding-top: 5%;
  }

</style>