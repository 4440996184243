import axios from 'axios'

const getEmptyLab = () => ({
  code: '',
  name: '',
  countryId: null,
})

const getEmptyNewUser = () => ({
  email: '',
  firstName: '',
  lastName: '',
  relationType: 'lab',
})

const getNewAditionalEmail = () => ({
  emailAdress: '',
  labId: null,
  id: null,
  idx: null,
})

const getDefaultState = () => ({
  errors: {},
  dirty: false,
  labs: [],
  lab: getEmptyLab(),
  newUser: getEmptyNewUser(),
  newAditionalEmails: getNewAditionalEmail(),
})

const isNewMethod = (state) =>
  !state.lab.id && !state.labs.map((lab) => lab.id).includes(state.lab.id)

const getters = {
  labs: (state) => state.labs,
  newAditionalEmails: (state) => state.newAditionalEmails,
  isDirty: (state) => state.dirty,
  isValid: (state) =>
    !Object.values(state.lab).includes('') &&
    !Object.values(state.lab).includes(null) &&
    !(isNewMethod(state) && Object.values(state.newUser).includes('')),
  isNew: isNewMethod,
  isEditingAnEmail: (state) => state.newAditionalEmails.id != null,
  hasErrors: (state) => Object.entries(state.errors).length > 0,
  errors: (state) => state.errors,
}

const actions = {
  async getLabs({ commit, state }) {
    if (state.status === 'loading') return
    commit('REQUEST_INIT')
    try {
      const labs = (await axios.get('/api2/user/lab/')).data
      commit('SET_LABS', labs)
      commit('REQUEST_SUCCESS')
    } catch (err) {
      commit('REQUEST_ERROR', err)
    }
  },
  async saveLab({ commit, state, getters }) {
    if (!getters.isDirty) return
    const method = getters.isNew ? 'post' : 'put'
    const url =
      method === 'post' ? '/api2/user/lab/' : `/api2/user/lab/${state.lab.id}`
    const data =
      method === 'post'
        ? { lab: { ...state.lab }, user: { ...state.newUser } }
        : { ...state.lab }

    commit('REQUEST_INIT')

    const lab_ask = await axios({ method, url, data })

    const lab = lab_ask.data
    commit('REQUEST_SUCCESS')
    const labs = [...state.labs]

    if (method === 'post') {
      labs.push(lab)
    } else {
      const index = labs.findIndex((c) => c.id === lab.id)
      labs[index] = lab
    }
    commit('SET_LABS', labs)
  },
  setLab({ commit, state }, labId) {
    const lab = state.labs.find((lab) => lab.id === labId)
    if (lab) commit('SET_LAB', { ...lab })
    else {
      commit('SET_LAB', getEmptyLab())
      commit('SET_NEW_USER', getEmptyNewUser())
    }
  },
  async cancelLastChanges({ commit, state }, labId) {
    commit('REQUEST_INIT')
    try {
      const labs = (await axios.get('/api2/user/lab/')).data
      commit('SET_LABS', labs)
      const lab = state.labs.find((lab) => lab.id === labId)
      if (lab) commit('SET_LAB', { ...lab })
      commit('REQUEST_SUCCESS')
    } catch (err) {
      commit('REQUEST_ERROR', err)
    }
  },
  addNewEmail({ commit }) {
    commit('ADD_NEW_EMAIL')
  },
  editEmail({ commit }, email) {
    commit('EDIT_EMAIL', email)
  },
  deleteEmail({ commit }, emailAddress) {
    commit('DELETE_EMAIL', emailAddress)
  },
  setEmail({ commit }, email) {
    commit('SET_EMAIL', email)
  },
  resetEmail({ commit }) {
    commit('RESET_NEW_ADITIONAL_EMAILS')
  },
  setLabCode({ commit }, code) {
    commit('SET_LAB_CODE', code)
  },
  setLabName({ commit }, name) {
    commit('SET_LAB_NAME', name)
  },
  setLabCountryId({ commit }, countryId) {
    commit('SET_LAB_COUNTRY_ID', countryId)
  },
  setNewUserEmail({ commit }, email) {
    commit('SET_NEW_USER_EMAIL', email)
  },
  setNewUserFirstName({ commit }, firstName) {
    commit('SET_NEW_USER_FIRST_NAME', firstName)
  },
  setNewUserLastName({ commit }, lastName) {
    commit('SET_NEW_USER_LAST_NAME', lastName)
  },
}

const mutations = {
  REQUEST_INIT(state) {
    state.status = 'loading'
  },
  REQUEST_SUCCESS(state) {
    state.status = 'success'
    state.errors = {}
    state.dirty = false
  },
  REQUEST_ERROR(state, message) {
    state.status = 'error'
    state.errors = message
  },
  ADD_NEW_EMAIL(state) {
    state.dirty = true
    state.lab.emails.push({
      labId: state.lab.id,
      emailAdress: state.newAditionalEmails.emailAdress,
      id: null,
    })
  },
  EDIT_EMAIL(state, email) {
    state.dirty = true
    state.newAditionalEmails = email
    state.newAditionalEmails.idx = state.lab.emails.findIndex(
      (e) => e.emailAdress == email.emailAdress
    )
  },
  DELETE_EMAIL(state, emailAddress) {
    state.dirty = true
    let idx = state.lab.emails.findIndex(
      (email) => email.emailAdress == emailAddress
    )
    state.lab.emails.splice(idx, 1)
  },
  SET_EMAIL(state, email) {
    state.dirty = true
    state.newAditionalEmails.emailAdress = email
  },
  RESET_NEW_ADITIONAL_EMAILS(state) {
    state.newAditionalEmails = getNewAditionalEmail()
  },
  SET_LABS(state, labs) {
    state.labs = labs
  },
  SET_LAB(state, lab) {
    state.lab = lab
  },
  SET_LAB_CODE(state, code) {
    state.dirty = true
    state.lab.code = code
  },
  SET_LAB_NAME(state, name) {
    state.dirty = true
    state.lab.name = name
  },
  SET_LAB_COUNTRY_ID(state, countryId) {
    state.dirty = true
    state.lab.countryId = countryId
  },
  SET_NEW_USER(state, user) {
    state.newUser = user
  },
  SET_NEW_USER_EMAIL(state, email) {
    state.dirty = true
    state.newUser.email = email
  },
  SET_NEW_USER_FIRST_NAME(state, firstName) {
    state.dirty = true
    state.newUser.firstName = firstName
  },
  SET_NEW_USER_LAST_NAME(state, lastName) {
    state.dirty = true
    state.newUser.lastName = lastName
  },
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  actions,
  mutations,
}
