<template>
  <d-container fluid class="h-100">
    <d-row class="h-100">
      <!-- Main Sidebar -->
      <div v-if="!printMode">
        <main-sidebar :items="sidebarItems" />
      </div>

      <d-col
        class="main-content p-0"
        :cols="12"
        tag="main"
        :lg="printMode ? 12 : 10"
        :md="printMode ? 12 : 9"
        :offset-lg="printMode ? 0 : 2"
        :offset-md="printMode ? 0 : 3"
      >
        <!-- Main Navbar -->
        <main-navbar v-if="!printMode" />

        <!-- Content -->
        <div v-if="alerts.success_message" class="container-fluid">
          <div
            class="alert alert-success alert-dismissible fade show m-0"
            role="alert"
          >
            {{ alerts.success_message }}
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>

        <div v-if="alerts.warning_message" class="container-fluid">
          <div
            class="alert alert-warning alert-dismissible fade show m-0"
            role="alert"
          >
            {{ alerts.warning_message }}
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <aside
          v-if="
            [NAMED_ROUTES.STUDIES, NAMED_ROUTES.STATS].includes($route.name)
          "
          :class="[
            'd-md-none',
            'main-sidebar',
            'p-4',
            'col-12',
            mobileFiltersVisible ? 'open' : '',
          ]"
        >
          <study-filters />
        </aside>

        <div class="container-fluid main-content-container p-4">
          <router-view />
        </div>

        <!-- Main Footer -->
        <zoom />
        <main-footer v-if="!printMode" />
      </d-col>
    </d-row>
  </d-container>
</template>

<script>
import getSidebarItems from '../data/sidebar-nav-items'

// Main layout components
import MainNavbar from '@/components/layout/MainNavbar.vue'
import StudyFilters from '@/components/StudyFilters.vue'
import MainSidebar from '@/components/layout/MainSidebar/MainSidebar.vue'
import MainFooter from '@/components/layout/MainFooter/MainFooter.vue'
import Zoom from '@/components/layout/Zoom.vue'

export default {
  components: {
    MainNavbar,
    MainSidebar,
    StudyFilters,
    MainFooter,
    Zoom,
  },
  data() {
    return {
      sidebarItems: getSidebarItems(),
      mobileFiltersVisible: false,
    }
  },
  computed: {
    alerts() {
      return this.$root.state.alerts
    },
    printMode() {
      return !!this.$route.query.print
    },
  },
  created() {
    this.$eventHub.$on(
      'toggle-study-filters-sidebar',
      this.handleToggleMobileFilters
    )
  },
  beforeDestroy() {
    this.$eventHub.$off('toggle-study-filters-sidebar')
  },
  methods: {
    handleToggleMobileFilters() {
      this.mobileFiltersVisible = !this.mobileFiltersVisible
    },
  },
}
</script>
<style lang="scss" scoped>
.main-content {
  min-height: 100vh;
}
aside {
  overflow-x: none;
  overflow-y: auto;
}
</style>
