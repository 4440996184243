<template>
  <d-row>
    <d-col>
      <d-card>
        <h5 class="mt-3" style="margin-left: 2rem">Usuarios</h5>
        <d-card-header
          class="d-flex justify-content-between align-items-center"
          style="padding-top: 0.1rem"
        >
          <div class="inline">
            <d-button-group>
              <d-form-input
                v-model.lazy="searchUser"
                placeholder="Nombre, apellido o mail"
                @input="search(searchUser)"
              /><d-button
                class="btn-danger cstm-btn-users"
                @click="searchUser = ''"
              >
                <span class="material-icons cstm-text-user">close</span>
              </d-button>
            </d-button-group>

            <d-form-checkbox
              id="condicion"
              :checked="typeOfSearch == 'exclusive'"
              @change="
                () => {
                  typeOfSearch == 'inclusive'
                    ? (typeOfSearch = 'exclusive')
                    : (typeOfSearch = 'inclusive')
                  search(searchUser)
                }
              "
            >
              <span class="cstm-text-user">Búsqueda exacta</span>
            </d-form-checkbox>
          </div>
          <pagination
            :total-count="total_pages"
            :current-page="current_page"
            :current-count="total_pages"
            :limit="limit"
            :next-page="nextPage"
            :go-to-page="goToPage"
            :previous-page="previousPage"
            :position-class="{
              'justify-content-center': true,
              'justify-content-lg-end': true,
            }"
          />
        </d-card-header>
        <div v-if="logins_filtered.length > 0">
          <d-card-body class="p-0 pb-3 table-responsive">
            <table class="table">
              <thead class="bg-light">
                <tr>
                  <th>Tipo de usuario</th>
                  <th>Nombre</th>
                  <th>Email</th>
                  <th>Estado</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in logins_filtered" :key="item.email">
                  <td class="align-middle">
                    {{
                      item.relationType === 'lab'
                        ? 'Laboratorio'
                        : item.relationType === 'doctor'
                        ? 'Doctor'
                        : 'Admin'
                    }}
                  </td>
                  <td class="align-middle">
                    {{ item.lastName }} {{ item.firstName }}
                  </td>
                  <td class="align-middle">
                    <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                  </td>
                  <td
                    :class="[
                      'align-middle',
                      item.rowStatus === 'E' ? 'enabled' : 'disabled',
                    ]"
                  >
                    {{
                      item.rowStatus === 'E' ? 'Habilitado' : 'Deshabilitado'
                    }}
                  </td>
                  <td class="align-middle">
                    <d-button-group>
                      <d-button
                        :id="`manage-roles-${item.id}`"
                        class="
                          btn btn-accent btn-sm btn-pill
                          d-block
                          w-100
                          mb-12
                        "
                        @click="addRoleModal(item)"
                        ><span class="material-icons">
                          supervisor_account
                        </span>
                      </d-button>
                      <d-button
                        v-if="item.relationType != 'admin'"
                        :id="`manage-caps-${item.id}`"
                        class="
                          btn btn-accent btn-sm btn-pill
                          d-block
                          w-100
                          mb-12
                        "
                        @click="addCapsModal(item)"
                        ><span class="material-icons text-white"
                          >pan_tool</span
                        ></d-button
                      >
                      <d-button
                        v-if="canChangeStatus(item)"
                        :id="`block-user-${item.id}`"
                        class="
                          btn btn-accent btn-sm btn-pill
                          d-block
                          w-100
                          mb-12
                        "
                        @click="changeStatus(item)"
                      >
                        <span class="material-icons"
                          >{{
                            item.rowStatus === 'E' ? 'person_off' : 'person'
                          }}
                        </span>
                      </d-button>
                    </d-button-group>
                    <d-tooltip
                      :triggers="['hover']"
                      :target="`#block-user-${item.id}`"
                      container="true"
                    >
                      {{
                        item.rowStatus === 'E'
                          ? 'Bloquear usuario'
                          : 'Habilitar usuario'
                      }} </d-tooltip
                    ><d-tooltip
                      :triggers="['hover']"
                      :target="`#manage-caps-${item.id}`"
                      container="true"
                    >
                      Manejar topes de usuario
                    </d-tooltip>
                    <d-tooltip
                      :triggers="['hover']"
                      :target="`#manage-roles-${item.id}`"
                      container="true"
                    >
                      Manejar roles de usuario
                    </d-tooltip>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="float-right mr-3">
              <pagination
                :total-count="total_pages"
                :current-page="current_page"
                :current-count="total_pages"
                :limit="limit"
                :next-page="nextPage"
                :go-to-page="goToPage"
                :previous-page="previousPage"
                :position-class="{
                  'justify-content-center': true,
                  'justify-content-lg-end': true,
                }"
              />
            </div>
          </d-card-body>
        </div>
        <div v-else>Sin resultados!</div>
      </d-card>
    </d-col>
    <d-modal
      v-if="openRolesModal"
      id="rolesModal"
      :size="'sm'"
      @close="openRolesModal = false"
    >
      <d-modal-header>
        <d-modal-title>
          Roles de usuario para
          {{ userLoaded.firstName + ' ' + userLoaded.lastName }}
        </d-modal-title>
      </d-modal-header>
      <d-modal-body>
        <d-row>
          <div class="inline-block">
            Todos los roles
            <d-form-select
              :options="rolesOptions"
              :value="rolesOptionsSelected"
              @change="($event) => (rolesOptionsSelected = $event)"
            >
              <option :value="null" disabled>Seleccione un rol</option>
            </d-form-select>
            <!-- prettier-ignore -->

            <d-button
              class="btn-accent"
              :disabled="!rolesOptionsSelected"
              @click=" () => {
                   addRoleToThisUser(
                    userLoaded.id,
                    rolesOptionsSelected
                  )
                  rolesOptionsSelected=null
                }
              "
              >Agregar asociacion de rol</d-button
            >
          </div>
          <label>Roles configurados</label>

          <d-col v-for="role in userLoaded.roles" :key="role.id" class="inline">
            <span>
              {{ role.roleType }}
            </span>
            <d-button
              :id="`deleteRole-${userLoaded.id}-${role.id}`"
              class="btn-accent"
              @click="deleteRoleToThisUser(userLoaded.id, role.id)"
            >
              <span class="material-icons">delete</span>
            </d-button>
            <d-tooltip
              v-if="!!userLoaded.id"
              :target="`deleteRole-${userLoaded.id}-${role.id}`"
              :triggers="['hover']"
              position="bottom"
              >Borrar asociacion de rol</d-tooltip
            >
          </d-col>
        </d-row>
      </d-modal-body>
    </d-modal>
    <d-modal v-if="openCapsModal" size="lg" @close="openCapsModal = false">
      <d-modal-header
        ><d-modal-title>
          Configurar topes de testeos de usuarios
        </d-modal-title></d-modal-header
      >
      <d-modal-body>
        <h6 id="manageCapsHeader">
          {{
            capsConfigSelected.editing ? 'Editar tope' : 'Agregar nuevos topes'
          }}
        </h6>
        <table
          v-if="userLoaded.country"
          id="results-table"
          class="table-responsive table table-striped table-borderless"
        >
          <colgroup>
            <col style="width: 15%" />
            <col style="width: 15%" />
            <col style="width: 15%" />
          </colgroup>
          <thead>
            <th scope="col">Test x farma</th>
            <th scope="col">Tope</th>
            <th scope="col">Accion</th>
          </thead>
          <tbody>
            <tr class="align-self-center">
              <td
                v-if="!capsConfigSelected.editing"
                class="align-middle sticky-column"
              >
                <d-form-select @change="handleSelectTests($event)">
                  <optgroup id="bmkGroup" label="Biomarcadores">
                    <option
                      v-for="opt in testsOptions"
                      :key="opt.id"
                      class="customOptions"
                      :value="
                        JSON.stringify({
                          group: 'bmk',
                          id: opt.id,
                          data: {
                            biomarker: opt.biomarker.name,
                            pharma: opt.pharma.name,
                            user:
                              userLoaded.firstName + '_' + userLoaded.lastName,
                            country: userLoaded.country.name,
                          },
                        })
                      "
                    >
                      {{ opt.biomarker.name }} ({{ opt.pharma.name }})
                      {{ opt.isPSP ? '(PSP)' : null }}
                    </option>
                  </optgroup>
                  <optgroup label="Umbrellas">
                    <option
                      v-for="opt in umbrellaTestsOptions"
                      :key="opt.id"
                      :value="
                        JSON.stringify({
                          group: 'umbrella',
                          id: opt.id,
                          data: {
                            umbrella: opt.umbrella.name,
                            pharma: opt.pharma.name,
                            user:
                              userLoaded.firstName + '_' + userLoaded.lastName,
                            country: userLoaded.country.name,
                          },
                        })
                      "
                    >
                      {{ opt.umbrella.name }} ({{ opt.pharma.name }})
                      {{ opt.isPSP ? '(PSP)' : null }}
                    </option>
                  </optgroup>
                </d-form-select>
              </td>
              <td v-else class="align-middle sticky-column">
                <p>
                  {{ capsConfigSelected.capsName }}
                </p>
              </td>
              <td class="align-middle sticky-column">
                <d-form-input
                  v-model="capsConfigSelected.caps"
                  type="number"
                  min="0"
                />
              </td>
              <td class="align-middle sticky-column">
                <d-button-group>
                  <d-button
                    class="btn-accent"
                    type="button"
                    :disabled="
                      !capsConfigSelected.caps &&
                      (!capsConfigSelected.testPerPharmaPerCountryId ||
                        !capsConfigSelected.umbrellaTestPerPharmaPerCountryId)
                    "
                    @click="saveNewCaps"
                    >{{
                      capsConfigSelected.editing
                        ? 'Editar tope de usuario'
                        : 'Guardar Tope de usuario'
                    }}</d-button
                  >
                  <d-button class="btn-danger" @click="getCapsDefaultState()">
                    <span class="material-icons">close</span>
                  </d-button>
                </d-button-group>
              </td>
            </tr>
            <hr />
          </tbody>
        </table>
        <table
          v-if="userLoaded.caps.length > 0"
          id="results-table"
          class="table-responsive table table-striped table-borderless"
        >
          <h6>Topes configurados</h6>
          <colgroup>
            <col style="width: 15%" />
            <col style="width: 15%" />
            <col style="width: 15%" />
          </colgroup>
          <thead>
            <th scope="col">Test x farma</th>
            <th scope="col">Tope</th>
            <th scope="col">Accion</th>
          </thead>
          <tbody>
            <tr
              v-for="(cap, index) in userLoaded.caps"
              :key="index"
              class="align-self-center"
            >
              <td class="align-middle sticky-column">
                {{ cap.capsName }}
              </td>
              <td v-if="cap.id" class="align-middle sticky-column">
                {{ cap.caps }}
              </td>
              <td class="align-middle sticky-column">
                <d-button-group>
                  <d-btn class="d-btn btn-accent">
                    <a
                      class="p text-white"
                      href="#manageCapsHeader"
                      @click="editSelectedCaps(cap)"
                      >Editar</a
                    >
                  </d-btn>

                  <d-button class="btn-danger" @click="deleteSelectedCaps(cap)"
                    >Borrar</d-button
                  >
                </d-button-group>
              </td>
            </tr>
          </tbody>
        </table>
      </d-modal-body>
    </d-modal>
  </d-row>
</template>

<script>
import PlutonBaseMixin from '@/components/mixins/PlutonBaseMixin'
import Pagination from '@/components/common/Pagination'
import { debounce } from 'lodash'
import { mapActions, mapState } from 'vuex'
export default {
  components: { Pagination },
  mixins: [PlutonBaseMixin],
  data() {
    return {
      current_page: 1,
      total_pages: 1,
      limit: 20,
      logins_filtered: [],
      status_show: [],
      searchUser: '',
      roles: [],
      openRolesModal: false,
      openCapsModal: false,
      userLoaded: {},
      typeOfSearch: 'exclusive',
      rolesOptionsSelected: null,
      capsConfigSelected: {
        caps: null,
        capsName: '',
        userId: null,
        testPerPharmaPerCountryId: null,
        umbrellaTestPerPharmaPerCountryId: null,
        editing: false,
      },
    }
  },
  computed: {
    ...mapState('testsGrouper', ['testsGroupers']),
    rolesOptions() {
      let rolesIdsCofiguratedForThisUser = this.userLoaded.roles.map(
        (r) => r.id
      )
      return this.roles
        .map((v) => {
          return { text: v.name, value: v.id }
        })
        .filter((r) => !rolesIdsCofiguratedForThisUser.includes(r.value))
    },
    testsGrouperOptions() {
      return this.testsGroupers.map((val) => {
        return { value: val.id, text: val.testGrouperName }
      })
    },
    testsOptions() {
      const tests = JSON.parse(
        JSON.stringify(
          this.userLoaded.country.settings.filter(
            (setting) =>
              !this.userLoaded.caps
                .map((val) => val.testPerPharmaPerCountryId)
                .includes(setting.id)
          )
        )
      )
      let pspTestGrouper = this.testsGroupers.find(
        (tG) => tG.testGrouperName === 'PSP'
      )
      return tests.map((test) => {
        return {
          id: test.id,
          biomarker: test.biomarker,
          pharma: test.pharma,
          isPSP: !!pspTestGrouper.biomarkersTestsConfig.find(
            (config) => test.id === config.id
          ),
        }
      })
    },
    umbrellaTestsOptions() {
      const tests = JSON.parse(
        JSON.stringify(
          this.userLoaded.country.countryUmbrellaSetting.filter(
            (setting) =>
              !this.userLoaded.caps
                .map((val) => val.umbrellaTestPerPharmaPerCountryId)
                .includes(setting.id)
          )
        )
      )
      let pspTestGrouper = this.testsGroupers.find(
        (tG) => tG.testGrouperName === 'PSP'
      )
      return tests.map((test) => {
        return {
          id: test.id,
          umbrella: test.umbrella,
          pharma: test.pharma,
          isPSP: !!pspTestGrouper.umbrellasTestsConfig.find(
            (umbrellaConfig) => test.id === umbrellaConfig.id
          ),
        }
      })
    },
  },
  watch: {
    userLoaded(val) {
      this.userLoaded = val
    },
  },
  mounted() {
    this.updateLoginList()
    this.getTestsGrouper()
  },
  methods: {
    ...mapActions('testsGrouper', ['getTestsGrouper']),
    getCapsDefaultState() {
      if (this.capsConfigSelected.userId) {
        var userId = this.capsConfigSelected.userId
      }
      this.capsConfigSelected = {
        caps: null,
        capsName: '',
        userId: userId ? userId : null,
        testPerPharmaPerCountryId: null,
        umbrellaTestPerPharmaPerCountryId: null,
        editing: false,
      }
    },
    handleSelectTests(event) {
      const selectedValue = JSON.parse(event)
      if (selectedValue.group === 'bmk') {
        this.capsConfigSelected.testPerPharmaPerCountryId = selectedValue.id
        this.capsConfigSelected.umbrellaTestPerPharmaPerCountryId = null
        this.capsConfigSelected.capsName =
          selectedValue.data.user +
          '_' +
          selectedValue.data.biomarker +
          '_' +
          selectedValue.data.pharma +
          '_' +
          selectedValue.data.country
      } else if (selectedValue.group === 'umbrella') {
        this.capsConfigSelected.umbrellaTestPerPharmaPerCountryId =
          selectedValue.id
        this.capsConfigSelected.testPerPharmaPerCountryId = null
        this.capsConfigSelected.capsName =
          selectedValue.data.user +
          '_' +
          selectedValue.data.umbrella +
          '_' +
          selectedValue.data.pharma +
          '_' +
          selectedValue.data.country
      }
    },
    async saveNewCaps() {
      let method = this.capsConfigSelected.editing == true ? 'PATCH' : 'POST'
      const url =
        method === 'POST'
          ? '/api2/caps/create'
          : `/api2/caps/${this.capsConfigSelected.id}`
      await this.axios({
        method,
        url,
        data: {
          ...this.capsConfigSelected,
        },
      })

      // await this.axios.post()
      await this.search(this.searchUser)
      let userUpdated = this.logins_filtered.find(
        (u) => u.id === this.capsConfigSelected.userId
      )
      await this.updateRolesData(userUpdated)
      setTimeout(() => this.resetCapsData(userUpdated), 1000)
    },
    resetCapsData(user) {
      this.loadAditionalUserData(user)
      this.getCapsDefaultState()
    },
    editSelectedCaps(cap) {
      this.capsConfigSelected = cap
      this.capsConfigSelected.editing = true
    },
    async deleteSelectedCaps(cap) {
      await this.axios.delete(`/api2/caps/delete_caps/${cap.id}`)
      await this.search(this.searchUser)
      let userUpdated = this.logins_filtered.find(
        (u) => u.id === this.capsConfigSelected.userId
      )
      await this.updateRolesData(userUpdated)
      setTimeout(() => this.resetCapsData(userUpdated), 1000)
    },
    async loadRoles() {
      let roles = (await this.axios.get('/api2/user/roles')).data
      if (roles) this.roles = roles
    },
    async search(value) {
      this.validationRequest = debounce(() => {
        this.updateLoginList(value)
      }, 650)
      this.validationRequest()
    },
    updateRolesData(user) {
      setTimeout(() => {
        let userUpdated = this.logins_filtered.find((u) => u.id === user.id)
        this.loadRolesData(userUpdated)
      }, 1000)
    },
    loadRolesData(user) {
      this.userLoaded = user
    },
    addRoleModal(user) {
      if (this.roles.length == 0) {
        this.loadRoles()
      }
      this.openRolesModal = true
      this.loadRolesData(user)
    },
    addCapsModal(user) {
      // if (this.roles.length == 0) {
      //   this.loadRoles()
      // }
      this.openCapsModal = true
      this.loadRolesData(user)
      this.loadAditionalUserData(user)
      this.getCapsDefaultState()
      this.capsConfigSelected.userId = user.id
    },
    async addRoleToThisUser(userId, roleId) {
      await this.axios.patch(`/api2/user/patch_user_roles/${userId}/${roleId}`)
      await this.updateLoginList(this.searchUser) //TODO
      this.updateRolesData(this.userLoaded)
    },
    async deleteRoleToThisUser(userId, roleId) {
      await this.axios.delete(
        `/api2/user/delete_user_roles/${userId}/${roleId}`
      )
      await this.updateLoginList(this.searchUser) //TODO
      this.updateRolesData(this.userLoaded)
    },
    previousPage() {
      if (this.current_page <= 1) return
      this.current_page--
      this.search(this.searchUser)
    },
    nextPage() {
      if (this.current_page * this.limit > this.totalStudies) return
      this.current_page++
      this.search(this.searchUser)
    },
    goToPage(pageNumber) {
      this.current_page = pageNumber
      this.search(this.searchUser)
    },
    changeStatus(item) {
      this.axios
        .patch(`/api2/user/toogle_user_status/${item.id}`)
        .then(() => {
          this.search(this.searchUser)
        })
        .catch(this.catchAjaxError)
    },
    canChangeStatus(item) {
      return item.relationType == 'doctor'
    },
    async loadAditionalUserData(user) {
      let params = { user_id: user.id }
      this.axios
        .get('/api2/user/users/get_user_data', { params })
        .then((response) => {
          this.userLoaded.country = response.data.country
        })
    },
    async updateLoginList(filters) {
      let params = {
        page: this.current_page,
        type_of_search: this.typeOfSearch,
      }
      if (filters !== undefined && filters !== null) {
        params.filters = filters
      }
      this.axios
        .get('/api2/user/users/get_users', { params })
        .then((response) => {
          this.logins_filtered = response.data.users
          this.total_pages = response.data.totalCount
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.enabled {
  color: green;
}
.disabled {
  color: red;
}
.cstm-btn-users {
  padding: 0.01rem;
}
.cstm-text-user {
  font-size: 0.9rem;
}
</style>
