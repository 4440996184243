<template>
  <div class="positivity-bar">
    <div class="header">
      <p>{{ name }}</p>
      <p class="total-positives">{{ positivePercentage }}%</p>
    </div>
    <div class="progress">
      <div
        class="progress-bar"
        role="progressbar"
        :style="`width: ${negativePercentage}%; background: var(--teal);`"
        :aria-valuenow="negativePercentage"
        aria-valuemin="0"
        aria-valuemax="100"
      />
      <div
        class="progress-bar"
        role="progressbar"
        :style="`width: ${positivePercentage}%; background: var(--pink);`"
        :aria-valuenow="positivePercentage"
        aria-valuemin="0"
        aria-valuemax="100"
      />
    </div>
    <p class="detail">
      {{ biomarker.successfulStudies }} estudio{{
        checkPlurals(biomarker.successfulStudies)
      }}
      exitoso{{ checkPlurals(biomarker.successfulStudies) }} ({{
        successfulPercentage
      }}%), {{ biomarker.insufficientStudies }} estudio{{
        checkPlurals(biomarker.insufficientStudies)
      }}
      insuficiente{{ checkPlurals(biomarker.insufficientStudies) }} ({{
        insufficientPercentage
      }}%)
    </p>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    biomarker: {
      type: Object,
      default: () => ({
        id: 0,
        positive: 0,
        negative: 0,
        successfulStudies: 0,
        insufficientStudies: 0,
      }),
    },
  },
  computed: {
    checkPlurals() {
      return (num) => (num === 1 ? '' : 's')
    },
    positivePercentage() {
      return this.getPercentage(this.biomarker.positive, [
        this.biomarker.positive,
        this.biomarker.negative,
      ])
    },
    negativePercentage() {
      return this.getPercentage(this.biomarker.negative, [
        this.biomarker.positive,
        this.biomarker.negative,
      ])
    },
    successfulPercentage() {
      return this.getPercentage(this.biomarker.successfulStudies, [
        this.biomarker.successfulStudies,
        this.biomarker.insufficientStudies,
      ])
    },
    insufficientPercentage() {
      return this.getPercentage(this.biomarker.insufficientStudies, [
        this.biomarker.successfulStudies,
        this.biomarker.insufficientStudies,
      ])
    },
  },
  methods: {
    getPercentage(num, numbers) {
      return Math.round(
        (num * 100) /
          numbers.reduce((acc, currentNumber) => acc + currentNumber, 0)
      )
    },
  },
}
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;

  > p {
    color: var(--grey-1);
    margin-bottom: 4px;
    font-size: var(--font-lg);
    &.total-positives {
      color: var(--pink);
    }
  }
}

.progress {
  height: 4px;
  margin: 4px 0;
  .progress-bar {
    animation-name: grow;
    animation-duration: 1.8s;
    animation-timing-function: linear;
  }
}

p {
  margin: 0;
}

.detail {
  color: var(--grey-2);
  font-size: var(--font-md);
}

@keyframes grow {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
</style>
