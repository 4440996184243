<template>
  <div>
    <h3>
      Carga de estudios por CSV
    </h3>
    <d-card class="mb-4 card-small">
      <d-card-header>
        <h4 class="mb-0">
          Seleccione un archivo
        </h4>
      </d-card-header>
      <d-card-body>
        <d-form
          enctype="multipart/form-data"
          class="d-flex align-items-center mb-3"
          @submit="handleOnSubmit"
        >
          <div class="form-group mr-3 mb-0 w-100">
            <input
              id="formFile"
              class="form-control"
              name="data_file"
              type="file"
              :disabled="isFormDisabled"
            >
          </div>
          <d-button
            class="btn-accent flex-shrink-0"
            type="submit"
            :disabled="isFormDisabled"
          >
            Subir archivo
          </d-button>
        </d-form>
        <div
          v-if="!isFormDisabled && logs.length"
          class="bg-light border rounded p-3 mb-3"
        >
          <h5>Resultados de la carga</h5>
          <p
            v-for="(log, idx) in logs"
            :key="idx"
            class="m-0"
          >
            <strong :class="logColors[log.level]">{{ log.level.toUpperCase() }}</strong> - {{ log.message }}
          </p>
        </div>
      </d-card-body>
    </d-card>
    <d-card class="card-small">
      <d-card-header>
        <h4 class="m-0">
          Consideraciones
        </h4>
      </d-card-header>
      <d-card-body>
        <ul>
          <li>Cada línea del CSV coincidirá con un test en un estudio en el sistema.</li>
          <li>El CSV debe contener <strong>al menos</strong> las columnas indicadas como <span class="table-warning">requeridas</span>.</li>
          <li>
            Todas las fechas deben estar en formato <a
              href="https://www.ionos.es/digitalguide/paginas-web/desarrollo-web/iso-8601/"
              target="_blank"
            >ISO 8601</a> (por ejemplo: <pre>{{ (new Date()).toISOString() }}</pre>).
          </li>
          <li>Cualquier campo que contenga comas como parte de su valor debe estar rodeado de comillas (por ejemplo: <pre>...,"un campo, con comas en su valor",...</pre>).</li>
        </ul>
        <div class="table-responsive">
          <table class="table table-condensed">
            <thead>
              <th>Campo</th>
              <th>Descripción</th>
              <th>Requerido</th>
              <th>Tipo de dato</th>
              <th>Valores permitidos</th>
              <th>Valor por defecto</th>
              <th>Valor de ejemplo</th>
            </thead>
            <tbody>
              <tr
                v-for="field in fields"
                :key="field.name"
                :class="field.required ? 'table-warning' : ''"
              >
                <th scope="row">
                  {{ field.name }}
                </th>
                <td>{{ field.description }}</td>
                <td>{{ field.required ? "Sí" : "No" }}</td>
                <td>{{ field.fieldType }}</td>
                <td v-if="field.name == 'study_test__result_code'">
                  <div
                    v-for="test in Object.keys(field.allowedValues)"
                    :key="test"
                  >
                    <p>Para el test <strong>{{ test }}</strong>, se permiten:</p>
                    <ul>
                      <li
                        v-for="result in Object.keys(field.allowedValues[test])"
                        :key="result"
                      >
                        <strong>{{ result }}</strong> ({{ field.allowedValues[test][result] }})
                      </li>
                    </ul>
                  </div>
                </td>
                <td v-else>
                  {{ field.allowedValues }}
                </td>
                <td>{{ field.defaultValue }}</td>
                <td>{{ field.sampleValue }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </d-card-body>
    </d-card>
  </div>
</template>

<script>
import PlutonBaseMixin from '@/components/mixins/PlutonBaseMixin';
import { getStudyCSVFields, postStudyCSV } from '@/api/management'

export default {
  mixins: [ PlutonBaseMixin ],
  data() {
    const states = {
      START: 'START',
      LOADING: 'LOADING',
    }
    return {
      fields: [],
      logs: [],
      possibleStates: states,
      state: states.START,
      logColors: {
        'info': 'text-info',
        'warning': 'text-warning',
        'error': 'text-danger',
      },
    }
  },
  computed: {
    isFormDisabled() {
      return this.state === this.possibleStates.LOADING;
    },
  },
  async mounted() {
    this.fields = await getStudyCSVFields()
  },
  methods: {
    async handleOnSubmit (e) {
      e.preventDefault();
      this.state = this.possibleStates.LOADING;
      this.logs = []
      const formData = new FormData();
      formData.append(e.target[0].name, e.target[0].files[0]);
      const response = await postStudyCSV(formData);
      if (response.status === 200) {
        this.logs = response.data.logs;
        if (response.data.errors.length) this.downloadErrorsCSV(response.data.errors)
      } else {
        this.logs = [{
          level: 'error',
          message: `Hubo un problema intentando procesar este CSV: ${response.data}`,
        }]
      }
      this.clearForm(e.target);
    },
    clearForm(form) {
      this.state = this.possibleStates.START;
      form.reset()
    },
    downloadErrorsCSV(errors = []) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent = `${csvContent}${Object.keys(errors[0])}\r\n`
      errors.forEach((rowArray) => {
          let row = `"${Object.values(rowArray).join('","')}"`;
          csvContent = `${csvContent}${row}\r\n`
      });
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `study_upload_errors-${(new Date()).toISOString()}.csv`);
      document.body.appendChild(link); // Required for FF
      link.click();
    }
  },
};
</script>

<style scoped lang="scss">
pre {
  display: inline;
}
</style>

