<template>
  <form @submit="sendAllStudiesToTraceabilitySystem">
    <h3>Envíar pedidos al sistema de trazabilidad</h3>
    <p class="text-danger">
      Advertencia!!. Los estudios viejos que no tengan un país de procesado
      deben agregársele desde base de datos su correspondiente antes de intentar
      reenviarlo. Sinó fallara el proceso!
    </p>
    <d-card>
      <d-input
        v-model="studiesIdsInput"
        placeholder="Ingrese los ids de los estudios separados por coma"
      />
      <d-button
        class="btn btn-accent btn-primary"
        type="submit"
        :disabled="!studiesIdsInput"
        >Enviar pedidos</d-button
      >
    </d-card>
    <hr />
    <d-card class="container flex custom_orders_to_send_d-card">
      <h6>Pedidos sin enviar</h6>
      <table class="orders_to_send_custom_table">
        <tbody>
          <tr class="align-self-center">
            <td class="align-middle">Id pedido -</td>
            <td class="align-middle">Envíado -</td>
            <td class="align-middle">Error</td>
          </tr>
          <tr
            v-for="order in unsendedOrders"
            :key="order.id"
            class="align-self-center"
          >
            <td class="align-middle">{{ order.order_id }}</td>
            <td class="align-middle">
              {{ order.sended }}
            </td>
            <td class="align-middle">
              <d-button
                class="btn-pill btn-accent"
                type="button"
                @click="searchSpecificLog(order)"
                ><span class="material-icons">search</span></d-button
              >
            </td>
          </tr>
        </tbody>
      </table>
    </d-card>
    <d-modal v-if="showModal">
      <d-modal-body>{{ modalMsg }}</d-modal-body>
      <d-button type="button" @click="showModal = false">Cerrar</d-button>
    </d-modal>
  </form>
</template>

<script>
// import { mapActions, mapGetters, mapState } from 'vuex'
import axios from 'axios'

export default {
  data() {
    return {
      studiesIdsInput: '',
      showModal: false,
      modalMsg: '',
      unsendedOrders: [],
    }
  },
  computed: {
    // ...mapGetters('orders_to_send'['get_all_orders_to_send']) // TODO: visualizar todos los pedidos que no se mandaron :)
  },
  async mounted() {
    this.unsendedOrders = (
      await axios.get('/api2/study/orders/get_all_unsendend_orders')
    ).data
  },
  methods: {
    async searchSpecificLog(order) {
      await axios
        .get(
          `/api2/study/orders/get_specific_log_for_an_order?created_at=${order.created_at}&associated_id=${order.order_id}`,
          {}
        )
        .then((logs) => {
          this.showModal = true
          this.modalMsg = logs['data']
        })
        .catch((e) => console.log(e))
    },
    async sendAllStudiesToTraceabilitySystem() {
      //   alert('asda')
      let split = this.studiesIdsInput.split(',')
      let ids = split.map(Number)
      await axios
        .put('/api2/study/studies/send_all_studies', ids)
        .then(async (values) => {
          this.studiesIdsInput = ''
          this.showModal = true
          if (values['data'].some((v) => v.status_code != 200)) {
            let sendedStudies = JSON.stringify(
              values['data'].filter((st) => st.status_code == 200)
            )
            let unsendedStudies = JSON.stringify(
              values['data'].filter((st) => st.status_code != 200)
            )
            this.modalMsg = `Estudios enviados:
            ${sendedStudies}.
            Estudios con error:
            ${unsendedStudies}`
          } else {
            this.modalMsg = `Operacion exitosa. Enviados pedidos con ids ${ids}`
          }
          this.unsendedOrders = (
            await axios.get('/api2/study/orders/get_all_unsendend_orders')
          ).data
        })
        .catch((e) => {
          let response = JSON.parse(JSON.stringify(e))
          this.studiesIdsInput = ''
          this.showModal = true
          this.modalMsg = response['message']
        })
    },
  },
}
</script>

<style lang="scss">
.custom_orders_to_send_d-card {
  width: fit-content;
}
.orders_to_send_custom_d-card_table {
  width: 30%;
  align-self: center;
}
</style>
