<template>
  <div>
    <div
      v-if="lab_exists"
      class="alert alert-warning fade show px-2 my-40 text-center"
      role="alert"
    >
      {{ msg_lab }}
    </div>
    <form @submit="handleSave">
      <management-header :title="`${isNew ? 'Nuevo' : 'Editar'} laboratorio`">
        <button
          type="submit"
          :disabled="!(isValid && isDirty)"
          outline
          class="btn btn-pill btn-accent"
        >
          {{ isNew ? 'Crear' : 'Actualizar' }}
        </button>
        <button
          type="button"
          outline
          class="btn btn-pill btn-secondary"
          @click="handleDiscard"
        >
          {{ isDirty ? 'Descartar' : 'Volver' }}
        </button>
      </management-header>
      <h6 v-if="isDirty" class="text-danger">Hay cambios sin guardar</h6>
      <d-card class="p-4">
        <div class="row">
          <div class="col">
            <v-input
              v-model="labCode"
              :error="errors.code"
              name="code"
              placeholder="Código del laboratorio"
            />
          </div>
          <div class="col">
            <v-input
              v-model="labName"
              :error="errors.name"
              name="name"
              placeholder="Nombre del laboratorio"
            />
          </div>
        </div>

        <d-form-select
          v-model="labCountryId"
          class="select"
          :options="countriesOptions"
        />
        <div v-if="isNew" class="row">
          <div class="col">
            <v-input
              v-model="newUserFirstName"
              :error="errors.firstName"
              name="firstName"
              placeholder="Nombre del nuevo usuario"
            />
          </div>
          <div class="col">
            <v-input
              v-model="newUserLastName"
              :error="errors.lastName"
              name="lastName"
              placeholder="Apellido del nuevo usuario"
            />
          </div>
        </div>

        <v-input
          v-if="isNew"
          v-model="newUserEmail"
          :error="errors.email"
          name="email"
          placeholder="Email del nuevo usuario"
        />
      </d-card>
      <d-card>
        <d-card-header id="AditionalEmailTitle" class="h5"
          >Emails adicionales</d-card-header
        >
        <d-card-body>
          <div class="row">
            <v-input
              v-model="additionalEmails"
              class="col"
              type="email"
              name="email"
              placeholder="E-mail"
            />
            <button
              id="saveButton"
              type="button"
              class="col-16 material-icons btn-accent"
              style="height: 50px"
              :disabled="
                newAditionalEmails.emailAdress == '' || mailAlreadyConfigurated
              "
              @click="addNewEmail().then(() => resetEmail())"
            >
              <span>save</span>
            </button>
          </div>
          <span v-if="newAditionalEmails.emailAdress == ''" class="text-danger"
            >Escriba un email válido</span
          >
          <span
            v-if="mailAlreadyConfigurated && !isEditingAnEmail"
            class="text-danger"
            >Este mail ya está configurado</span
          >
          <d-tooltip target="#AditionalEmailTitle" class="text-info">
            Configure otros mails para envíar cuando se cree un pedido.
          </d-tooltip>
          <d-tooltip target="#saveButton">Guardar</d-tooltip>
          <colgroup>
            <col style="width: 5%; max-width: 5%" />
            <col style="width: 5%; max-width: 5%" />
          </colgroup>
          <thead>
            <th scope="col">Email</th>
            <th scope="col">Editar</th>
            <th v-if="!isEditingAnEmail" scope="col">Borrar</th>
            <th v-if="isEditingAnEmail" scope="col">Guardar</th>
          </thead>
          <tbody>
            <tr v-for="(lab, idx) in lab.emails" :key="idx">
              <td class="align-middle">
                <span v-if="newAditionalEmails.idx != idx">{{
                  lab.emailAdress
                }}</span>
                <div v-else>
                  <d-form-input
                    v-model="additionalEmails"
                    style="width: 15rem"
                  />
                </div>
              </td>
              <td class="align-middle">
                <button
                  type="button"
                  class="material-icons btn-accent"
                  @click="editEmail(lab)"
                >
                  <span>view_agenda</span>
                </button>
              </td>
              <td v-if="!isEditingAnEmail" class="align-middle">
                <button
                  type="button"
                  class="material-icons btn-accent"
                  @click="deleteEmail(lab.emailAdress)"
                >
                  <span>delete</span>
                </button>
              </td>
              <td v-if="newAditionalEmails.idx == idx" class="align-middle">
                <button
                  type="button"
                  class="material-icons btn-accent"
                  @click="resetEmail()"
                >
                  <span>save</span>
                </button>
              </td>
            </tr>
          </tbody>
        </d-card-body>
      </d-card>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import VInput from '@/components/Form/VInput.vue'
import ManagementHeader from '@/components/ManagementHeader.vue'

export default {
  components: {
    VInput,
    ManagementHeader,
  },
  props: {
    labId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      msg_lab: '',
      lab_exists: false,
    }
  },
  computed: {
    ...mapState('labs', ['lab', 'newAditionalEmails']),
    ...mapGetters('labs', [
      'isNew',
      'isValid',
      'isDirty',
      'isEditingAnEmail',
      'hasErrors',
      'errors',
    ]),
    ...mapGetters('countries', {
      countries: 'countriesEnabled',
    }),
    mailAlreadyConfigurated() {
      return this.lab.emails
        ? this.lab.emails.find(
            (email) => email.emailAdress === this.newAditionalEmails.emailAdress
          )
        : false
    },
    countriesOptions() {
      const options = this.countries.map((country) => ({
        value: country.id,
        text: country.name,
      }))
      return [{ value: null, text: 'Seleccione un país' }, ...options]
    },
    additionalEmails: {
      get() {
        return this.newAditionalEmails.emailAdress
      },
      set(email) {
        this.setEmail(email)
      },
    },
    labCode: {
      get() {
        return this.$store.state.labs.lab.code
      },
      set(code) {
        this.setLabCode(code)
      },
    },
    labName: {
      get() {
        return this.$store.state.labs.lab.name
      },
      set(name) {
        this.setLabName(name)
      },
    },
    labCountryId: {
      get() {
        return this.$store.state.labs.lab.countryId
      },
      set(countryId) {
        this.setLabCountryId(countryId)
      },
    },
    newUserEmail: {
      get() {
        return this.$store.state.labs.newUser.email
      },
      set(email) {
        this.setNewUserEmail(email)
      },
    },
    newUserFirstName: {
      get() {
        return this.$store.state.labs.newUser.fisrtName
      },
      set(fisrtName) {
        this.setNewUserFirstName(fisrtName)
      },
    },
    newUserLastName: {
      get() {
        return this.$store.state.labs.newUser.lastName
      },
      set(lastName) {
        this.setNewUserLastName(lastName)
      },
    },
  },
  async mounted() {
    this.getCountries()
    await this.getLabs()
    await this.setLab(this.labId)
  },
  methods: {
    ...mapActions('labs', [
      'getLabs',
      'saveLab',
      'setLab',
      'setLabCode',
      'setLabName',
      'setLabCountryId',
      'setNewUserEmail',
      'setNewUserFirstName',
      'setNewUserLastName',
      'setEmail',
      'editEmail',
      'resetEmail',
      'addNewEmail',
      'deleteEmail',
    ]),
    ...mapActions('countries', ['getCountries']),
    async handleSave(e) {
      e.preventDefault()
      await this.saveLab()
      if (!this.hasErrors) this.$router.push({ name: this.NAMED_ROUTES.LABS })
    },
    handleDiscard() {
      this.$router.push({ name: this.NAMED_ROUTES.LABS })
    },
  },
}
</script>

<style lang="scss" scoped>
.select {
  margin-bottom: 0.8rem;
}
</style>
