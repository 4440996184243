import axios from 'axios'

import { parse_pydantic_errors } from '@/utils'
import { ROW_STATUS } from '@/utils/constants'

const getEmptyBiomarker = () => ({
  id: '',
  name: '',
  code: '',
  recent: false,
  primaryTumors: [],
  bibliographies: [],
})

const getEmptyBiomarkerResult = () => ({
  name: '',
  code: '',
  color: '#999999',
  statisticsGrouper: null,
  rowStatus: 'E',
})

const getEmptyBiomarkerBibliography = () => ({
  identifiers: '',
  authors: '',
  title: '',
  url: '',
  biomarkerId: '',
  rowStatus: 'E',
})

const getDefaultState = () => ({
  status: null,
  errors: {},
  dirty: false,
  biomarkers: [],
  biomarkersSetting: [],
  biomarker: getEmptyBiomarker(),
  result: getEmptyBiomarkerResult(),
  bibliography: getEmptyBiomarkerBibliography(),
})

const getters = {
  biomarkers: (state) => state.biomarkers,
  biomarkersEnabled: (state) =>
    state.biomarkers.filter(
      (biomarker) => biomarker.rowStatus === ROW_STATUS.ENABLED
    ),
  biomarkerResults: (state) => state.biomarker.results,
  biomarkerBibliographies: (state) => state.biomarker.bibliographies,
  result: (state) => state.result,
  bibliography: (state) => state.bibliography,
  isDirty: (state) => state.dirty,
  isValid: (state) =>
    !!state.biomarker.name &&
    !!state.biomarker.code &&
    state.biomarker.primaryTumors.length,
  isNew: (state) =>
    !(
      state.biomarker.id &&
      state.biomarkers.find((biomarker) => biomarker.id === state.biomarker.id)
    ),
  hasErrors: (state) => Object.entries(state.errors).length > 0,
  errors: (state) => state.errors,
}

const actions = {
  async getBiomarkersSetting({ commit, state }) {
    if (state.status === 'loading') return
    commit('REQUEST_INIT')
    try {
      const biomarkersSetting = (await axios.get('/api2/settings/test/')).data
        .map((setting) => {
          setting.allowedPharmas = setting.allowedPharmas.filter(
            (pharma) => pharma.rowStatus === ROW_STATUS.ENABLED
          )
          return setting
        })
        .filter(
          (setting) =>
            setting.rowStatus === ROW_STATUS.ENABLED &&
            setting.allowedPharmas.length > 0
        )
      commit('SET_BIOMARKERS_SETTING', biomarkersSetting)
      commit('REQUEST_SUCCESS')
    } catch (err) {
      commit('REQUEST_ERROR', err)
    }
  },
  async getBiomarkers({ commit }) {
    commit('REQUEST_INIT')
    try {
      const biomarkers = (await axios.get('/api2/biomarker/')).data
      commit('SET_BIOMARKERS', biomarkers)
      commit('REQUEST_SUCCESS')
    } catch (err) {
      commit('REQUEST_ERROR', err)
    }
  },
  async saveBiomarker({ commit, state, getters }) {
    if (!getters.isDirty) return
    const method = getters.isNew ? 'post' : 'put'
    const url =
      method === 'post'
        ? '/api2/biomarker/'
        : `/api2/biomarker/${state.biomarker.id}`

    commit('REQUEST_INIT')
    try {
      const biomarker = (
        await axios({
          method,
          url,
          data: { ...state.biomarker },
        })
      ).data
      commit('REQUEST_SUCCESS')
      const biomarkers = [...state.biomarkers]
      if (method === 'post') {
        biomarkers.push(biomarker)
      } else {
        const index = biomarkers.findIndex((c) => c.id === biomarker.id)
        biomarkers[index] = biomarker
      }
      commit('SET_BIOMARKERS', biomarkers)
    } catch (err) {
      commit('REQUEST_ERROR', parse_pydantic_errors(err.response.data))
    }
  },
  setBiomarker({ commit, state }, biomarkerId) {
    const biomarker = state.biomarkers.find(
      (biomarker) => biomarker.id === biomarkerId
    )
    if (biomarker) commit('SET_BIOMARKER', biomarker)
    else commit('SET_BIOMARKER', getEmptyBiomarker())
  },
  setBiomarkerName({ commit }, name) {
    commit('SET_BIOMARKER_NAME', name)
  },
  setBiomarkerCode({ commit }, code) {
    commit('SET_BIOMARKER_CODE', code)
  },
  setBiomarkerPrimaryTumor({ commit }, primaryTumor) {
    commit('SET_BIOMARKER_PRIMARY_TUMOR', primaryTumor)
  },
  setBiomarkerResult({ commit }, payload) {
    commit('SET_BIOMARKER_RESULT', payload)
  },
  setResultName({ commit }, name) {
    commit('SET_RESULT_NAME', name)
  },
  setResultCode({ commit, state }, code) {
    if (state.biomarker.results.some((result) => result.code === code))
      commit('REQUEST_ERROR', { new_result_code: 'Este código ya está en uso' })
    else commit('CLEAR_ERRORS')
    commit('SET_RESULT_CODE', code)
  },
  setResultColor({ commit }, color) {
    commit('SET_RESULT_COLOR', color)
  },
  setResultStatisticsGrouper({ commit }, statisticsGrouper) {
    commit('SET_RESULT_STATISTICS_GROUPER', statisticsGrouper)
  },
  addResult({ commit, state }) {
    commit('ADD_RESULT', state.result)
    commit('SET_RESULT', getEmptyBiomarkerResult())
  },
  setBiomarkerBibliography({ commit }, payload) {
    commit('SET_BIOMARKER_BIBLIOGRAPHY', payload)
  },
  setBibliographyIdentifiers({ commit }, identifiers) {
    commit('SET_BIBLIOGRAPHY_IDENTIFIERS', identifiers)
  },
  setBibliographyAuthors({ commit }, authors) {
    commit('SET_BIBLIOGRAPHY_AUTHORS', authors)
  },
  setBibliographyTitle({ commit }, title) {
    commit('SET_BIBLIOGRAPHY_TITLE', title)
  },
  setBibliographyUrl({ commit }, url) {
    commit('SET_BIBLIOGRAPHY_URL', url)
  },
  addBibliography({ commit, state }) {
    commit('ADD_BIBLIOGRAPHY', state.bibliography)
    commit('SET_BIBLIOGRAPHY', getEmptyBiomarkerBibliography())
  },
  setBiomarkerRowStatus({ commit }, value) {
    commit('SET_BIOMARKER_ROW_STATUS', value)
  },
}

const mutations = {
  REQUEST_INIT(state) {
    state.status = 'loading'
  },
  REQUEST_SUCCESS(state) {
    state.status = 'success'
    state.errors = {}
    state.dirty = false
  },
  REQUEST_ERROR(state, message) {
    state.status = 'error'
    state.errors = message
  },
  CLEAR_ERRORS(state) {
    state.errors = {}
  },
  SET_BIOMARKERS_SETTING(state, biomarkersSetting) {
    state.biomarkersSetting = biomarkersSetting
  },
  SET_BIOMARKERS(state, biomarkers) {
    state.biomarkers = biomarkers
  },
  SET_BIOMARKER(state, biomarker) {
    // deep copy data without memory refs
    state.biomarker = JSON.parse(JSON.stringify(biomarker))
  },
  SET_BIOMARKER_NAME(state, name) {
    state.dirty = true
    state.biomarker.name = name
  },
  SET_BIOMARKER_CODE(state, code) {
    state.dirty = true
    state.biomarker.code = code
  },
  SET_RESULT_NAME(state, name) {
    state.dirty = true
    state.result.name = name
  },
  SET_RESULT_CODE(state, code) {
    state.dirty = true
    state.result.code = code
  },
  SET_RESULT_COLOR(state, color) {
    state.dirty = true
    state.result.color = color
  },
  SET_RESULT_STATISTICS_GROUPER(state, statisticsGrouper) {
    state.dirty = true
    state.result.statisticsGrouper = statisticsGrouper
  },
  SET_BIOMARKER_PRIMARY_TUMOR(state, primaryTumor) {
    state.dirty = true
    const tumorIndex = state.biomarker.primaryTumors.findIndex(
      (tumor) => tumor.id === primaryTumor.id
    )
    if (tumorIndex >= 0) state.biomarker.primaryTumors.splice(tumorIndex, 1)
    else
      state.biomarker.primaryTumors = [
        ...state.biomarker.primaryTumors,
        primaryTumor,
      ]
  },
  SET_BIOMARKER_RESULT(state, { index, value, key }) {
    state.dirty = true
    const results = [...state.biomarker.results]
    results[index][key] = value
    state.biomarker.results = results
  },
  SET_BIOMARKER_BIBLIOGRAPHY(state, { index, value, key }) {
    state.dirty = true
    const bibliographies = [...state.biomarker.bibliographies]
    bibliographies[index][key] = value
    state.biomarker.bibliographies = bibliographies
  },
  ADD_RESULT(state, result) {
    state.dirty = true
    state.biomarker.results = [{ ...result }, ...state.biomarker.results]
  },
  SET_RESULT(state, result) {
    state.result = { ...result }
  },
  ADD_BIBLIOGRAPHY(state, bibliography) {
    state.dirty = true
    state.biomarker.bibliographies = [
      {
        ...bibliography,
        biomarkerId: state.biomarker.id,
      },
      ...state.biomarker.bibliographies,
    ]
  },
  SET_BIBLIOGRAPHY(state, bibliography) {
    state.bibliography = { ...bibliography }
  },
  SET_BIBLIOGRAPHY_IDENTIFIERS(state, identifiers) {
    state.dirty = true
    state.bibliography.identifiers = identifiers
  },
  SET_BIBLIOGRAPHY_AUTHORS(state, authors) {
    state.dirty = true
    state.bibliography.authors = authors
  },
  SET_BIBLIOGRAPHY_TITLE(state, title) {
    state.dirty = true
    state.bibliography.title = title
  },
  SET_BIBLIOGRAPHY_URL(state, url) {
    state.dirty = true
    state.bibliography.url = url
  },
  SET_BIOMARKER_ROW_STATUS(state, value) {
    state.dirty = true
    state.biomarker.rowStatus = value
  },
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters,
  actions,
  mutations,
}
