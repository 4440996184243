<template>
  <div>
    <d-card class="mb-4">
      <d-card-body>
        <div class="form-row">
          <d-input
            v-model="name"
            placeholder="Nombre de la Aplicación"
            class="large-form-text"
          />
        </div>
        <div class="form-row">
          <button
            :disabled="!name"
            class="btn btn-accent btn-pill d-block w-50 mb-12"
            @click="submit()"
          >
            {{ buttonDescription }}
          </button>
        </div>
      </d-card-body>
    </d-card>
    <d-card class="card-small">
      <d-card-header>
        <h6 class="m-0">Credenciales</h6>
      </d-card-header>
      <d-card-body class="p-0 pb-3 table-responsive">
        <table class="table">
          <thead class="bg-light">
            <tr>
              <th>Nombre</th>
              <th>Client ID</th>
              <th>Client Secret</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in clients" :key="item.client_id">
              <td class="align-middle">
                {{ item.name }}
              </td>
              <td class="align-middle">
                {{ item.client_id }}
              </td>
              <td class="align-middle">
                {{ item.client_secret }}
              </td>
            </tr>
          </tbody>
        </table>
        <p v-if="clients.length === 0" class="text-center">
          No hay credenciales para APIs generadas
        </p>
      </d-card-body>
    </d-card>
  </div>
</template>

<script>
import PlutonBaseMixin from '@/components/mixins/PlutonBaseMixin'

export default {
  mixins: [PlutonBaseMixin],
  data() {
    return {
      clients: [],
      name: '',
    }
  },
  computed: {
    buttonDescription() {
      return this.name
        ? `Generar Credenciales para '${this.name}'`
        : 'Generar Credenciales'
    },
  },
  mounted() {
    this.fetchOAuthClients()
  },
  methods: {
    async fetchOAuthClients() {
      const response = await this.axios.get(`/api/oauth/clients`)
      this.clients = response.data.rows
    },
    async submit() {
      await this.axios({
        method: 'POST',
        url: '/api/oauth/client',
        data: { name: this.name },
      })

      await this.fetchOAuthClients()
      this.name = ''
    },
  },
}
</script>

<style scoped lang="scss">
button {
  margin-top: 1rem;
}
</style>
